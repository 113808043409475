export default {
    mockedPkData: {
        S: [
            {
                digits: 2,
                eodPriceAsk: 2442.31,
                eodPriceBid: 2442.31,
                priceAsk: 2432.72,
                priceBid: 2432.64,
                longPositions: 86.46,
                shortPositions: 94.64,
                weightedAvgLongPrice: 2419.2,
                weightedAvgShortPrice: 2310.23,
                floatingLongProfit: 153217.38,
                floatingShortProfit: -1195858.53,
                floatingProfit: -1042641.15,
                floatingLongProfitEOD: -52110.13,
                floatingShortProfitEOD: 52647.71,
                floatingProfitEOD: 537.58,
                closedLongPositions: 146.85000000000065,
                closedShortPositions: 229.99999999999986,
                closedLongProfit: 13025.369999999994,
                closedShortProfit: -5791.169999999966,
                closedProfit: 7234.200000000044,
                closedLongProfitEOD: 3847.230000000001,
                closedShortProfitEOD: 17567.620000000003,
                closedProfitEOD: 21414.850000000024,
                liveLongSum: 209164.24299999987,
                liveShortSum: 218640.57100000093,
                symbolExposure: -1990286.7199994598,
                longPositionsForVWAP: 86.4600000000003,
                shortPositionsForVWAP: 94.64000000000013,
                isReady: true,
                eodPriceMap: {
                    XAUUSD_1: "2442.26,2442.26,1722556800",
                    "XAUUSD-P_1": "2442.31,2442.31,1722556800",
                    "XAUUSD-E_1": "2442.36,2442.36,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "a9dd6463aaf1968e02b2c007de37daef",
                symbol: "XAUUSD",
                _eodPriceList: [
                    {
                        symbolName: "XAUUSD_1",
                        ask: "2442.26",
                        bid: "2442.26",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "XAUUSD-P_1",
                        ask: "2442.31",
                        bid: "2442.31",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "XAUUSD-E_1",
                        ask: "2442.36",
                        bid: "2442.36",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "-8.18",
                netStr: "-8.18",
                dailyNet: "21952.43",
                dailyNetStr: "21,952.43",
                symbolExposureStr: "-1,990,286.72",
                floatingProfitStr: "-1,042,641.15",
                longPositionsStr: "86.46",
                shortPositionsStr: "94.64",
                floatingProfitEODStr: "537.58",
                closedProfitEODStr: "21,414.85",
                closedProfitStr: "7,234.20",
                weightedAvgLongPriceStr: "2419.20",
                weightedAvgShortPriceStr: "2310.23",
                color: "red",
            },
            {
                digits: 5,
                eodPriceAsk: 1.67591,
                eodPriceBid: 1.67591,
                priceAsk: 1.70791,
                priceBid: 1.70776,
                longPositions: 4.28,
                shortPositions: 10.03,
                weightedAvgLongPrice: 1.68154,
                weightedAvgShortPrice: 1.62951,
                floatingLongProfit: 5302.01,
                floatingShortProfit: -41082.07,
                floatingProfit: -35780.06,
                floatingLongProfitEOD: 5122.86,
                floatingShortProfitEOD: -20099.47,
                floatingProfitEOD: -14976.61,
                closedLongPositions: 0.9900000000000005,
                closedShortPositions: 0.03,
                closedLongProfit: 89.95000000000002,
                closedShortProfit: -8.46,
                closedProfit: 81.48999999999998,
                closedLongProfitEOD: 80.92999999999961,
                closedShortProfitEOD: 0,
                closedProfitEOD: 80.92999999999961,
                liveLongSum: 7.196999999999997,
                liveShortSum: 16.344000000000023,
                symbolExposure: -627490.4449784982,
                longPositionsForVWAP: 4.279999999999999,
                shortPositionsForVWAP: 10.029999999999973,
                isReady: true,
                eodPriceMap: {
                    "EURAUD-E_1": "1.67596,1.67596,1722556800",
                    "EURAUD-P_1": "1.67591,1.67591,1722556800",
                    EURAUD_1: "1.67589,1.67589,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "411f254adef2270cc974fc6117042ebd",
                symbol: "EURAUD",
                _eodPriceList: [
                    {
                        symbolName: "EURAUD-E_1",
                        ask: "1.67596",
                        bid: "1.67596",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "EURAUD-P_1",
                        ask: "1.67591",
                        bid: "1.67591",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "EURAUD_1",
                        ask: "1.67589",
                        bid: "1.67589",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "-5.75",
                netStr: "-5.75",
                dailyNet: "-14895.68",
                dailyNetStr: "-14,895.68",
                symbolExposureStr: "-627,490.44",
                floatingProfitStr: "-35,780.06",
                longPositionsStr: "4.28",
                shortPositionsStr: "10.03",
                floatingProfitEODStr: "-14,976.61",
                closedProfitEODStr: "80.93",
                closedProfitStr: "81.49",
                weightedAvgLongPriceStr: "1.68154",
                weightedAvgShortPriceStr: "1.62951",
                color: "green",
            },
            {
                digits: 5,
                eodPriceAsk: 1.3874,
                eodPriceBid: 1.3874,
                priceAsk: 1.39343,
                priceBid: 1.39329,
                longPositions: 0.25,
                shortPositions: 36.22,
                weightedAvgLongPrice: 1.372,
                weightedAvgShortPrice: 1.37203,
                floatingLongProfit: -48.19,
                floatingShortProfit: -28237.6,
                floatingProfit: -28285.79,
                floatingLongProfitEOD: -61.13,
                floatingShortProfitEOD: -6941.53,
                floatingProfitEOD: -7002.66,
                closedLongPositions: 0.6100000000000003,
                closedShortPositions: 1.8500000000000005,
                closedLongProfit: 52.08999999999998,
                closedShortProfit: 34.969999999999985,
                closedProfit: 87.06000000000004,
                closedLongProfitEOD: 32.56000000000001,
                closedShortProfitEOD: 130.15000000000023,
                closedProfitEOD: 162.7100000000004,
                liveLongSum: 0.343,
                liveShortSum: 49.6949999999999,
                symbolExposure: -3591548.623900818,
                longPositionsForVWAP: 0.24999999999999997,
                shortPositionsForVWAP: 36.219999999999985,
                isReady: true,
                eodPriceMap: {
                    USDCAD_1: "1.38738,1.38738,1722556800",
                    "USDCAD-P_1": "1.3874,1.3874,1722556800",
                    "USDCAD-E_1": "1.38745,1.38745,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "410356a6e476d63f9bd8535da1086a2d",
                symbol: "USDCAD",
                _eodPriceList: [
                    {
                        symbolName: "USDCAD_1",
                        ask: "1.38738",
                        bid: "1.38738",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "USDCAD-P_1",
                        ask: "1.3874",
                        bid: "1.3874",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "USDCAD-E_1",
                        ask: "1.38745",
                        bid: "1.38745",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "-35.97",
                netStr: "-35.97",
                dailyNet: "-6839.95",
                dailyNetStr: "-6,839.95",
                symbolExposureStr: "-3,591,548.62",
                floatingProfitStr: "-28,285.79",
                longPositionsStr: "0.25",
                shortPositionsStr: "36.22",
                floatingProfitEODStr: "-7,002.66",
                closedProfitEODStr: "162.71",
                closedProfitStr: "87.06",
                weightedAvgLongPriceStr: "1.37200",
                weightedAvgShortPriceStr: "1.37203",
                color: "green",
            },
            {
                digits: 2,
                eodPriceAsk: 169.73,
                eodPriceBid: 169.73,
                priceAsk: 169.96,
                priceBid: 169.73,
                longPositions: 11,
                shortPositions: 0,
                weightedAvgLongPrice: 173.19,
                weightedAvgShortPrice: 0,
                floatingLongProfit: -38.09,
                floatingShortProfit: 0,
                floatingProfit: -38.09,
                floatingLongProfitEOD: 0,
                floatingShortProfitEOD: 0,
                floatingProfitEOD: 0,
                closedLongPositions: 0,
                closedShortPositions: 0,
                closedLongProfit: 0,
                closedShortProfit: 0,
                closedProfit: 0,
                closedLongProfitEOD: 0,
                closedShortProfitEOD: 0,
                closedProfitEOD: 0,
                liveLongSum: 1905.12,
                liveShortSum: 0,
                symbolExposure: 1867.03,
                longPositionsForVWAP: 11,
                shortPositionsForVWAP: 0,
                isReady: true,
                eodPriceMap: {
                    BA_1: "169.73,169.73,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "ed3463bcdffad01c179b00de747fccc6",
                symbol: "BA",
                _eodPriceList: [
                    {
                        symbolName: "BA_1",
                        ask: "169.73",
                        bid: "169.73",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "11.00",
                netStr: "11.00",
                dailyNet: "0.00",
                dailyNetStr: "0.00",
                symbolExposureStr: "1,867.03",
                floatingProfitStr: "-38.09",
                longPositionsStr: "11.00",
                shortPositionsStr: "0.00",
                floatingProfitEODStr: "0.00",
                closedProfitEODStr: "0.00",
                closedProfitStr: "0.00",
                weightedAvgLongPriceStr: "173.19",
                weightedAvgShortPriceStr: "0.00",
                color: "",
            },
            {
                digits: 5,
                eodPriceAsk: 0.59564,
                eodPriceBid: 0.59564,
                priceAsk: 0.58708,
                priceBid: 0.58696,
                longPositions: 1.66,
                shortPositions: 0.12,
                weightedAvgLongPrice: 0.57951,
                weightedAvgShortPrice: 0.50833,
                floatingLongProfit: -2437.04,
                floatingShortProfit: 36.85,
                floatingProfit: -2400.19,
                floatingLongProfitEOD: -1387.34,
                floatingShortProfitEOD: 87.12,
                floatingProfitEOD: -1300.22,
                closedLongPositions: 0.14,
                closedShortPositions: 0.5300000000000002,
                closedLongProfit: 22.040000000000003,
                closedShortProfit: -2.3400000000000043,
                closedProfit: 19.700000000000024,
                closedLongProfitEOD: 1.06,
                closedShortProfitEOD: 4.3500000000000005,
                closedProfitEOD: 5.409999999999999,
                liveLongSum: 0.9620000000000007,
                liveShortSum: 0.061000000000000006,
                symbolExposure: 90429.59999999846,
                longPositionsForVWAP: 1.660000000000001,
                shortPositionsForVWAP: 0.11999999999999998,
                isReady: true,
                eodPriceMap: {
                    "NZDUSD-P_1": "0.59566,0.59566,1722556800",
                    NZDUSD_1: "0.59564,0.59564,1722556800",
                    "NZDUSD-E_1": "0.59571,0.59571,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "579c5a5b5f7f3bb39fb661c454d46960",
                symbol: "NZDUSD",
                _eodPriceList: [
                    {
                        symbolName: "NZDUSD-P_1",
                        ask: "0.59566",
                        bid: "0.59566",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "NZDUSD_1",
                        ask: "0.59564",
                        bid: "0.59564",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "NZDUSD-E_1",
                        ask: "0.59571",
                        bid: "0.59571",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "1.54",
                netStr: "1.54",
                dailyNet: "-1294.81",
                dailyNetStr: "-1,294.81",
                symbolExposureStr: "90,429.60",
                floatingProfitStr: "-2,400.19",
                longPositionsStr: "1.66",
                shortPositionsStr: "0.12",
                floatingProfitEODStr: "-1,300.22",
                closedProfitEODStr: "5.41",
                closedProfitStr: "19.70",
                weightedAvgLongPriceStr: "0.57951",
                weightedAvgShortPriceStr: "0.50833",
                color: "green",
            },
            {
                digits: 2,
                eodPriceAsk: 18392.99,
                eodPriceBid: 18392.99,
                priceAsk: 17470.78,
                priceBid: 17462.42,
                longPositions: 25.2,
                shortPositions: 14.9,
                weightedAvgLongPrice: 19425.16,
                weightedAvgShortPrice: 17953.04,
                floatingLongProfit: -48652.46,
                floatingShortProfit: 6436.71,
                floatingProfit: -42215.75,
                floatingLongProfitEOD: -22020.15,
                floatingShortProfitEOD: 5774.72,
                floatingProfitEOD: -16245.43,
                closedLongPositions: 8.899999999999997,
                closedShortPositions: 16.2,
                closedLongProfit: -1484.6499999999999,
                closedShortProfit: 7414.49,
                closedProfit: 5929.840000000001,
                closedLongProfitEOD: -1394.71,
                closedShortProfitEOD: 3114.9299999999994,
                closedProfitEOD: 1720.2200000000007,
                liveLongSum: 489514.1710000001,
                liveShortSum: 267500.31999999995,
                symbolExposure: 179779.44000000076,
                longPositionsForVWAP: 25.200000000000003,
                shortPositionsForVWAP: 14.899999999999997,
                isReady: true,
                eodPriceMap: {
                    USTEC_1: "18392.99,18392.99,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "8502fe9a181b20ed39882cff078e363c",
                symbol: "USTEC",
                _eodPriceList: [
                    {
                        symbolName: "USTEC_1",
                        ask: "18392.99",
                        bid: "18392.99",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "10.30",
                netStr: "10.30",
                dailyNet: "-14525.21",
                dailyNetStr: "-14,525.21",
                symbolExposureStr: "179,779.44",
                floatingProfitStr: "-42,215.75",
                longPositionsStr: "25.20",
                shortPositionsStr: "14.90",
                floatingProfitEODStr: "-16,245.43",
                closedProfitEODStr: "1,720.22",
                closedProfitStr: "5,929.84",
                weightedAvgLongPriceStr: "19425.16",
                weightedAvgShortPriceStr: "17953.04",
                color: "green",
            },
            {
                digits: 5,
                eodPriceAsk: 0.55844,
                eodPriceBid: 0.55844,
                priceAsk: 0.54017,
                priceBid: 0.54002,
                longPositions: 3.49,
                shortPositions: 1.6,
                weightedAvgLongPrice: 0.58108,
                weightedAvgShortPrice: 0.56812,
                floatingLongProfit: -17041.8,
                floatingShortProfit: 4035.66,
                floatingProfit: -13006.14,
                floatingLongProfitEOD: -5774.16,
                floatingShortProfitEOD: 1917.67,
                floatingProfitEOD: -3856.49,
                closedLongPositions: 1.35,
                closedShortPositions: 0,
                closedLongProfit: -4626.789999999999,
                closedShortProfit: 0,
                closedProfit: -4626.789999999999,
                closedLongProfitEOD: -5.68,
                closedShortProfitEOD: 0,
                closedProfitEOD: -5.68,
                liveLongSum: 2.027999999999999,
                liveShortSum: 0.909,
                symbolExposure: 120685.88584696726,
                longPositionsForVWAP: 3.4899999999999984,
                shortPositionsForVWAP: 1.6,
                isReady: true,
                eodPriceMap: {
                    "AUDCHF-E_1": "0.55844,0.55844,1722556800",
                    AUDCHF_1: "0.55837,0.55837,1722556800",
                    "AUDCHF-P_1": "0.55839,0.55839,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "c8b6c11c88069d41df26e55d679d5f1d",
                symbol: "AUDCHF",
                _eodPriceList: [
                    {
                        symbolName: "AUDCHF-E_1",
                        ask: "0.55844",
                        bid: "0.55844",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "AUDCHF_1",
                        ask: "0.55837",
                        bid: "0.55837",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "AUDCHF-P_1",
                        ask: "0.55839",
                        bid: "0.55839",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "1.89",
                netStr: "1.89",
                dailyNet: "-3862.17",
                dailyNetStr: "-3,862.17",
                symbolExposureStr: "120,685.89",
                floatingProfitStr: "-13,006.14",
                longPositionsStr: "3.49",
                shortPositionsStr: "1.60",
                floatingProfitEODStr: "-3,856.49",
                closedProfitEODStr: "-5.68",
                closedProfitStr: "-4,626.79",
                weightedAvgLongPriceStr: "0.58108",
                weightedAvgShortPriceStr: "0.56812",
                color: "",
            },
        ],
        B: [
            {
                digits: 3,
                eodPriceAsk: 20.407,
                eodPriceBid: 20.407,
                priceAsk: 19.936,
                priceBid: 19.924,
                longPositions: 0.02,
                shortPositions: 0,
                weightedAvgLongPrice: 20,
                weightedAvgShortPrice: 0,
                floatingLongProfit: -1.51,
                floatingShortProfit: 0,
                floatingProfit: -1.51,
                floatingLongProfitEOD: -1.51,
                floatingShortProfitEOD: 0,
                floatingProfitEOD: -1.51,
                closedLongPositions: 0.18000000000000002,
                closedShortPositions: 0,
                closedLongProfit: -5.51,
                closedShortProfit: 0,
                closedProfit: -5.51,
                closedLongProfitEOD: -5.55,
                closedShortProfitEOD: 0,
                closedProfitEOD: -5.55,
                liveLongSum: 0.39999999999999997,
                liveShortSum: 0,
                symbolExposure: 280.4321052955669,
                longPositionsForVWAP: 0.019999999999999997,
                shortPositionsForVWAP: 0,
                isReady: true,
                eodPriceMap: {
                    "CNHJPY-P_1": "20.407,20.407,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "aa4b88dd99bd8779a03748ceee398ef5",
                symbol: "CNHJPY",
                _eodPriceList: [
                    {
                        symbolName: "CNHJPY-P_1",
                        ask: "20.407",
                        bid: "20.407",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "0.02",
                netStr: "0.02",
                dailyNet: "-7.06",
                dailyNetStr: "-7.06",
                symbolExposureStr: "280.43",
                floatingProfitStr: "-1.51",
                longPositionsStr: "0.02",
                shortPositionsStr: "0.00",
                floatingProfitEODStr: "-1.51",
                closedProfitEODStr: "-5.55",
                closedProfitStr: "-5.51",
                weightedAvgLongPriceStr: "20.000",
                weightedAvgShortPriceStr: "0.000",
                color: "",
            },
            {
                digits: 2,
                eodPriceAsk: 2442.31,
                eodPriceBid: 2442.31,
                priceAsk: 2432.72,
                priceBid: 2432.64,
                longPositions: 86.46,
                shortPositions: 94.64,
                weightedAvgLongPrice: 2419.2,
                weightedAvgShortPrice: 2310.23,
                floatingLongProfit: 153217.38,
                floatingShortProfit: -1195858.53,
                floatingProfit: -1042641.15,
                floatingLongProfitEOD: -52110.13,
                floatingShortProfitEOD: 52647.71,
                floatingProfitEOD: 537.58,
                closedLongPositions: 146.85000000000065,
                closedShortPositions: 229.99999999999986,
                closedLongProfit: 13025.369999999994,
                closedShortProfit: -5791.169999999966,
                closedProfit: 7234.200000000044,
                closedLongProfitEOD: 3847.230000000001,
                closedShortProfitEOD: 17567.620000000003,
                closedProfitEOD: 21414.850000000024,
                liveLongSum: 209164.24299999987,
                liveShortSum: 218640.57100000093,
                symbolExposure: -1990286.7199994598,
                longPositionsForVWAP: 86.4600000000003,
                shortPositionsForVWAP: 94.64000000000013,
                isReady: true,
                eodPriceMap: {
                    XAUUSD_1: "2442.26,2442.26,1722556800",
                    "XAUUSD-P_1": "2442.31,2442.31,1722556800",
                    "XAUUSD-E_1": "2442.36,2442.36,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "a9dd6463aaf1968e02b2c007de37daef",
                symbol: "XAUUSD",
                _eodPriceList: [
                    {
                        symbolName: "XAUUSD_1",
                        ask: "2442.26",
                        bid: "2442.26",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "XAUUSD-P_1",
                        ask: "2442.31",
                        bid: "2442.31",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "XAUUSD-E_1",
                        ask: "2442.36",
                        bid: "2442.36",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "-8.18",
                netStr: "-8.18",
                dailyNet: "21952.43",
                dailyNetStr: "21,952.43",
                symbolExposureStr: "-1,990,286.72",
                floatingProfitStr: "-1,042,641.15",
                longPositionsStr: "86.46",
                shortPositionsStr: "94.64",
                floatingProfitEODStr: "537.58",
                closedProfitEODStr: "21,414.85",
                closedProfitStr: "7,234.20",
                weightedAvgLongPriceStr: "2419.20",
                weightedAvgShortPriceStr: "2310.23",
                color: "red",
            },
            {
                digits: 5,
                eodPriceAsk: 1.67591,
                eodPriceBid: 1.67591,
                priceAsk: 1.70791,
                priceBid: 1.70776,
                longPositions: 4.28,
                shortPositions: 10.03,
                weightedAvgLongPrice: 1.68154,
                weightedAvgShortPrice: 1.62951,
                floatingLongProfit: 5302.01,
                floatingShortProfit: -41082.07,
                floatingProfit: -35780.06,
                floatingLongProfitEOD: 5122.86,
                floatingShortProfitEOD: -20099.47,
                floatingProfitEOD: -14976.61,
                closedLongPositions: 0.9900000000000005,
                closedShortPositions: 0.03,
                closedLongProfit: 89.95000000000002,
                closedShortProfit: -8.46,
                closedProfit: 81.48999999999998,
                closedLongProfitEOD: 80.92999999999961,
                closedShortProfitEOD: 0,
                closedProfitEOD: 80.92999999999961,
                liveLongSum: 7.196999999999997,
                liveShortSum: 16.344000000000023,
                symbolExposure: -627490.4449784982,
                longPositionsForVWAP: 4.279999999999999,
                shortPositionsForVWAP: 10.029999999999973,
                isReady: true,
                eodPriceMap: {
                    "EURAUD-E_1": "1.67596,1.67596,1722556800",
                    "EURAUD-P_1": "1.67591,1.67591,1722556800",
                    EURAUD_1: "1.67589,1.67589,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "411f254adef2270cc974fc6117042ebd",
                symbol: "EURAUD",
                _eodPriceList: [
                    {
                        symbolName: "EURAUD-E_1",
                        ask: "1.67596",
                        bid: "1.67596",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "EURAUD-P_1",
                        ask: "1.67591",
                        bid: "1.67591",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "EURAUD_1",
                        ask: "1.67589",
                        bid: "1.67589",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "-5.75",
                netStr: "-5.75",
                dailyNet: "-14895.68",
                dailyNetStr: "-14,895.68",
                symbolExposureStr: "-627,490.44",
                floatingProfitStr: "-35,780.06",
                longPositionsStr: "4.28",
                shortPositionsStr: "10.03",
                floatingProfitEODStr: "-14,976.61",
                closedProfitEODStr: "80.93",
                closedProfitStr: "81.49",
                weightedAvgLongPriceStr: "1.68154",
                weightedAvgShortPriceStr: "1.62951",
                color: "green",
            },
            {
                digits: 5,
                eodPriceAsk: 1.3874,
                eodPriceBid: 1.3874,
                priceAsk: 1.39343,
                priceBid: 1.39329,
                longPositions: 0.25,
                shortPositions: 36.22,
                weightedAvgLongPrice: 1.372,
                weightedAvgShortPrice: 1.37203,
                floatingLongProfit: -48.19,
                floatingShortProfit: -28237.6,
                floatingProfit: -28285.79,
                floatingLongProfitEOD: -61.13,
                floatingShortProfitEOD: -6941.53,
                floatingProfitEOD: -7002.66,
                closedLongPositions: 0.6100000000000003,
                closedShortPositions: 1.8500000000000005,
                closedLongProfit: 52.08999999999998,
                closedShortProfit: 34.969999999999985,
                closedProfit: 87.06000000000004,
                closedLongProfitEOD: 32.56000000000001,
                closedShortProfitEOD: 130.15000000000023,
                closedProfitEOD: 162.7100000000004,
                liveLongSum: 0.343,
                liveShortSum: 49.6949999999999,
                symbolExposure: -3591548.623900818,
                longPositionsForVWAP: 0.24999999999999997,
                shortPositionsForVWAP: 36.219999999999985,
                isReady: true,
                eodPriceMap: {
                    USDCAD_1: "1.38738,1.38738,1722556800",
                    "USDCAD-P_1": "1.3874,1.3874,1722556800",
                    "USDCAD-E_1": "1.38745,1.38745,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "410356a6e476d63f9bd8535da1086a2d",
                symbol: "USDCAD",
                _eodPriceList: [
                    {
                        symbolName: "USDCAD_1",
                        ask: "1.38738",
                        bid: "1.38738",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "USDCAD-P_1",
                        ask: "1.3874",
                        bid: "1.3874",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "USDCAD-E_1",
                        ask: "1.38745",
                        bid: "1.38745",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "-35.97",
                netStr: "-35.97",
                dailyNet: "-6839.95",
                dailyNetStr: "-6,839.95",
                symbolExposureStr: "-3,591,548.62",
                floatingProfitStr: "-28,285.79",
                longPositionsStr: "0.25",
                shortPositionsStr: "36.22",
                floatingProfitEODStr: "-7,002.66",
                closedProfitEODStr: "162.71",
                closedProfitStr: "87.06",
                weightedAvgLongPriceStr: "1.37200",
                weightedAvgShortPriceStr: "1.37203",
                color: "green",
            },
            {
                digits: 2,
                eodPriceAsk: 169.73,
                eodPriceBid: 169.73,
                priceAsk: 169.96,
                priceBid: 169.73,
                longPositions: 11,
                shortPositions: 0,
                weightedAvgLongPrice: 173.19,
                weightedAvgShortPrice: 0,
                floatingLongProfit: -38.09,
                floatingShortProfit: 0,
                floatingProfit: -38.09,
                floatingLongProfitEOD: 0,
                floatingShortProfitEOD: 0,
                floatingProfitEOD: 0,
                closedLongPositions: 0,
                closedShortPositions: 0,
                closedLongProfit: 0,
                closedShortProfit: 0,
                closedProfit: 0,
                closedLongProfitEOD: 0,
                closedShortProfitEOD: 0,
                closedProfitEOD: 0,
                liveLongSum: 1905.12,
                liveShortSum: 0,
                symbolExposure: 1867.03,
                longPositionsForVWAP: 11,
                shortPositionsForVWAP: 0,
                isReady: true,
                eodPriceMap: {
                    BA_1: "169.73,169.73,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "ed3463bcdffad01c179b00de747fccc6",
                symbol: "BA",
                _eodPriceList: [
                    {
                        symbolName: "BA_1",
                        ask: "169.73",
                        bid: "169.73",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "11.00",
                netStr: "11.00",
                dailyNet: "0.00",
                dailyNetStr: "0.00",
                symbolExposureStr: "1,867.03",
                floatingProfitStr: "-38.09",
                longPositionsStr: "11.00",
                shortPositionsStr: "0.00",
                floatingProfitEODStr: "0.00",
                closedProfitEODStr: "0.00",
                closedProfitStr: "0.00",
                weightedAvgLongPriceStr: "173.19",
                weightedAvgShortPriceStr: "0.00",
                color: "",
            },
            {
                digits: 5,
                eodPriceAsk: 0.59564,
                eodPriceBid: 0.59564,
                priceAsk: 0.58708,
                priceBid: 0.58696,
                longPositions: 1.66,
                shortPositions: 0.12,
                weightedAvgLongPrice: 0.57951,
                weightedAvgShortPrice: 0.50833,
                floatingLongProfit: -2437.04,
                floatingShortProfit: 36.85,
                floatingProfit: -2400.19,
                floatingLongProfitEOD: -1387.34,
                floatingShortProfitEOD: 87.12,
                floatingProfitEOD: -1300.22,
                closedLongPositions: 0.14,
                closedShortPositions: 0.5300000000000002,
                closedLongProfit: 22.040000000000003,
                closedShortProfit: -2.3400000000000043,
                closedProfit: 19.700000000000024,
                closedLongProfitEOD: 1.06,
                closedShortProfitEOD: 4.3500000000000005,
                closedProfitEOD: 5.409999999999999,
                liveLongSum: 0.9620000000000007,
                liveShortSum: 0.061000000000000006,
                symbolExposure: 90429.59999999846,
                longPositionsForVWAP: 1.660000000000001,
                shortPositionsForVWAP: 0.11999999999999998,
                isReady: true,
                eodPriceMap: {
                    "NZDUSD-P_1": "0.59566,0.59566,1722556800",
                    NZDUSD_1: "0.59564,0.59564,1722556800",
                    "NZDUSD-E_1": "0.59571,0.59571,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "579c5a5b5f7f3bb39fb661c454d46960",
                symbol: "NZDUSD",
                _eodPriceList: [
                    {
                        symbolName: "NZDUSD-P_1",
                        ask: "0.59566",
                        bid: "0.59566",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "NZDUSD_1",
                        ask: "0.59564",
                        bid: "0.59564",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "NZDUSD-E_1",
                        ask: "0.59571",
                        bid: "0.59571",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "1.54",
                netStr: "1.54",
                dailyNet: "-1294.81",
                dailyNetStr: "-1,294.81",
                symbolExposureStr: "90,429.60",
                floatingProfitStr: "-2,400.19",
                longPositionsStr: "1.66",
                shortPositionsStr: "0.12",
                floatingProfitEODStr: "-1,300.22",
                closedProfitEODStr: "5.41",
                closedProfitStr: "19.70",
                weightedAvgLongPriceStr: "0.57951",
                weightedAvgShortPriceStr: "0.50833",
                color: "green",
            },
            {
                digits: 2,
                eodPriceAsk: 18392.99,
                eodPriceBid: 18392.99,
                priceAsk: 17470.78,
                priceBid: 17462.42,
                longPositions: 25.2,
                shortPositions: 14.9,
                weightedAvgLongPrice: 19425.16,
                weightedAvgShortPrice: 17953.04,
                floatingLongProfit: -48652.46,
                floatingShortProfit: 6436.71,
                floatingProfit: -42215.75,
                floatingLongProfitEOD: -22020.15,
                floatingShortProfitEOD: 5774.72,
                floatingProfitEOD: -16245.43,
                closedLongPositions: 8.899999999999997,
                closedShortPositions: 16.2,
                closedLongProfit: -1484.6499999999999,
                closedShortProfit: 7414.49,
                closedProfit: 5929.840000000001,
                closedLongProfitEOD: -1394.71,
                closedShortProfitEOD: 3114.9299999999994,
                closedProfitEOD: 1720.2200000000007,
                liveLongSum: 489514.1710000001,
                liveShortSum: 267500.31999999995,
                symbolExposure: 179779.44000000076,
                longPositionsForVWAP: 25.200000000000003,
                shortPositionsForVWAP: 14.899999999999997,
                isReady: true,
                eodPriceMap: {
                    USTEC_1: "18392.99,18392.99,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "8502fe9a181b20ed39882cff078e363c",
                symbol: "USTEC",
                _eodPriceList: [
                    {
                        symbolName: "USTEC_1",
                        ask: "18392.99",
                        bid: "18392.99",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "10.30",
                netStr: "10.30",
                dailyNet: "-14525.21",
                dailyNetStr: "-14,525.21",
                symbolExposureStr: "179,779.44",
                floatingProfitStr: "-42,215.75",
                longPositionsStr: "25.20",
                shortPositionsStr: "14.90",
                floatingProfitEODStr: "-16,245.43",
                closedProfitEODStr: "1,720.22",
                closedProfitStr: "5,929.84",
                weightedAvgLongPriceStr: "19425.16",
                weightedAvgShortPriceStr: "17953.04",
                color: "green",
            },
            {
                digits: 5,
                eodPriceAsk: 0.55844,
                eodPriceBid: 0.55844,
                priceAsk: 0.54017,
                priceBid: 0.54002,
                longPositions: 3.49,
                shortPositions: 1.6,
                weightedAvgLongPrice: 0.58108,
                weightedAvgShortPrice: 0.56812,
                floatingLongProfit: -17041.8,
                floatingShortProfit: 4035.66,
                floatingProfit: -13006.14,
                floatingLongProfitEOD: -5774.16,
                floatingShortProfitEOD: 1917.67,
                floatingProfitEOD: -3856.49,
                closedLongPositions: 1.35,
                closedShortPositions: 0,
                closedLongProfit: -4626.789999999999,
                closedShortProfit: 0,
                closedProfit: -4626.789999999999,
                closedLongProfitEOD: -5.68,
                closedShortProfitEOD: 0,
                closedProfitEOD: -5.68,
                liveLongSum: 2.027999999999999,
                liveShortSum: 0.909,
                symbolExposure: 120685.88584696726,
                longPositionsForVWAP: 3.4899999999999984,
                shortPositionsForVWAP: 1.6,
                isReady: true,
                eodPriceMap: {
                    "AUDCHF-E_1": "0.55844,0.55844,1722556800",
                    AUDCHF_1: "0.55837,0.55837,1722556800",
                    "AUDCHF-P_1": "0.55839,0.55839,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "c8b6c11c88069d41df26e55d679d5f1d",
                symbol: "AUDCHF",
                _eodPriceList: [
                    {
                        symbolName: "AUDCHF-E_1",
                        ask: "0.55844",
                        bid: "0.55844",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "AUDCHF_1",
                        ask: "0.55837",
                        bid: "0.55837",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "AUDCHF-P_1",
                        ask: "0.55839",
                        bid: "0.55839",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "1.89",
                netStr: "1.89",
                dailyNet: "-3862.17",
                dailyNetStr: "-3,862.17",
                symbolExposureStr: "120,685.89",
                floatingProfitStr: "-13,006.14",
                longPositionsStr: "3.49",
                shortPositionsStr: "1.60",
                floatingProfitEODStr: "-3,856.49",
                closedProfitEODStr: "-5.68",
                closedProfitStr: "-4,626.79",
                weightedAvgLongPriceStr: "0.58108",
                weightedAvgShortPriceStr: "0.56812",
                color: "",
            },
            {
                digits: 2,
                eodPriceAsk: 59149.46,
                eodPriceBid: 59149.46,
                priceAsk: 52032.79,
                priceBid: 52011.17,
                longPositions: 2.14,
                shortPositions: 0.91,
                weightedAvgLongPrice: 59571.76,
                weightedAvgShortPrice: 54090.39,
                floatingLongProfit: -14386.5,
                floatingShortProfit: 1023.54,
                floatingProfit: -13362.96,
                floatingLongProfitEOD: -10166.46,
                floatingShortProfitEOD: 1021.26,
                floatingProfitEOD: -9145.2,
                closedLongPositions: 67.07000000000005,
                closedShortPositions: 20.930000000000128,
                closedLongProfit: -31305.550000000003,
                closedShortProfit: 7847.900000000002,
                closedProfit: -23457.649999999998,
                closedLongProfitEOD: -27997.25,
                closedShortProfitEOD: 5075.840000000002,
                closedProfitEOD: -22921.40999999998,
                liveLongSum: 127483.56899999983,
                liveShortSum: 49222.261000000035,
                symbolExposure: 64898.305999997814,
                longPositionsForVWAP: 2.140000000000005,
                shortPositionsForVWAP: 0.9099999999999994,
                isReady: true,
                eodPriceMap: {
                    BTCUSD_1: "59149.46,59149.46,1722729600",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "8fa44715dc9fdad2d73ff5379cc59714",
                symbol: "BTCUSD",
                _eodPriceList: [
                    {
                        symbolName: "BTCUSD_1",
                        ask: "59149.46",
                        bid: "59149.46",
                        dateTime: "2024-08-04 00:00:00",
                    },
                ],
                net: "1.23",
                netStr: "1.23",
                dailyNet: "-32066.61",
                dailyNetStr: "-32,066.61",
                symbolExposureStr: "64,898.31",
                floatingProfitStr: "-13,362.96",
                longPositionsStr: "2.14",
                shortPositionsStr: "0.91",
                floatingProfitEODStr: "-9,145.20",
                closedProfitEODStr: "-22,921.41",
                closedProfitStr: "-23,457.65",
                weightedAvgLongPriceStr: "59571.76",
                weightedAvgShortPriceStr: "54090.39",
                color: "green",
            },
            {
                digits: 2,
                eodPriceAsk: 2752.25,
                eodPriceBid: 2752.25,
                priceAsk: 2283.38,
                priceBid: 2280.8,
                longPositions: 0.6,
                shortPositions: 0.01,
                weightedAvgLongPrice: 2388.36,
                weightedAvgShortPrice: 2903.1,
                floatingLongProfit: -64.02,
                floatingShortProfit: 6.2,
                floatingProfit: -57.82,
                floatingLongProfitEOD: -64.02,
                floatingShortProfitEOD: 4.71,
                floatingProfitEOD: -59.31,
                closedLongPositions: 6.850000000000003,
                closedShortPositions: 3.249999999999999,
                closedLongProfit: -2817.7799999999993,
                closedShortProfit: 742.99,
                closedProfit: -2074.7899999999995,
                closedLongProfitEOD: -35.16,
                closedShortProfitEOD: 458.60999999999996,
                closedProfitEOD: 423.45000000000005,
                liveLongSum: 1433.021,
                liveShortSum: 29.031000000000063,
                symbolExposure: 1346.1742000000004,
                longPositionsForVWAP: 0.5999999999999999,
                shortPositionsForVWAP: 0.010000000000000009,
                isReady: true,
                eodPriceMap: {
                    ETHUSD_1: "2752.25,2752.25,1722729600",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "8f3518018b908c8556e3a0a994a93652",
                symbol: "ETHUSD",
                _eodPriceList: [
                    {
                        symbolName: "ETHUSD_1",
                        ask: "2752.25",
                        bid: "2752.25",
                        dateTime: "2024-08-04 00:00:00",
                    },
                ],
                net: "0.59",
                netStr: "0.59",
                dailyNet: "364.14",
                dailyNetStr: "364.14",
                symbolExposureStr: "1,346.17",
                floatingProfitStr: "-57.82",
                longPositionsStr: "0.60",
                shortPositionsStr: "0.01",
                floatingProfitEODStr: "-59.31",
                closedProfitEODStr: "423.45",
                closedProfitStr: "-2,074.79",
                weightedAvgLongPriceStr: "2388.36",
                weightedAvgShortPriceStr: "2903.10",
                color: "",
            },
            {
                digits: 5,
                eodPriceAsk: 0.10494,
                eodPriceBid: 0.10494,
                priceAsk: 0.0886,
                priceBid: 0.08843,
                longPositions: 4,
                shortPositions: 4,
                weightedAvgLongPrice: 0.12725,
                weightedAvgShortPrice: 0.089,
                floatingLongProfit: -15797,
                floatingShortProfit: 260,
                floatingProfit: -15537,
                floatingLongProfitEOD: -6611,
                floatingShortProfitEOD: 260,
                floatingProfitEOD: -6351,
                closedLongPositions: 2,
                closedShortPositions: 32,
                closedLongProfit: -1935,
                closedShortProfit: 6466,
                closedProfit: 4531,
                closedLongProfitEOD: -1935,
                closedShortProfitEOD: 7372,
                closedProfitEOD: 5437,
                liveLongSum: 0.509,
                liveShortSum: 0.356,
                symbolExposure: -108.99999999994543,
                longPositionsForVWAP: 4,
                shortPositionsForVWAP: 4,
                isReady: true,
                eodPriceMap: {
                    DOGUSD_1: "0.10494,0.10494,1722729600",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "857a95376af4d888e07035c752298578",
                symbol: "DOGUSD",
                _eodPriceList: [
                    {
                        symbolName: "DOGUSD_1",
                        ask: "0.10494",
                        bid: "0.10494",
                        dateTime: "2024-08-04 00:00:00",
                    },
                ],
                net: "0.00",
                netStr: "0.00",
                dailyNet: "-914.00",
                dailyNetStr: "-914.00",
                symbolExposureStr: "-109.00",
                floatingProfitStr: "-15,537.00",
                longPositionsStr: "4.00",
                shortPositionsStr: "4.00",
                floatingProfitEODStr: "-6,351.00",
                closedProfitEODStr: "5,437.00",
                closedProfitStr: "4,531.00",
                weightedAvgLongPriceStr: "0.12725",
                weightedAvgShortPriceStr: "0.08900",
                color: "red",
            },
            {
                digits: 5,
                eodPriceAsk: 1.0926,
                eodPriceBid: 1.0926,
                priceAsk: 1.09117,
                priceBid: 1.09097,
                longPositions: 3.1,
                shortPositions: 1,
                weightedAvgLongPrice: 1.10774,
                weightedAvgShortPrice: 1.091,
                floatingLongProfit: -3195.65,
                floatingShortProfit: 8.81,
                floatingProfit: -3186.84,
                floatingLongProfitEOD: -300.25,
                floatingShortProfitEOD: 8.81,
                floatingProfitEOD: -291.44,
                closedLongPositions: 1.7500000000000002,
                closedShortPositions: 10.01,
                closedLongProfit: -370.42999999999995,
                closedShortProfit: 348.25,
                closedProfit: -22.180000000000042,
                closedLongProfitEOD: 102.16,
                closedShortProfitEOD: 278.82999999999987,
                closedProfitEOD: 380.9900000000004,
                liveLongSum: 3.4339999999999997,
                liveShortSum: 1.091,
                symbolExposure: 134461.59432979932,
                longPositionsForVWAP: 3.0999999999999996,
                shortPositionsForVWAP: 1,
                isReady: true,
                eodPriceMap: {
                    "AUDNZD-P_1": "1.09262,1.09262,1722556800",
                    AUDNZD_1: "1.0926,1.0926,1722556800",
                    "AUDNZD-E_1": "1.09267,1.09267,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "c52646bbe0151dcff5f3d60cad970f60",
                symbol: "AUDNZD",
                _eodPriceList: [
                    {
                        symbolName: "AUDNZD-P_1",
                        ask: "1.09262",
                        bid: "1.09262",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "AUDNZD_1",
                        ask: "1.0926",
                        bid: "1.0926",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "AUDNZD-E_1",
                        ask: "1.09267",
                        bid: "1.09267",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "2.10",
                netStr: "2.10",
                dailyNet: "89.55",
                dailyNetStr: "89.55",
                symbolExposureStr: "134,461.59",
                floatingProfitStr: "-3,186.84",
                longPositionsStr: "3.10",
                shortPositionsStr: "1.00",
                floatingProfitEODStr: "-291.44",
                closedProfitEODStr: "380.99",
                closedProfitStr: "-22.18",
                weightedAvgLongPriceStr: "1.10774",
                weightedAvgShortPriceStr: "1.09100",
                color: "",
            },
            {
                digits: 5,
                eodPriceAsk: 1.51376,
                eodPriceBid: 1.51376,
                priceAsk: 1.51978,
                priceBid: 1.5196100000000001,
                longPositions: 0.51,
                shortPositions: 2.57,
                weightedAvgLongPrice: 1.5098,
                weightedAvgShortPrice: 1.47354,
                floatingLongProfit: 78.72,
                floatingShortProfit: -4809.65,
                floatingProfit: -4730.93,
                floatingLongProfitEOD: 78.72,
                floatingShortProfitEOD: -1090.06,
                floatingProfitEOD: -1011.34,
                closedLongPositions: 0.11999999999999998,
                closedShortPositions: 0,
                closedLongProfit: 10.23,
                closedShortProfit: 0,
                closedProfit: 10.23,
                closedLongProfitEOD: 5.6000000000000005,
                closedShortProfitEOD: 0,
                closedProfitEOD: 5.6000000000000005,
                liveLongSum: 0.77,
                liveShortSum: 3.7869999999999977,
                symbolExposure: -224802.14521966325,
                longPositionsForVWAP: 0.51,
                shortPositionsForVWAP: 2.5699999999999985,
                isReady: true,
                eodPriceMap: {
                    "EURCAD-P_1": "1.51376,1.51376,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "bb2ddbcef7e5a22d34d65f14694d9ac1",
                symbol: "EURCAD",
                _eodPriceList: [
                    {
                        symbolName: "EURCAD-P_1",
                        ask: "1.51376",
                        bid: "1.51376",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "-2.06",
                netStr: "-2.06",
                dailyNet: "-1005.74",
                dailyNetStr: "-1,005.74",
                symbolExposureStr: "-224,802.15",
                floatingProfitStr: "-4,730.93",
                longPositionsStr: "0.51",
                shortPositionsStr: "2.57",
                floatingProfitEODStr: "-1,011.34",
                closedProfitEODStr: "5.60",
                closedProfitStr: "10.23",
                weightedAvgLongPriceStr: "1.50980",
                weightedAvgShortPriceStr: "1.47354",
                color: "",
            },
            {
                digits: 2,
                eodPriceAsk: 34828.21,
                eodPriceBid: 34828.21,
                priceAsk: 31364.67,
                priceBid: 31351.32,
                longPositions: 0,
                shortPositions: 0.1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 32765.32,
                floatingLongProfit: 0,
                floatingShortProfit: 49.23,
                floatingProfit: 49.23,
                floatingLongProfitEOD: 0,
                floatingShortProfitEOD: 49.23,
                floatingProfitEOD: 49.23,
                closedLongPositions: 0.6,
                closedShortPositions: 24,
                closedLongProfit: 62.06,
                closedShortProfit: 222.29000000000002,
                closedProfit: 284.35,
                closedLongProfitEOD: 63.18999999999998,
                closedShortProfitEOD: 224.6899999999999,
                closedProfitEOD: 287.8799999999999,
                liveLongSum: 0,
                liveShortSum: 3276.5320000000065,
                symbolExposure: -1102.4953600855029,
                longPositionsForVWAP: 0,
                shortPositionsForVWAP: 0.09999999999999964,
                isReady: true,
                eodPriceMap: {
                    JP225_1: "34828.21,34828.21,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "4937c95d952588f6fd8c1e62f6f92c90",
                symbol: "JP225",
                _eodPriceList: [
                    {
                        symbolName: "JP225_1",
                        ask: "34828.21",
                        bid: "34828.21",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "-0.10",
                netStr: "-0.10",
                dailyNet: "337.11",
                dailyNetStr: "337.11",
                symbolExposureStr: "-1,102.50",
                floatingProfitStr: "49.23",
                longPositionsStr: "0.00",
                shortPositionsStr: "0.10",
                floatingProfitEODStr: "49.23",
                closedProfitEODStr: "287.88",
                closedProfitStr: "284.35",
                weightedAvgLongPriceStr: "0.00",
                weightedAvgShortPriceStr: "32765.32",
                color: "",
            },
            {
                digits: 3,
                eodPriceAsk: 73.149,
                eodPriceBid: 73.149,
                priceAsk: 71.725,
                priceBid: 71.676,
                longPositions: 0.23,
                shortPositions: 0,
                weightedAvgLongPrice: 72.495,
                weightedAvgShortPrice: 0,
                floatingLongProfit: -182.23,
                floatingShortProfit: 0,
                floatingProfit: -182.23,
                floatingLongProfitEOD: -238.81,
                floatingShortProfitEOD: 0,
                floatingProfitEOD: -238.81,
                closedLongPositions: 0.06,
                closedShortPositions: 0,
                closedLongProfit: -61.769999999999996,
                closedShortProfit: 0,
                closedProfit: -61.769999999999996,
                closedLongProfitEOD: 0,
                closedShortProfitEOD: 0,
                closedProfitEOD: 0,
                liveLongSum: 16.674,
                liveShortSum: 0,
                symbolExposure: 16493.53999999997,
                longPositionsForVWAP: 0.23,
                shortPositionsForVWAP: 0,
                isReady: true,
                eodPriceMap: {
                    "WTI10-E_1": "73.149,73.149,1722556800",
                    WTI10_1: "73.142,73.142,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "3ca29b60ed37c055bb01ad098483ce54",
                symbol: "WTI10",
                _eodPriceList: [
                    {
                        symbolName: "WTI10-E_1",
                        ask: "73.149",
                        bid: "73.149",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "WTI10_1",
                        ask: "73.142",
                        bid: "73.142",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "0.23",
                netStr: "0.23",
                dailyNet: "-238.81",
                dailyNetStr: "-238.81",
                symbolExposureStr: "16,493.54",
                floatingProfitStr: "-182.23",
                longPositionsStr: "0.23",
                shortPositionsStr: "0.00",
                floatingProfitEODStr: "-238.81",
                closedProfitEODStr: "0.00",
                closedProfitStr: "-61.77",
                weightedAvgLongPriceStr: "72.495",
                weightedAvgShortPriceStr: "0.000",
                color: "",
            },
            {
                digits: 2,
                eodPriceAsk: 39603.15,
                eodPriceBid: 39603.15,
                priceAsk: 39313.25,
                priceBid: 39305.75,
                longPositions: 0.7,
                shortPositions: 1.1,
                weightedAvgLongPrice: 39718.22,
                weightedAvgShortPrice: 40484.94,
                floatingLongProfit: -244.46,
                floatingShortProfit: 1194.82,
                floatingProfit: 950.36,
                floatingLongProfitEOD: -163.88,
                floatingShortProfitEOD: 229.79,
                floatingProfitEOD: 65.91,
                closedLongPositions: 0,
                closedShortPositions: 0.1,
                closedLongProfit: 0,
                closedShortProfit: 1.29,
                closedProfit: 1.29,
                closedLongProfitEOD: 0,
                closedShortProfitEOD: 1.29,
                closedProfitEOD: 1.29,
                liveLongSum: 27802.759,
                liveShortSum: 44533.435,
                symbolExposure: -15780.29999999999,
                longPositionsForVWAP: 0.7,
                shortPositionsForVWAP: 1.0999999999999999,
                isReady: true,
                eodPriceMap: {
                    US30_1: "39603.15,39603.15,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "3a4ec4c7029e64ff8b932ac8638c0690",
                symbol: "US30",
                _eodPriceList: [
                    {
                        symbolName: "US30_1",
                        ask: "39603.15",
                        bid: "39603.15",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "-0.40",
                netStr: "-0.40",
                dailyNet: "67.20",
                dailyNetStr: "67.20",
                symbolExposureStr: "-15,780.30",
                floatingProfitStr: "950.36",
                longPositionsStr: "0.70",
                shortPositionsStr: "1.10",
                floatingProfitEODStr: "65.91",
                closedProfitEODStr: "1.29",
                closedProfitStr: "1.29",
                weightedAvgLongPriceStr: "39718.22",
                weightedAvgShortPriceStr: "40484.94",
                color: "",
            },
            {
                digits: 5,
                eodPriceAsk: 1.77654,
                eodPriceBid: 1.77654,
                priceAsk: 1.7722799999999999,
                priceBid: 1.77207,
                longPositions: 4.64,
                shortPositions: 10.83,
                weightedAvgLongPrice: 1.76961,
                weightedAvgShortPrice: 1.73591,
                floatingLongProfit: 83.28,
                floatingShortProfit: -19090.76,
                floatingProfit: -19007.48,
                floatingLongProfitEOD: -103.87,
                floatingShortProfitEOD: 398.36,
                floatingProfitEOD: 294.49,
                closedLongPositions: 0.13,
                closedShortPositions: 0.05,
                closedLongProfit: -37.34,
                closedShortProfit: 2.6599999999999997,
                closedProfit: -34.68,
                closedLongProfitEOD: 2.42,
                closedShortProfitEOD: 0,
                closedProfitEOD: 2.42,
                liveLongSum: 8.210999999999997,
                liveShortSum: 18.79999999999997,
                symbolExposure: -789680.4769318412,
                longPositionsForVWAP: 4.639999999999998,
                shortPositionsForVWAP: 10.829999999999949,
                isReady: true,
                eodPriceMap: {
                    "GBPCAD-P_1": "1.77654,1.77654,1722556800",
                    "GBPCAD-E_1": "1.77659,1.77659,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "e59a641013ab84fef3fd9fcc156641a3",
                symbol: "GBPCAD",
                _eodPriceList: [
                    {
                        symbolName: "GBPCAD-P_1",
                        ask: "1.77654",
                        bid: "1.77654",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "GBPCAD-E_1",
                        ask: "1.77659",
                        bid: "1.77659",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "-6.19",
                netStr: "-6.19",
                dailyNet: "296.91",
                dailyNetStr: "296.91",
                symbolExposureStr: "-789,680.48",
                floatingProfitStr: "-19,007.48",
                longPositionsStr: "4.64",
                shortPositionsStr: "10.83",
                floatingProfitEODStr: "294.49",
                closedProfitEODStr: "2.42",
                closedProfitStr: "-34.68",
                weightedAvgLongPriceStr: "1.76961",
                weightedAvgShortPriceStr: "1.73591",
                color: "red",
            },
            {
                digits: 3,
                eodPriceAsk: 170.811,
                eodPriceBid: 170.811,
                priceAsk: 167.595,
                priceBid: 167.572,
                longPositions: 0,
                shortPositions: 0,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
                floatingLongProfit: 0,
                floatingShortProfit: 0,
                floatingProfit: 0,
                floatingLongProfitEOD: 0,
                floatingShortProfitEOD: 0,
                floatingProfitEOD: 0,
                closedLongPositions: 0,
                closedShortPositions: 0.01,
                closedLongProfit: 0,
                closedShortProfit: 4.97,
                closedProfit: 4.97,
                closedLongProfitEOD: 0,
                closedShortProfitEOD: 0,
                closedProfitEOD: 0,
                liveLongSum: 0,
                liveShortSum: 0,
                symbolExposure: 0,
                longPositionsForVWAP: 0,
                shortPositionsForVWAP: 0,
                isReady: true,
                eodPriceMap: {
                    CHFJPY_1: "170.811,170.811,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "d156bbeb9a47f7104e9e9c9bab90933c",
                symbol: "CHFJPY",
                _eodPriceList: [
                    {
                        symbolName: "CHFJPY_1",
                        ask: "170.811",
                        bid: "170.811",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "0.00",
                netStr: "0.00",
                dailyNet: "0.00",
                dailyNetStr: "0.00",
                symbolExposureStr: "0.00",
                floatingProfitStr: "0.00",
                longPositionsStr: "0.00",
                shortPositionsStr: "0.00",
                floatingProfitEODStr: "0.00",
                closedProfitEODStr: "0.00",
                closedProfitStr: "4.97",
                weightedAvgLongPriceStr: "0.000",
                weightedAvgShortPriceStr: "0.000",
                color: "",
            },
            {
                digits: 5,
                eodPriceAsk: 0.85197,
                eodPriceBid: 0.85197,
                priceAsk: 0.85762,
                priceBid: 0.85749,
                longPositions: 0,
                shortPositions: 1.45,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.82344,
                floatingLongProfit: 0,
                floatingShortProfit: -1713.36,
                floatingProfit: -1713.36,
                floatingLongProfitEOD: 0,
                floatingShortProfitEOD: -1005.41,
                floatingProfitEOD: -1005.41,
                closedLongPositions: 1.5199999999999998,
                closedShortPositions: 0.01,
                closedLongProfit: -97.7,
                closedShortProfit: -2.11,
                closedProfit: -99.81,
                closedLongProfitEOD: 1.03,
                closedShortProfitEOD: -2.11,
                closedProfitEOD: -1.0799999999999998,
                liveLongSum: 0,
                liveShortSum: 1.1940000000000008,
                symbolExposure: -158113.76678389785,
                longPositionsForVWAP: 0,
                shortPositionsForVWAP: 1.4500000000000008,
                isReady: true,
                eodPriceMap: {
                    "EURGBP-P_1": "0.85197,0.85197,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "61acab6c4cf53cf28b85ac1faa4e37c6",
                symbol: "EURGBP",
                _eodPriceList: [
                    {
                        symbolName: "EURGBP-P_1",
                        ask: "0.85197",
                        bid: "0.85197",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "-1.45",
                netStr: "-1.45",
                dailyNet: "-1006.49",
                dailyNetStr: "-1,006.49",
                symbolExposureStr: "-158,113.77",
                floatingProfitStr: "-1,713.36",
                longPositionsStr: "0.00",
                shortPositionsStr: "1.45",
                floatingProfitEODStr: "-1,005.41",
                closedProfitEODStr: "-1.08",
                closedProfitStr: "-99.81",
                weightedAvgLongPriceStr: "0.00000",
                weightedAvgShortPriceStr: "0.82344",
                color: "",
            },
            {
                digits: 2,
                eodPriceAsk: 107.2,
                eodPriceBid: 107.2,
                priceAsk: 107.22,
                priceBid: 107.2,
                longPositions: 8,
                shortPositions: 0,
                weightedAvgLongPrice: 110.4,
                weightedAvgShortPrice: 0,
                floatingLongProfit: -25.63,
                floatingShortProfit: 0,
                floatingProfit: -25.63,
                floatingLongProfitEOD: 0,
                floatingShortProfitEOD: 0,
                floatingProfitEOD: 0,
                closedLongPositions: 0,
                closedShortPositions: 0,
                closedLongProfit: 0,
                closedShortProfit: 0,
                closedProfit: 0,
                closedLongProfitEOD: 0,
                closedShortProfitEOD: 0,
                closedProfitEOD: 0,
                liveLongSum: 883.23,
                liveShortSum: 0,
                symbolExposure: 857.6000000000001,
                longPositionsForVWAP: 8,
                shortPositionsForVWAP: 0,
                isReady: true,
                eodPriceMap: {
                    NVDA_1: "107.2,107.2,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "7a51d9b20eb2f1dd140e25a9a3a7fe8d",
                symbol: "NVDA",
                _eodPriceList: [
                    {
                        symbolName: "NVDA_1",
                        ask: "107.2",
                        bid: "107.2",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "8.00",
                netStr: "8.00",
                dailyNet: "0.00",
                dailyNetStr: "0.00",
                symbolExposureStr: "857.60",
                floatingProfitStr: "-25.63",
                longPositionsStr: "8.00",
                shortPositionsStr: "0.00",
                floatingProfitEODStr: "0.00",
                closedProfitEODStr: "0.00",
                closedProfitStr: "0.00",
                weightedAvgLongPriceStr: "110.40",
                weightedAvgShortPriceStr: "0.00",
                color: "",
            },
            {
                digits: 3,
                eodPriceAsk: 159.915,
                eodPriceBid: 159.915,
                priceAsk: 156.26,
                priceBid: 156.24,
                longPositions: 0.05,
                shortPositions: 0.05,
                weightedAvgLongPrice: 156.56,
                weightedAvgShortPrice: 156.44,
                floatingLongProfit: -11.88,
                floatingShortProfit: 42.19,
                floatingProfit: 30.31,
                floatingLongProfitEOD: -11.88,
                floatingShortProfitEOD: 41.78,
                floatingProfitEOD: 29.9,
                closedLongPositions: 0.45,
                closedShortPositions: 0.11999999999999998,
                closedLongProfit: -796.0899999999999,
                closedShortProfit: 92.74000000000001,
                closedProfit: -703.35,
                closedLongProfitEOD: 0,
                closedShortProfitEOD: 9.319999999999999,
                closedProfitEOD: 9.319999999999999,
                liveLongSum: 7.828000000000001,
                liveShortSum: 7.821999999999999,
                symbolExposure: 35.026988763758936,
                longPositionsForVWAP: 0.05,
                shortPositionsForVWAP: 0.049999999999999996,
                isReady: true,
                eodPriceMap: {
                    "EURJPY-P_1": "159.917,159.917,1722556800",
                    "EURJPY-E_1": "159.922,159.922,1722556800",
                    EURJPY_1: "159.915,159.915,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "dc6727faf1a172ceda40631d43c7f19a",
                symbol: "EURJPY",
                _eodPriceList: [
                    {
                        symbolName: "EURJPY-P_1",
                        ask: "159.917",
                        bid: "159.917",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "EURJPY-E_1",
                        ask: "159.922",
                        bid: "159.922",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "EURJPY_1",
                        ask: "159.915",
                        bid: "159.915",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "0.00",
                netStr: "0.00",
                dailyNet: "39.22",
                dailyNetStr: "39.22",
                symbolExposureStr: "35.03",
                floatingProfitStr: "30.31",
                longPositionsStr: "0.05",
                shortPositionsStr: "0.05",
                floatingProfitEODStr: "29.90",
                closedProfitEODStr: "9.32",
                closedProfitStr: "-703.35",
                weightedAvgLongPriceStr: "156.560",
                weightedAvgShortPriceStr: "156.440",
                color: "",
            },
            {
                digits: 5,
                eodPriceAsk: 1.09859,
                eodPriceBid: 1.09859,
                priceAsk: 1.07553,
                priceBid: 1.0754299999999999,
                longPositions: 0,
                shortPositions: 0.03,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.03333,
                floatingLongProfit: 0,
                floatingShortProfit: 34.71,
                floatingProfit: 34.71,
                floatingLongProfitEOD: 0,
                floatingShortProfitEOD: 34.71,
                floatingProfitEOD: 34.71,
                closedLongPositions: 0,
                closedShortPositions: 0.16,
                closedLongProfit: 0,
                closedShortProfit: 36.64999999999999,
                closedProfit: 36.64999999999999,
                closedLongProfitEOD: 0,
                closedShortProfitEOD: 36.97999999999997,
                closedProfitEOD: 36.97999999999997,
                liveLongSum: 0,
                liveShortSum: 0.031000000000000003,
                symbolExposure: -3804.4199411385416,
                longPositionsForVWAP: 0,
                shortPositionsForVWAP: 0.029999999999999995,
                isReady: true,
                eodPriceMap: {
                    "GBPCHF-E_1": "1.09859,1.09859,1722556800",
                    GBPCHF_1: "1.09852,1.09852,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "45453351e3ac67c41848826c4d1669a5",
                symbol: "GBPCHF",
                _eodPriceList: [
                    {
                        symbolName: "GBPCHF-E_1",
                        ask: "1.09859",
                        bid: "1.09859",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "GBPCHF_1",
                        ask: "1.09852",
                        bid: "1.09852",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "-0.03",
                netStr: "-0.03",
                dailyNet: "71.69",
                dailyNetStr: "71.69",
                symbolExposureStr: "-3,804.42",
                floatingProfitStr: "34.71",
                longPositionsStr: "0.00",
                shortPositionsStr: "0.03",
                floatingProfitEODStr: "34.71",
                closedProfitEODStr: "36.98",
                closedProfitStr: "36.65",
                weightedAvgLongPriceStr: "0.00000",
                weightedAvgShortPriceStr: "1.03333",
                color: "",
            },
            {
                digits: 3,
                eodPriceAsk: 105.628,
                eodPriceBid: 105.628,
                priceAsk: 101.746,
                priceBid: 101.728,
                longPositions: 0,
                shortPositions: 0,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
                floatingLongProfit: 0,
                floatingShortProfit: 0,
                floatingProfit: 0,
                floatingLongProfitEOD: 0,
                floatingShortProfitEOD: 0,
                floatingProfitEOD: 0,
                closedLongPositions: 0.01,
                closedShortPositions: 0.02,
                closedLongProfit: -4.92,
                closedShortProfit: 22.14,
                closedProfit: 17.22,
                closedLongProfitEOD: -5.01,
                closedShortProfitEOD: 0,
                closedProfitEOD: -5.01,
                liveLongSum: 0,
                liveShortSum: 0,
                symbolExposure: 0,
                longPositionsForVWAP: 0,
                shortPositionsForVWAP: 0,
                isReady: true,
                eodPriceMap: {
                    CADJPY_1: "105.628,105.628,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "ebba16b3f43e8809c30483b18f3f13d4",
                symbol: "CADJPY",
                _eodPriceList: [
                    {
                        symbolName: "CADJPY_1",
                        ask: "105.628",
                        bid: "105.628",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "0.00",
                netStr: "0.00",
                dailyNet: "-5.01",
                dailyNetStr: "-5.01",
                symbolExposureStr: "0.00",
                floatingProfitStr: "0.00",
                longPositionsStr: "0.00",
                shortPositionsStr: "0.00",
                floatingProfitEODStr: "0.00",
                closedProfitEODStr: "-5.01",
                closedProfitStr: "17.22",
                weightedAvgLongPriceStr: "0.000",
                weightedAvgShortPriceStr: "0.000",
                color: "",
            },
        ],
        Test: [
            {
                digits: 2,
                eodPriceAsk: 59149.46,
                eodPriceBid: 59149.46,
                priceAsk: 52032.79,
                priceBid: 52011.17,
                longPositions: 2.14,
                shortPositions: 0.91,
                weightedAvgLongPrice: 59571.76,
                weightedAvgShortPrice: 54090.39,
                floatingLongProfit: -14386.5,
                floatingShortProfit: 1023.54,
                floatingProfit: -13362.96,
                floatingLongProfitEOD: -10166.46,
                floatingShortProfitEOD: 1021.26,
                floatingProfitEOD: -9145.2,
                closedLongPositions: 67.07000000000005,
                closedShortPositions: 20.930000000000128,
                closedLongProfit: -31305.550000000003,
                closedShortProfit: 7847.900000000002,
                closedProfit: -23457.649999999998,
                closedLongProfitEOD: -27997.25,
                closedShortProfitEOD: 5075.840000000002,
                closedProfitEOD: -22921.40999999998,
                liveLongSum: 127483.56899999983,
                liveShortSum: 49222.261000000035,
                symbolExposure: 64898.305999997814,
                longPositionsForVWAP: 2.140000000000005,
                shortPositionsForVWAP: 0.9099999999999994,
                isReady: true,
                eodPriceMap: {
                    BTCUSD_1: "59149.46,59149.46,1722729600",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "8fa44715dc9fdad2d73ff5379cc59714",
                symbol: "BTCUSD",
                _eodPriceList: [
                    {
                        symbolName: "BTCUSD_1",
                        ask: "59149.46",
                        bid: "59149.46",
                        dateTime: "2024-08-04 00:00:00",
                    },
                ],
                net: "1.23",
                netStr: "1.23",
                dailyNet: "-32066.61",
                dailyNetStr: "-32,066.61",
                symbolExposureStr: "64,898.31",
                floatingProfitStr: "-13,362.96",
                longPositionsStr: "2.14",
                shortPositionsStr: "0.91",
                floatingProfitEODStr: "-9,145.20",
                closedProfitEODStr: "-22,921.41",
                closedProfitStr: "-23,457.65",
                weightedAvgLongPriceStr: "59571.76",
                weightedAvgShortPriceStr: "54090.39",
                color: "green",
            },
            {
                digits: 2,
                eodPriceAsk: 2752.25,
                eodPriceBid: 2752.25,
                priceAsk: 2283.38,
                priceBid: 2280.8,
                longPositions: 0.6,
                shortPositions: 0.01,
                weightedAvgLongPrice: 2388.36,
                weightedAvgShortPrice: 2903.1,
                floatingLongProfit: -64.02,
                floatingShortProfit: 6.2,
                floatingProfit: -57.82,
                floatingLongProfitEOD: -64.02,
                floatingShortProfitEOD: 4.71,
                floatingProfitEOD: -59.31,
                closedLongPositions: 6.850000000000003,
                closedShortPositions: 3.249999999999999,
                closedLongProfit: -2817.7799999999993,
                closedShortProfit: 742.99,
                closedProfit: -2074.7899999999995,
                closedLongProfitEOD: -35.16,
                closedShortProfitEOD: 458.60999999999996,
                closedProfitEOD: 423.45000000000005,
                liveLongSum: 1433.021,
                liveShortSum: 29.031000000000063,
                symbolExposure: 1346.1742000000004,
                longPositionsForVWAP: 0.5999999999999999,
                shortPositionsForVWAP: 0.010000000000000009,
                isReady: true,
                eodPriceMap: {
                    ETHUSD_1: "2752.25,2752.25,1722729600",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "8f3518018b908c8556e3a0a994a93652",
                symbol: "ETHUSD",
                _eodPriceList: [
                    {
                        symbolName: "ETHUSD_1",
                        ask: "2752.25",
                        bid: "2752.25",
                        dateTime: "2024-08-04 00:00:00",
                    },
                ],
                net: "0.59",
                netStr: "0.59",
                dailyNet: "364.14",
                dailyNetStr: "364.14",
                symbolExposureStr: "1,346.17",
                floatingProfitStr: "-57.82",
                longPositionsStr: "0.60",
                shortPositionsStr: "0.01",
                floatingProfitEODStr: "-59.31",
                closedProfitEODStr: "423.45",
                closedProfitStr: "-2,074.79",
                weightedAvgLongPriceStr: "2388.36",
                weightedAvgShortPriceStr: "2903.10",
                color: "",
            },
            {
                digits: 5,
                eodPriceAsk: 0.10494,
                eodPriceBid: 0.10494,
                priceAsk: 0.0886,
                priceBid: 0.08843,
                longPositions: 4,
                shortPositions: 4,
                weightedAvgLongPrice: 0.12725,
                weightedAvgShortPrice: 0.089,
                floatingLongProfit: -15797,
                floatingShortProfit: 260,
                floatingProfit: -15537,
                floatingLongProfitEOD: -6611,
                floatingShortProfitEOD: 260,
                floatingProfitEOD: -6351,
                closedLongPositions: 2,
                closedShortPositions: 32,
                closedLongProfit: -1935,
                closedShortProfit: 6466,
                closedProfit: 4531,
                closedLongProfitEOD: -1935,
                closedShortProfitEOD: 7372,
                closedProfitEOD: 5437,
                liveLongSum: 0.509,
                liveShortSum: 0.356,
                symbolExposure: -108.99999999994543,
                longPositionsForVWAP: 4,
                shortPositionsForVWAP: 4,
                isReady: true,
                eodPriceMap: {
                    DOGUSD_1: "0.10494,0.10494,1722729600",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "857a95376af4d888e07035c752298578",
                symbol: "DOGUSD",
                _eodPriceList: [
                    {
                        symbolName: "DOGUSD_1",
                        ask: "0.10494",
                        bid: "0.10494",
                        dateTime: "2024-08-04 00:00:00",
                    },
                ],
                net: "0.00",
                netStr: "0.00",
                dailyNet: "-914.00",
                dailyNetStr: "-914.00",
                symbolExposureStr: "-109.00",
                floatingProfitStr: "-15,537.00",
                longPositionsStr: "4.00",
                shortPositionsStr: "4.00",
                floatingProfitEODStr: "-6,351.00",
                closedProfitEODStr: "5,437.00",
                closedProfitStr: "4,531.00",
                weightedAvgLongPriceStr: "0.12725",
                weightedAvgShortPriceStr: "0.08900",
                color: "red",
            },
            {
                digits: 5,
                eodPriceAsk: 1.0926,
                eodPriceBid: 1.0926,
                priceAsk: 1.09117,
                priceBid: 1.09097,
                longPositions: 3.1,
                shortPositions: 1,
                weightedAvgLongPrice: 1.10774,
                weightedAvgShortPrice: 1.091,
                floatingLongProfit: -3195.65,
                floatingShortProfit: 8.81,
                floatingProfit: -3186.84,
                floatingLongProfitEOD: -300.25,
                floatingShortProfitEOD: 8.81,
                floatingProfitEOD: -291.44,
                closedLongPositions: 1.7500000000000002,
                closedShortPositions: 10.01,
                closedLongProfit: -370.42999999999995,
                closedShortProfit: 348.25,
                closedProfit: -22.180000000000042,
                closedLongProfitEOD: 102.16,
                closedShortProfitEOD: 278.82999999999987,
                closedProfitEOD: 380.9900000000004,
                liveLongSum: 3.4339999999999997,
                liveShortSum: 1.091,
                symbolExposure: 134461.59432979932,
                longPositionsForVWAP: 3.0999999999999996,
                shortPositionsForVWAP: 1,
                isReady: true,
                eodPriceMap: {
                    "AUDNZD-P_1": "1.09262,1.09262,1722556800",
                    AUDNZD_1: "1.0926,1.0926,1722556800",
                    "AUDNZD-E_1": "1.09267,1.09267,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "c52646bbe0151dcff5f3d60cad970f60",
                symbol: "AUDNZD",
                _eodPriceList: [
                    {
                        symbolName: "AUDNZD-P_1",
                        ask: "1.09262",
                        bid: "1.09262",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "AUDNZD_1",
                        ask: "1.0926",
                        bid: "1.0926",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "AUDNZD-E_1",
                        ask: "1.09267",
                        bid: "1.09267",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "2.10",
                netStr: "2.10",
                dailyNet: "89.55",
                dailyNetStr: "89.55",
                symbolExposureStr: "134,461.59",
                floatingProfitStr: "-3,186.84",
                longPositionsStr: "3.10",
                shortPositionsStr: "1.00",
                floatingProfitEODStr: "-291.44",
                closedProfitEODStr: "380.99",
                closedProfitStr: "-22.18",
                weightedAvgLongPriceStr: "1.10774",
                weightedAvgShortPriceStr: "1.09100",
                color: "",
            },
        ],
        B2: [
            {
                digits: 5,
                eodPriceAsk: 1.51376,
                eodPriceBid: 1.51376,
                priceAsk: 1.51978,
                priceBid: 1.5196100000000001,
                longPositions: 0.51,
                shortPositions: 2.57,
                weightedAvgLongPrice: 1.5098,
                weightedAvgShortPrice: 1.47354,
                floatingLongProfit: 78.72,
                floatingShortProfit: -4809.65,
                floatingProfit: -4730.93,
                floatingLongProfitEOD: 78.72,
                floatingShortProfitEOD: -1090.06,
                floatingProfitEOD: -1011.34,
                closedLongPositions: 0.11999999999999998,
                closedShortPositions: 0,
                closedLongProfit: 10.23,
                closedShortProfit: 0,
                closedProfit: 10.23,
                closedLongProfitEOD: 5.6000000000000005,
                closedShortProfitEOD: 0,
                closedProfitEOD: 5.6000000000000005,
                liveLongSum: 0.77,
                liveShortSum: 3.7869999999999977,
                symbolExposure: -224802.14521966325,
                longPositionsForVWAP: 0.51,
                shortPositionsForVWAP: 2.5699999999999985,
                isReady: true,
                eodPriceMap: {
                    "EURCAD-P_1": "1.51376,1.51376,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "bb2ddbcef7e5a22d34d65f14694d9ac1",
                symbol: "EURCAD",
                _eodPriceList: [
                    {
                        symbolName: "EURCAD-P_1",
                        ask: "1.51376",
                        bid: "1.51376",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "-2.06",
                netStr: "-2.06",
                dailyNet: "-1005.74",
                dailyNetStr: "-1,005.74",
                symbolExposureStr: "-224,802.15",
                floatingProfitStr: "-4,730.93",
                longPositionsStr: "0.51",
                shortPositionsStr: "2.57",
                floatingProfitEODStr: "-1,011.34",
                closedProfitEODStr: "5.60",
                closedProfitStr: "10.23",
                weightedAvgLongPriceStr: "1.50980",
                weightedAvgShortPriceStr: "1.47354",
                color: "",
            },
            {
                digits: 2,
                eodPriceAsk: 34828.21,
                eodPriceBid: 34828.21,
                priceAsk: 31364.67,
                priceBid: 31351.32,
                longPositions: 0,
                shortPositions: 0.1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 32765.32,
                floatingLongProfit: 0,
                floatingShortProfit: 49.23,
                floatingProfit: 49.23,
                floatingLongProfitEOD: 0,
                floatingShortProfitEOD: 49.23,
                floatingProfitEOD: 49.23,
                closedLongPositions: 0.6,
                closedShortPositions: 24,
                closedLongProfit: 62.06,
                closedShortProfit: 222.29000000000002,
                closedProfit: 284.35,
                closedLongProfitEOD: 63.18999999999998,
                closedShortProfitEOD: 224.6899999999999,
                closedProfitEOD: 287.8799999999999,
                liveLongSum: 0,
                liveShortSum: 3276.5320000000065,
                symbolExposure: -1102.4953600855029,
                longPositionsForVWAP: 0,
                shortPositionsForVWAP: 0.09999999999999964,
                isReady: true,
                eodPriceMap: {
                    JP225_1: "34828.21,34828.21,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "4937c95d952588f6fd8c1e62f6f92c90",
                symbol: "JP225",
                _eodPriceList: [
                    {
                        symbolName: "JP225_1",
                        ask: "34828.21",
                        bid: "34828.21",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "-0.10",
                netStr: "-0.10",
                dailyNet: "337.11",
                dailyNetStr: "337.11",
                symbolExposureStr: "-1,102.50",
                floatingProfitStr: "49.23",
                longPositionsStr: "0.00",
                shortPositionsStr: "0.10",
                floatingProfitEODStr: "49.23",
                closedProfitEODStr: "287.88",
                closedProfitStr: "284.35",
                weightedAvgLongPriceStr: "0.00",
                weightedAvgShortPriceStr: "32765.32",
                color: "",
            },
            {
                digits: 3,
                eodPriceAsk: 73.149,
                eodPriceBid: 73.149,
                priceAsk: 71.725,
                priceBid: 71.676,
                longPositions: 0.23,
                shortPositions: 0,
                weightedAvgLongPrice: 72.495,
                weightedAvgShortPrice: 0,
                floatingLongProfit: -182.23,
                floatingShortProfit: 0,
                floatingProfit: -182.23,
                floatingLongProfitEOD: -238.81,
                floatingShortProfitEOD: 0,
                floatingProfitEOD: -238.81,
                closedLongPositions: 0.06,
                closedShortPositions: 0,
                closedLongProfit: -61.769999999999996,
                closedShortProfit: 0,
                closedProfit: -61.769999999999996,
                closedLongProfitEOD: 0,
                closedShortProfitEOD: 0,
                closedProfitEOD: 0,
                liveLongSum: 16.674,
                liveShortSum: 0,
                symbolExposure: 16493.53999999997,
                longPositionsForVWAP: 0.23,
                shortPositionsForVWAP: 0,
                isReady: true,
                eodPriceMap: {
                    "WTI10-E_1": "73.149,73.149,1722556800",
                    WTI10_1: "73.142,73.142,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "3ca29b60ed37c055bb01ad098483ce54",
                symbol: "WTI10",
                _eodPriceList: [
                    {
                        symbolName: "WTI10-E_1",
                        ask: "73.149",
                        bid: "73.149",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "WTI10_1",
                        ask: "73.142",
                        bid: "73.142",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "0.23",
                netStr: "0.23",
                dailyNet: "-238.81",
                dailyNetStr: "-238.81",
                symbolExposureStr: "16,493.54",
                floatingProfitStr: "-182.23",
                longPositionsStr: "0.23",
                shortPositionsStr: "0.00",
                floatingProfitEODStr: "-238.81",
                closedProfitEODStr: "0.00",
                closedProfitStr: "-61.77",
                weightedAvgLongPriceStr: "72.495",
                weightedAvgShortPriceStr: "0.000",
                color: "",
            },
            {
                digits: 2,
                eodPriceAsk: 39603.15,
                eodPriceBid: 39603.15,
                priceAsk: 39313.25,
                priceBid: 39305.75,
                longPositions: 0.7,
                shortPositions: 1.1,
                weightedAvgLongPrice: 39718.22,
                weightedAvgShortPrice: 40484.94,
                floatingLongProfit: -244.46,
                floatingShortProfit: 1194.82,
                floatingProfit: 950.36,
                floatingLongProfitEOD: -163.88,
                floatingShortProfitEOD: 229.79,
                floatingProfitEOD: 65.91,
                closedLongPositions: 0,
                closedShortPositions: 0.1,
                closedLongProfit: 0,
                closedShortProfit: 1.29,
                closedProfit: 1.29,
                closedLongProfitEOD: 0,
                closedShortProfitEOD: 1.29,
                closedProfitEOD: 1.29,
                liveLongSum: 27802.759,
                liveShortSum: 44533.435,
                symbolExposure: -15780.29999999999,
                longPositionsForVWAP: 0.7,
                shortPositionsForVWAP: 1.0999999999999999,
                isReady: true,
                eodPriceMap: {
                    US30_1: "39603.15,39603.15,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "3a4ec4c7029e64ff8b932ac8638c0690",
                symbol: "US30",
                _eodPriceList: [
                    {
                        symbolName: "US30_1",
                        ask: "39603.15",
                        bid: "39603.15",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "-0.40",
                netStr: "-0.40",
                dailyNet: "67.20",
                dailyNetStr: "67.20",
                symbolExposureStr: "-15,780.30",
                floatingProfitStr: "950.36",
                longPositionsStr: "0.70",
                shortPositionsStr: "1.10",
                floatingProfitEODStr: "65.91",
                closedProfitEODStr: "1.29",
                closedProfitStr: "1.29",
                weightedAvgLongPriceStr: "39718.22",
                weightedAvgShortPriceStr: "40484.94",
                color: "",
            },
            {
                digits: 5,
                eodPriceAsk: 1.77654,
                eodPriceBid: 1.77654,
                priceAsk: 1.7722799999999999,
                priceBid: 1.77207,
                longPositions: 4.64,
                shortPositions: 10.83,
                weightedAvgLongPrice: 1.76961,
                weightedAvgShortPrice: 1.73591,
                floatingLongProfit: 83.28,
                floatingShortProfit: -19090.76,
                floatingProfit: -19007.48,
                floatingLongProfitEOD: -103.87,
                floatingShortProfitEOD: 398.36,
                floatingProfitEOD: 294.49,
                closedLongPositions: 0.13,
                closedShortPositions: 0.05,
                closedLongProfit: -37.34,
                closedShortProfit: 2.6599999999999997,
                closedProfit: -34.68,
                closedLongProfitEOD: 2.42,
                closedShortProfitEOD: 0,
                closedProfitEOD: 2.42,
                liveLongSum: 8.210999999999997,
                liveShortSum: 18.79999999999997,
                symbolExposure: -789680.4769318412,
                longPositionsForVWAP: 4.639999999999998,
                shortPositionsForVWAP: 10.829999999999949,
                isReady: true,
                eodPriceMap: {
                    "GBPCAD-P_1": "1.77654,1.77654,1722556800",
                    "GBPCAD-E_1": "1.77659,1.77659,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "e59a641013ab84fef3fd9fcc156641a3",
                symbol: "GBPCAD",
                _eodPriceList: [
                    {
                        symbolName: "GBPCAD-P_1",
                        ask: "1.77654",
                        bid: "1.77654",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "GBPCAD-E_1",
                        ask: "1.77659",
                        bid: "1.77659",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "-6.19",
                netStr: "-6.19",
                dailyNet: "296.91",
                dailyNetStr: "296.91",
                symbolExposureStr: "-789,680.48",
                floatingProfitStr: "-19,007.48",
                longPositionsStr: "4.64",
                shortPositionsStr: "10.83",
                floatingProfitEODStr: "294.49",
                closedProfitEODStr: "2.42",
                closedProfitStr: "-34.68",
                weightedAvgLongPriceStr: "1.76961",
                weightedAvgShortPriceStr: "1.73591",
                color: "red",
            },
            {
                digits: 3,
                eodPriceAsk: 170.811,
                eodPriceBid: 170.811,
                priceAsk: 167.595,
                priceBid: 167.572,
                longPositions: 0,
                shortPositions: 0,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
                floatingLongProfit: 0,
                floatingShortProfit: 0,
                floatingProfit: 0,
                floatingLongProfitEOD: 0,
                floatingShortProfitEOD: 0,
                floatingProfitEOD: 0,
                closedLongPositions: 0,
                closedShortPositions: 0.01,
                closedLongProfit: 0,
                closedShortProfit: 4.97,
                closedProfit: 4.97,
                closedLongProfitEOD: 0,
                closedShortProfitEOD: 0,
                closedProfitEOD: 0,
                liveLongSum: 0,
                liveShortSum: 0,
                symbolExposure: 0,
                longPositionsForVWAP: 0,
                shortPositionsForVWAP: 0,
                isReady: true,
                eodPriceMap: {
                    CHFJPY_1: "170.811,170.811,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "d156bbeb9a47f7104e9e9c9bab90933c",
                symbol: "CHFJPY",
                _eodPriceList: [
                    {
                        symbolName: "CHFJPY_1",
                        ask: "170.811",
                        bid: "170.811",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "0.00",
                netStr: "0.00",
                dailyNet: "0.00",
                dailyNetStr: "0.00",
                symbolExposureStr: "0.00",
                floatingProfitStr: "0.00",
                longPositionsStr: "0.00",
                shortPositionsStr: "0.00",
                floatingProfitEODStr: "0.00",
                closedProfitEODStr: "0.00",
                closedProfitStr: "4.97",
                weightedAvgLongPriceStr: "0.000",
                weightedAvgShortPriceStr: "0.000",
                color: "",
            },
            {
                digits: 5,
                eodPriceAsk: 0.85197,
                eodPriceBid: 0.85197,
                priceAsk: 0.85762,
                priceBid: 0.85749,
                longPositions: 0,
                shortPositions: 1.45,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.82344,
                floatingLongProfit: 0,
                floatingShortProfit: -1713.36,
                floatingProfit: -1713.36,
                floatingLongProfitEOD: 0,
                floatingShortProfitEOD: -1005.41,
                floatingProfitEOD: -1005.41,
                closedLongPositions: 1.5199999999999998,
                closedShortPositions: 0.01,
                closedLongProfit: -97.7,
                closedShortProfit: -2.11,
                closedProfit: -99.81,
                closedLongProfitEOD: 1.03,
                closedShortProfitEOD: -2.11,
                closedProfitEOD: -1.0799999999999998,
                liveLongSum: 0,
                liveShortSum: 1.1940000000000008,
                symbolExposure: -158113.76678389785,
                longPositionsForVWAP: 0,
                shortPositionsForVWAP: 1.4500000000000008,
                isReady: true,
                eodPriceMap: {
                    "EURGBP-P_1": "0.85197,0.85197,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "61acab6c4cf53cf28b85ac1faa4e37c6",
                symbol: "EURGBP",
                _eodPriceList: [
                    {
                        symbolName: "EURGBP-P_1",
                        ask: "0.85197",
                        bid: "0.85197",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "-1.45",
                netStr: "-1.45",
                dailyNet: "-1006.49",
                dailyNetStr: "-1,006.49",
                symbolExposureStr: "-158,113.77",
                floatingProfitStr: "-1,713.36",
                longPositionsStr: "0.00",
                shortPositionsStr: "1.45",
                floatingProfitEODStr: "-1,005.41",
                closedProfitEODStr: "-1.08",
                closedProfitStr: "-99.81",
                weightedAvgLongPriceStr: "0.00000",
                weightedAvgShortPriceStr: "0.82344",
                color: "",
            },
            {
                digits: 2,
                eodPriceAsk: 107.2,
                eodPriceBid: 107.2,
                priceAsk: 107.22,
                priceBid: 107.2,
                longPositions: 8,
                shortPositions: 0,
                weightedAvgLongPrice: 110.4,
                weightedAvgShortPrice: 0,
                floatingLongProfit: -25.63,
                floatingShortProfit: 0,
                floatingProfit: -25.63,
                floatingLongProfitEOD: 0,
                floatingShortProfitEOD: 0,
                floatingProfitEOD: 0,
                closedLongPositions: 0,
                closedShortPositions: 0,
                closedLongProfit: 0,
                closedShortProfit: 0,
                closedProfit: 0,
                closedLongProfitEOD: 0,
                closedShortProfitEOD: 0,
                closedProfitEOD: 0,
                liveLongSum: 883.23,
                liveShortSum: 0,
                symbolExposure: 857.6000000000001,
                longPositionsForVWAP: 8,
                shortPositionsForVWAP: 0,
                isReady: true,
                eodPriceMap: {
                    NVDA_1: "107.2,107.2,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "7a51d9b20eb2f1dd140e25a9a3a7fe8d",
                symbol: "NVDA",
                _eodPriceList: [
                    {
                        symbolName: "NVDA_1",
                        ask: "107.2",
                        bid: "107.2",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "8.00",
                netStr: "8.00",
                dailyNet: "0.00",
                dailyNetStr: "0.00",
                symbolExposureStr: "857.60",
                floatingProfitStr: "-25.63",
                longPositionsStr: "8.00",
                shortPositionsStr: "0.00",
                floatingProfitEODStr: "0.00",
                closedProfitEODStr: "0.00",
                closedProfitStr: "0.00",
                weightedAvgLongPriceStr: "110.40",
                weightedAvgShortPriceStr: "0.00",
                color: "",
            },
            {
                digits: 3,
                eodPriceAsk: 159.915,
                eodPriceBid: 159.915,
                priceAsk: 156.26,
                priceBid: 156.24,
                longPositions: 0.05,
                shortPositions: 0.05,
                weightedAvgLongPrice: 156.56,
                weightedAvgShortPrice: 156.44,
                floatingLongProfit: -11.88,
                floatingShortProfit: 42.19,
                floatingProfit: 30.31,
                floatingLongProfitEOD: -11.88,
                floatingShortProfitEOD: 41.78,
                floatingProfitEOD: 29.9,
                closedLongPositions: 0.45,
                closedShortPositions: 0.11999999999999998,
                closedLongProfit: -796.0899999999999,
                closedShortProfit: 92.74000000000001,
                closedProfit: -703.35,
                closedLongProfitEOD: 0,
                closedShortProfitEOD: 9.319999999999999,
                closedProfitEOD: 9.319999999999999,
                liveLongSum: 7.828000000000001,
                liveShortSum: 7.821999999999999,
                symbolExposure: 35.026988763758936,
                longPositionsForVWAP: 0.05,
                shortPositionsForVWAP: 0.049999999999999996,
                isReady: true,
                eodPriceMap: {
                    "EURJPY-P_1": "159.917,159.917,1722556800",
                    "EURJPY-E_1": "159.922,159.922,1722556800",
                    EURJPY_1: "159.915,159.915,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "dc6727faf1a172ceda40631d43c7f19a",
                symbol: "EURJPY",
                _eodPriceList: [
                    {
                        symbolName: "EURJPY-P_1",
                        ask: "159.917",
                        bid: "159.917",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "EURJPY-E_1",
                        ask: "159.922",
                        bid: "159.922",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "EURJPY_1",
                        ask: "159.915",
                        bid: "159.915",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "0.00",
                netStr: "0.00",
                dailyNet: "39.22",
                dailyNetStr: "39.22",
                symbolExposureStr: "35.03",
                floatingProfitStr: "30.31",
                longPositionsStr: "0.05",
                shortPositionsStr: "0.05",
                floatingProfitEODStr: "29.90",
                closedProfitEODStr: "9.32",
                closedProfitStr: "-703.35",
                weightedAvgLongPriceStr: "156.560",
                weightedAvgShortPriceStr: "156.440",
                color: "",
            },
            {
                digits: 5,
                eodPriceAsk: 1.09859,
                eodPriceBid: 1.09859,
                priceAsk: 1.07553,
                priceBid: 1.0754299999999999,
                longPositions: 0,
                shortPositions: 0.03,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.03333,
                floatingLongProfit: 0,
                floatingShortProfit: 34.71,
                floatingProfit: 34.71,
                floatingLongProfitEOD: 0,
                floatingShortProfitEOD: 34.71,
                floatingProfitEOD: 34.71,
                closedLongPositions: 0,
                closedShortPositions: 0.16,
                closedLongProfit: 0,
                closedShortProfit: 36.64999999999999,
                closedProfit: 36.64999999999999,
                closedLongProfitEOD: 0,
                closedShortProfitEOD: 36.97999999999997,
                closedProfitEOD: 36.97999999999997,
                liveLongSum: 0,
                liveShortSum: 0.031000000000000003,
                symbolExposure: -3804.4199411385416,
                longPositionsForVWAP: 0,
                shortPositionsForVWAP: 0.029999999999999995,
                isReady: true,
                eodPriceMap: {
                    "GBPCHF-E_1": "1.09859,1.09859,1722556800",
                    GBPCHF_1: "1.09852,1.09852,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "45453351e3ac67c41848826c4d1669a5",
                symbol: "GBPCHF",
                _eodPriceList: [
                    {
                        symbolName: "GBPCHF-E_1",
                        ask: "1.09859",
                        bid: "1.09859",
                        dateTime: "2024-08-02 00:00:00",
                    },
                    {
                        symbolName: "GBPCHF_1",
                        ask: "1.09852",
                        bid: "1.09852",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "-0.03",
                netStr: "-0.03",
                dailyNet: "71.69",
                dailyNetStr: "71.69",
                symbolExposureStr: "-3,804.42",
                floatingProfitStr: "34.71",
                longPositionsStr: "0.00",
                shortPositionsStr: "0.03",
                floatingProfitEODStr: "34.71",
                closedProfitEODStr: "36.98",
                closedProfitStr: "36.65",
                weightedAvgLongPriceStr: "0.00000",
                weightedAvgShortPriceStr: "1.03333",
                color: "",
            },
            {
                digits: 3,
                eodPriceAsk: 105.628,
                eodPriceBid: 105.628,
                priceAsk: 101.746,
                priceBid: 101.728,
                longPositions: 0,
                shortPositions: 0,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
                floatingLongProfit: 0,
                floatingShortProfit: 0,
                floatingProfit: 0,
                floatingLongProfitEOD: 0,
                floatingShortProfitEOD: 0,
                floatingProfitEOD: 0,
                closedLongPositions: 0.01,
                closedShortPositions: 0.02,
                closedLongProfit: -4.92,
                closedShortProfit: 22.14,
                closedProfit: 17.22,
                closedLongProfitEOD: -5.01,
                closedShortProfitEOD: 0,
                closedProfitEOD: -5.01,
                liveLongSum: 0,
                liveShortSum: 0,
                symbolExposure: 0,
                longPositionsForVWAP: 0,
                shortPositionsForVWAP: 0,
                isReady: true,
                eodPriceMap: {
                    CADJPY_1: "105.628,105.628,1722556800",
                },
                externalMsgType: "DATA_BOOK_PROFIT_SYMBOL_RESULT",
                lifeCycle: "",
                force: false,
                priority: 1,
                _ITEM_HASH: "ebba16b3f43e8809c30483b18f3f13d4",
                symbol: "CADJPY",
                _eodPriceList: [
                    {
                        symbolName: "CADJPY_1",
                        ask: "105.628",
                        bid: "105.628",
                        dateTime: "2024-08-02 00:00:00",
                    },
                ],
                net: "0.00",
                netStr: "0.00",
                dailyNet: "-5.01",
                dailyNetStr: "-5.01",
                symbolExposureStr: "0.00",
                floatingProfitStr: "0.00",
                longPositionsStr: "0.00",
                shortPositionsStr: "0.00",
                floatingProfitEODStr: "0.00",
                closedProfitEODStr: "-5.01",
                closedProfitStr: "17.22",
                weightedAvgLongPriceStr: "0.000",
                weightedAvgShortPriceStr: "0.000",
                color: "",
            },
        ],
    },

    mockedSymbolDetail: {
        USTEC: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -130.74,
                floatingProfitEOD: 2.04,
                floatingShortProfit: -130.74,
                floatingShortProfitEOD: 2.04,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809662",
                longPositions: 0,
                netPositions: -0.2,
                shortPositions: 0.2,
                symbol: "USTEC",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 15162.84,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -2791.64,
                floatingProfitEOD: 61.5,
                floatingShortProfit: -2791.64,
                floatingShortProfitEOD: 61.5,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810148",
                longPositions: 0,
                netPositions: -6,
                shortPositions: 6,
                symbol: "USTEC",
                user_trade_number: 5,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 15351.286666666667,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -704.02,
                floatingProfitEOD: 56.38,
                floatingShortProfit: -704.02,
                floatingShortProfitEOD: 56.38,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802300",
                longPositions: 0,
                netPositions: -5.5,
                shortPositions: 5.5,
                symbol: "USTEC",
                user_trade_number: 19,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 15688.548181818185,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 371.71,
                floatingLongProfitEOD: -6.12,
                floatingProfit: 371.71,
                floatingProfitEOD: -6.12,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807267",
                longPositions: 0.6,
                netPositions: 0.6,
                shortPositions: 0,
                symbol: "USTEC",
                user_trade_number: 6,
                weightedAvgLongPrice: 15194.135,
                weightedAvgShortPrice: 0,
            },
        ],
        GBPJPY: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 5.93,
                floatingLongProfitEOD: 0.93,
                floatingProfit: 5.93,
                floatingProfitEOD: 0.93,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813732",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "GBPJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 185.733,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -3.66,
                floatingProfitEOD: -0.87,
                floatingShortProfit: -3.66,
                floatingShortProfitEOD: -0.87,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814888",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "GBPJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 186.106,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -4.95,
                floatingLongProfitEOD: 1.84,
                floatingProfit: -4.95,
                floatingProfitEOD: 1.84,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814569",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "GBPJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 186.992,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -43.78,
                floatingProfitEOD: -6.92,
                floatingShortProfit: -43.78,
                floatingShortProfitEOD: -6.92,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813822",
                longPositions: 0,
                netPositions: -0.08,
                shortPositions: 0.08,
                symbol: "GBPJPY",
                user_trade_number: 4,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 185.83325000000002,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -254.14,
                floatingProfitEOD: -17.35,
                floatingShortProfit: -254.14,
                floatingShortProfitEOD: -17.35,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812186",
                longPositions: 0,
                netPositions: -0.2,
                shortPositions: 0.2,
                symbol: "GBPJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 184.75,
            },
            {
                closedLongPositions: 0.19,
                closedLongProfit: 23.77,
                closedLongProfitEOD: 11.3,
                closedProfit: 23.77,
                closedProfitEOD: 11.3,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -30.06,
                floatingProfitEOD: -9.45,
                floatingShortProfit: -30.06,
                floatingShortProfitEOD: -9.45,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812045",
                longPositions: 8.673617379884035e-18,
                netPositions: -0.10999999999999999,
                shortPositions: 0.11,
                symbol: "GBPJPY",
                user_trade_number: 9,
                weightedAvgLongPrice: -76.8,
                weightedAvgShortPrice: 186.24418181818183,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -8.14,
                floatingLongProfitEOD: 36.18,
                floatingProfit: -8.14,
                floatingProfitEOD: 36.18,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813167",
                longPositions: 0.39,
                netPositions: 0.39,
                shortPositions: 0,
                symbol: "GBPJPY",
                user_trade_number: 3,
                weightedAvgLongPrice: 186.65123076923078,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -524.89,
                floatingProfitEOD: -22.56,
                floatingShortProfit: -524.89,
                floatingShortProfitEOD: -22.56,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813740",
                longPositions: 0,
                netPositions: -0.26,
                shortPositions: 0.26,
                symbol: "GBPJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 183.63,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -129.61,
                floatingProfitEOD: -34.3,
                floatingShortProfit: -129.61,
                floatingShortProfitEOD: -34.3,
                isInPercentageMode: false,
                login: "DEMO:MT4:8800893",
                longPositions: 0,
                netPositions: -0.4,
                shortPositions: 0.4,
                symbol: "GBPJPY",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 186.16,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -488.82,
                floatingProfitEOD: -17.26,
                floatingShortProfit: -488.82,
                floatingShortProfitEOD: -17.26,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814193",
                longPositions: 0,
                netPositions: -0.19999999999999998,
                shortPositions: 0.19999999999999998,
                symbol: "GBPJPY",
                user_trade_number: 8,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 182.99095000000003,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.33,
                floatingLongProfitEOD: 9.28,
                floatingProfit: 0.33,
                floatingProfitEOD: 9.28,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813958",
                longPositions: 0.1,
                netPositions: 0.1,
                shortPositions: 0,
                symbol: "GBPJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 186.617,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -240.86,
                floatingProfitEOD: -8.48,
                floatingShortProfit: -240.86,
                floatingShortProfitEOD: -8.48,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808668",
                longPositions: 0,
                netPositions: -0.1,
                shortPositions: 0.1,
                symbol: "GBPJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 183.043,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -24.7,
                floatingLongProfitEOD: 9.21,
                floatingProfit: -24.7,
                floatingProfitEOD: 9.21,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799396",
                longPositions: 0.1,
                netPositions: 0.1,
                shortPositions: 0,
                symbol: "GBPJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 186.991,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 32.51,
                floatingLongProfitEOD: 0.93,
                floatingProfit: 0.02,
                floatingProfitEOD: -7.73,
                floatingShortProfit: -32.49,
                floatingShortProfitEOD: -8.66,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799362",
                longPositions: 0.01,
                netPositions: -0.09,
                shortPositions: 0.09999999999999999,
                symbol: "GBPJPY",
                user_trade_number: 8,
                weightedAvgLongPrice: 181.751,
                weightedAvgShortPrice: 186.16660000000002,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -532.23,
                floatingProfitEOD: -43.38,
                floatingShortProfit: -532.23,
                floatingShortProfitEOD: -43.38,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799351",
                longPositions: 0,
                netPositions: -0.5,
                shortPositions: 0.5,
                symbol: "GBPJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 185.059,
            },
        ],
        EURJPY: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -37.07,
                floatingProfitEOD: -1.66,
                floatingShortProfit: -37.07,
                floatingShortProfitEOD: -1.66,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813224",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "EURJPY",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 160.66699999999997,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -3.17,
                floatingProfitEOD: -1.66,
                floatingShortProfit: -3.17,
                floatingShortProfitEOD: -1.66,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813216",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "EURJPY",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 163.20749999999998,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -429.44,
                floatingProfitEOD: -23.73,
                floatingShortProfit: -429.44,
                floatingShortProfitEOD: -23.73,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813822",
                longPositions: 0,
                netPositions: -0.30999999999999994,
                shortPositions: 0.30999999999999994,
                symbol: "EURJPY",
                user_trade_number: 12,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 161.36822580645165,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: -70.4,
                closedProfitEOD: -2.14,
                closedShortPositions: 0.1,
                closedShortProfit: -70.4,
                closedShortProfitEOD: -2.14,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813152",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "EURJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -251.45,
                floatingProfitEOD: -13.35,
                floatingShortProfit: -251.45,
                floatingShortProfitEOD: -13.35,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813991",
                longPositions: 0,
                netPositions: -0.16,
                shortPositions: 0.16,
                symbol: "EURJPY",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 161.089125,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -18.49,
                floatingLongProfitEOD: 8.94,
                floatingProfit: -18.49,
                floatingProfitEOD: 8.94,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813167",
                longPositions: 0.1,
                netPositions: 0.1,
                shortPositions: 0,
                symbol: "EURJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 163.7,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -109.33,
                floatingLongProfitEOD: 26.83,
                floatingProfit: -109.33,
                floatingProfitEOD: 26.83,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814894",
                longPositions: 0.3,
                netPositions: 0.3,
                shortPositions: 0,
                symbol: "EURJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 163.969,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -7.74,
                floatingProfitEOD: -16.68,
                floatingShortProfit: -7.74,
                floatingShortProfitEOD: -16.68,
                isInPercentageMode: false,
                login: "DEMO:MT4:8800893",
                longPositions: 0,
                netPositions: -0.2,
                shortPositions: 0.2,
                symbol: "EURJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 163.379,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -215.53,
                floatingProfitEOD: -12.5,
                floatingShortProfit: -215.53,
                floatingShortProfitEOD: -12.5,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814194",
                longPositions: 0,
                netPositions: -0.15,
                shortPositions: 0.15,
                symbol: "EURJPY",
                user_trade_number: 5,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 161.291,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -8752.57,
                floatingProfitEOD: -155.15,
                floatingShortProfit: -8752.57,
                floatingShortProfitEOD: -155.15,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802384",
                longPositions: 0,
                netPositions: -1.8600000000000003,
                shortPositions: 1.8600000000000003,
                symbol: "EURJPY",
                user_trade_number: 13,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 156.39399999999995,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -24.25,
                floatingProfitEOD: -5,
                floatingShortProfit: -24.25,
                floatingShortProfitEOD: -5,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812848",
                longPositions: 0,
                netPositions: -0.06,
                shortPositions: 0.06,
                symbol: "EURJPY",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 162.8385,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -282.9,
                floatingProfitEOD: -8.34,
                floatingShortProfit: -282.9,
                floatingShortProfitEOD: -8.34,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808668",
                longPositions: 0,
                netPositions: -0.1,
                shortPositions: 0.1,
                symbol: "EURJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 159.206,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -3037.43,
                floatingProfitEOD: -16.6,
                floatingShortProfit: -3037.43,
                floatingShortProfitEOD: -16.6,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807466",
                longPositions: 0,
                netPositions: -0.20000000000000004,
                shortPositions: 0.20000000000000004,
                symbol: "EURJPY",
                user_trade_number: 20,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 140.68874999999997,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1741.4,
                floatingProfitEOD: -120.12,
                floatingShortProfit: -1741.4,
                floatingShortProfitEOD: -120.12,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799360",
                longPositions: 0,
                netPositions: -1.44,
                shortPositions: 1.44,
                symbol: "EURJPY",
                user_trade_number: 4,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 161.63197222222223,
            },
            {
                closedLongPositions: 0.02,
                closedLongProfit: 1.35,
                closedLongProfitEOD: 1.24,
                closedProfit: 1.35,
                closedProfitEOD: 1.24,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -230.42,
                floatingProfitEOD: -71.63,
                floatingShortProfit: -230.42,
                floatingShortProfitEOD: -71.63,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799318",
                longPositions: 0.01,
                netPositions: -1.0200000000000002,
                shortPositions: 1.0300000000000002,
                symbol: "EURJPY",
                user_trade_number: 24,
                weightedAvgLongPrice: 163.425,
                weightedAvgShortPrice: 163.10879611650483,
            },
        ],
        EURUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 34611.11,
                floatingLongProfitEOD: -6275.5,
                floatingProfit: -426719.29,
                floatingProfitEOD: 113.35,
                floatingShortProfit: -461330.4,
                floatingShortProfitEOD: 6388.85,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808384",
                longPositions: 114.1,
                netPositions: -2.3100000000000165,
                shortPositions: 116.41000000000001,
                symbol: "EURUSD",
                user_trade_number: 72,
                weightedAvgLongPrice: 1.0876465985977215,
                weightedAvgShortPrice: 1.0512113856197924,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -5.58,
                floatingProfitEOD: 1.1,
                floatingShortProfit: -5.58,
                floatingShortProfitEOD: 1.1,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814544",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "EURUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.08798,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 55618.9,
                floatingLongProfitEOD: -6820.55,
                floatingProfit: -448541.99,
                floatingProfitEOD: 76.99,
                floatingShortProfit: -504160.89,
                floatingShortProfitEOD: 6897.54,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808385",
                longPositions: 124.01,
                netPositions: -2,
                shortPositions: 126.01,
                symbol: "EURUSD",
                user_trade_number: 76,
                weightedAvgLongPrice: 1.0861949665349568,
                weightedAvgShortPrice: 1.0508330259503216,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -3.45,
                floatingProfitEOD: 0.55,
                floatingShortProfit: -3.45,
                floatingShortProfitEOD: 0.55,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814888",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "EURUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.08739,
            },
            {
                closedLongPositions: 0.01,
                closedLongProfit: 5.35,
                closedLongProfitEOD: -0.46,
                closedProfit: 5.35,
                closedProfitEOD: -0.46,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 3.35,
                floatingLongProfitEOD: -0.55,
                floatingProfit: -29.46,
                floatingProfitEOD: 1.65,
                floatingShortProfit: -32.81,
                floatingShortProfitEOD: 2.2,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812524",
                longPositions: 0.01,
                netPositions: -0.03,
                shortPositions: 0.04,
                symbol: "EURUSD",
                user_trade_number: 4,
                weightedAvgLongPrice: 1.08734,
                weightedAvgShortPrice: 1.0826375000000001,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -61.46,
                floatingProfitEOD: 6.53,
                floatingShortProfit: -61.46,
                floatingShortProfitEOD: 6.53,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811779",
                longPositions: 0,
                netPositions: -0.12000000000000001,
                shortPositions: 0.12000000000000001,
                symbol: "EURUSD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.0857241666666666,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -5.37,
                floatingProfitEOD: 1.06,
                floatingShortProfit: -5.37,
                floatingShortProfitEOD: 1.06,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810469",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "EURUSD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.08905,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 15.3,
                floatingLongProfitEOD: -5.5,
                floatingProfit: 15.3,
                floatingProfitEOD: -5.5,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810787",
                longPositions: 0.1,
                netPositions: 0.1,
                shortPositions: 0,
                symbol: "EURUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.08915,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0.02,
                floatingProfitEOD: 0.02,
                floatingShortProfit: 0.02,
                floatingShortProfitEOD: 0.02,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812186",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "EURUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.09086,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 43.48,
                floatingLongProfitEOD: -1.1,
                floatingProfit: 43.48,
                floatingProfitEOD: -1.1,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811059",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "EURUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 1.06894,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -344.34,
                floatingProfitEOD: 7.15,
                floatingShortProfit: -344.34,
                floatingShortProfitEOD: 7.15,
                isInPercentageMode: false,
                login: "DEMO:MT4:8806738",
                longPositions: 0,
                netPositions: -0.13,
                shortPositions: 0.13,
                symbol: "EURUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.0642823076923076,
            },
            {
                closedLongPositions: 0.01,
                closedLongProfit: 2.13,
                closedLongProfitEOD: -0.6,
                closedProfit: 2.13,
                closedProfitEOD: -0.6,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -42.28,
                floatingProfitEOD: 0.54,
                floatingShortProfit: -42.28,
                floatingShortProfitEOD: 0.54,
                isInPercentageMode: false,
                login: "DEMO:MT4:8805626",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "EURUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.04857,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 61.5,
                floatingLongProfitEOD: -24.75,
                floatingProfit: -1824.6,
                floatingProfitEOD: 2.75,
                floatingShortProfit: -1886.1,
                floatingShortProfitEOD: 27.5,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807928",
                longPositions: 0.44999999999999996,
                netPositions: -0.04999999999999993,
                shortPositions: 0.4999999999999999,
                symbol: "EURUSD",
                user_trade_number: 12,
                weightedAvgLongPrice: 1.0893233333333334,
                weightedAvgShortPrice: 1.0531180000000002,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -787.54,
                floatingProfitEOD: 26.95,
                floatingShortProfit: -787.54,
                floatingShortProfitEOD: 26.95,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814193",
                longPositions: 0,
                netPositions: -0.4900000000000001,
                shortPositions: 0.4900000000000001,
                symbol: "EURUSD",
                user_trade_number: 15,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.0747677551020403,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -455.57,
                floatingProfitEOD: 17.05,
                floatingShortProfit: -455.57,
                floatingShortProfitEOD: 17.05,
                isInPercentageMode: false,
                login: "DEMO:MT4:8803208",
                longPositions: 0,
                netPositions: -0.31000000000000005,
                shortPositions: 0.31000000000000005,
                symbol: "EURUSD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.076074193548387,
            },
        ],
        USOUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -615471.92,
                floatingLongProfitEOD: 6697.98,
                floatingProfit: -615471.92,
                floatingProfitEOD: 6697.98,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808384",
                longPositions: 42.31,
                netPositions: 42.31,
                shortPositions: 0,
                symbol: "USOUSD",
                user_trade_number: 24,
                weightedAvgLongPrice: 90.85303190735051,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -293603.12,
                floatingLongProfitEOD: 4113.08,
                floatingProfit: -293603.12,
                floatingProfitEOD: 4113.08,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808385",
                longPositions: 26.009999999999998,
                netPositions: 26.009999999999998,
                shortPositions: 0,
                symbol: "USOUSD",
                user_trade_number: 17,
                weightedAvgLongPrice: 87.59422068435218,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 11.45,
                floatingLongProfitEOD: 7.9,
                floatingProfit: 11.45,
                floatingProfitEOD: 7.9,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802181",
                longPositions: 0.05,
                netPositions: 0.05,
                shortPositions: 0,
                symbol: "USOUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 76.077,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 2.01,
                closedLongProfit: -468.33,
                closedLongProfitEOD: -631.14,
                closedProfit: -468.33,
                closedProfitEOD: -631.14,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809051",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "USOUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0.07,
                closedLongProfit: 67.08,
                closedLongProfitEOD: 0.07,
                closedProfit: 67.08,
                closedProfitEOD: 0.07,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 111.97,
                floatingLongProfitEOD: 9.48,
                floatingProfit: 111.97,
                floatingProfitEOD: 9.48,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811093",
                longPositions: 0.06,
                netPositions: 0.06,
                shortPositions: 0,
                symbol: "USOUSD",
                user_trade_number: 9,
                weightedAvgLongPrice: 74.4408333333333,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0.03,
                closedLongProfit: -0.51,
                closedLongProfitEOD: -4.03,
                closedProfit: -0.51,
                closedProfitEOD: -4.03,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -72.75,
                floatingProfitEOD: -8.3,
                floatingShortProfit: -72.75,
                floatingShortProfitEOD: -8.3,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810469",
                longPositions: -3.469446951953614e-18,
                netPositions: -0.05,
                shortPositions: 0.05,
                symbol: "USOUSD",
                user_trade_number: 8,
                weightedAvgLongPrice: 64,
                weightedAvgShortPrice: 74.892,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 9660,
                floatingLongProfitEOD: 790,
                floatingProfit: 26338,
                floatingProfitEOD: -206,
                floatingShortProfit: 16678,
                floatingShortProfitEOD: -996,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812560",
                longPositions: 5,
                netPositions: -1,
                shortPositions: 6,
                symbol: "USOUSD",
                user_trade_number: 4,
                weightedAvgLongPrice: 74.381,
                weightedAvgShortPrice: 79.12066666666666,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -3448,
                floatingLongProfitEOD: 316,
                floatingProfit: -3448,
                floatingProfitEOD: 316,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814024",
                longPositions: 2,
                netPositions: 2,
                shortPositions: 0,
                symbol: "USOUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 78.031,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -98.56,
                floatingLongProfitEOD: 3.16,
                floatingProfit: -101.47,
                floatingProfitEOD: 0.25,
                floatingShortProfit: -2.91,
                floatingShortProfitEOD: -2.91,
                isInPercentageMode: false,
                login: "DEMO:MT4:8804559",
                longPositions: 0.02,
                netPositions: 0.01,
                shortPositions: 0.01,
                symbol: "USOUSD",
                user_trade_number: 3,
                weightedAvgLongPrice: 81.23400000000001,
                weightedAvgShortPrice: 76.057,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -324.3,
                floatingLongProfitEOD: 3.17,
                floatingProfit: -324.3,
                floatingProfitEOD: 3.17,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802358",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "USOUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 92.5215,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -715.28,
                floatingLongProfitEOD: 12.65,
                floatingProfit: -715.28,
                floatingProfitEOD: 12.65,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802656",
                longPositions: 0.08,
                netPositions: 0.08,
                shortPositions: 0,
                symbol: "USOUSD",
                user_trade_number: 8,
                weightedAvgLongPrice: 85.247125,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 2227.06,
                floatingLongProfitEOD: 167.48,
                floatingProfit: 2227.06,
                floatingProfitEOD: 167.48,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802378",
                longPositions: 1.06,
                netPositions: 1.06,
                shortPositions: 0,
                symbol: "USOUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 74.205,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -6.72,
                floatingLongProfitEOD: 6.32,
                floatingProfit: -19.12,
                floatingProfitEOD: -0.32,
                floatingShortProfit: -12.4,
                floatingShortProfitEOD: -6.64,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814293",
                longPositions: 0.04,
                netPositions: 0,
                shortPositions: 0.04,
                symbol: "USOUSD",
                user_trade_number: 3,
                weightedAvgLongPrice: 76.47500000000001,
                weightedAvgShortPrice: 76.037,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -6.75,
                floatingProfitEOD: -6.75,
                floatingShortProfit: -6.75,
                floatingShortProfitEOD: -6.75,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807401",
                longPositions: 0,
                netPositions: -0.05,
                shortPositions: 0.05,
                symbol: "USOUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 76.213,
            },
            {
                closedLongPositions: 0.15,
                closedLongProfit: 0.45,
                closedLongProfitEOD: -18.65,
                closedProfit: 0.45,
                closedProfitEOD: -18.65,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -361.25,
                floatingProfitEOD: -41.5,
                floatingShortProfit: -361.25,
                floatingShortProfitEOD: -41.5,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812727",
                longPositions: 1.3877787807814457e-17,
                netPositions: -0.25,
                shortPositions: 0.25,
                symbol: "USOUSD",
                user_trade_number: 8,
                weightedAvgLongPrice: -64,
                weightedAvgShortPrice: 74.902,
            },
        ],
        XAGUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -235.75,
                floatingProfitEOD: 87.5,
                floatingShortProfit: -235.75,
                floatingShortProfitEOD: 87.5,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810918",
                longPositions: 0,
                netPositions: -0.1,
                shortPositions: 0.1,
                symbol: "XAGUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 23.202499999999997,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -24,
                floatingLongProfitEOD: -24,
                floatingProfit: -24,
                floatingProfitEOD: -24,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813469",
                longPositions: 0.3,
                netPositions: 0.3,
                shortPositions: 0,
                symbol: "XAGUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 23.672,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1583.5,
                floatingProfitEOD: 437.5,
                floatingShortProfit: -1583.5,
                floatingShortProfitEOD: 437.5,
                isInPercentageMode: false,
                login: "DEMO:MT4:8803781",
                longPositions: 0,
                netPositions: -0.5,
                shortPositions: 0.5,
                symbol: "XAGUSD",
                user_trade_number: 5,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 23.040600000000005,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1580,
                floatingProfitEOD: 437.5,
                floatingShortProfit: -1580,
                floatingShortProfitEOD: 437.5,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802670",
                longPositions: 0,
                netPositions: -0.5,
                shortPositions: 0.5,
                symbol: "XAGUSD",
                user_trade_number: 5,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 23.042,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -338.3,
                floatingProfitEOD: 17.4,
                floatingShortProfit: -338.3,
                floatingShortProfitEOD: 17.4,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802194",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "XAGUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 20.292,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -78,
                floatingProfitEOD: 131.25,
                floatingShortProfit: -78,
                floatingShortProfitEOD: 131.25,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812141",
                longPositions: 0,
                netPositions: -0.15,
                shortPositions: 0.15,
                symbol: "XAGUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 23.57,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 280,
                floatingProfitEOD: 875,
                floatingShortProfit: 280,
                floatingShortProfitEOD: 875,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810148",
                longPositions: 0,
                netPositions: -1,
                shortPositions: 1,
                symbol: "XAGUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 23.73,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: -575,
                closedProfitEOD: 840,
                closedShortPositions: 1,
                closedShortProfit: -575,
                closedShortProfitEOD: 840,
                floatingLongProfit: -4575,
                floatingLongProfitEOD: 1920,
                floatingProfit: -22205,
                floatingProfitEOD: 6295,
                floatingShortProfit: -17630,
                floatingShortProfitEOD: 4375,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814024",
                longPositions: 6,
                netPositions: 1,
                shortPositions: 5,
                symbol: "XAGUSD",
                user_trade_number: 8,
                weightedAvgLongPrice: 23.8085,
                weightedAvgShortPrice: 22.970799999999997,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -675.75,
                floatingProfitEOD: 43.75,
                floatingShortProfit: -675.75,
                floatingShortProfitEOD: 43.75,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807100",
                longPositions: 0,
                netPositions: -0.05,
                shortPositions: 0.05,
                symbol: "XAGUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 20.971,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -24.85,
                floatingProfitEOD: 17.5,
                floatingShortProfit: -24.85,
                floatingShortProfitEOD: 17.5,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809754",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "XAGUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 23.4255,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 8.25,
                closedProfitEOD: 8.35,
                closedShortPositions: 0.01,
                closedShortProfit: 8.25,
                closedShortProfitEOD: 8.35,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -13,
                floatingProfitEOD: 8.75,
                floatingShortProfit: -13,
                floatingShortProfitEOD: 8.75,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802682",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "XAGUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 23.414,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 90.7,
                floatingLongProfitEOD: 6.4,
                floatingProfit: 90.7,
                floatingProfitEOD: 6.4,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799569",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "XAGUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 22.754,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 65520,
                floatingLongProfitEOD: 2240,
                floatingProfit: 65520,
                floatingProfitEOD: 2240,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:100000010",
                longPositions: 7,
                netPositions: 7,
                shortPositions: 0,
                symbol: "XAGUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 21.789000000000005,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 250,
                floatingProfitEOD: 310,
                floatingShortProfit: 250,
                floatingShortProfitEOD: 310,
                isInPercentageMode: false,
                login: "DEMO:MT4:99999999",
                longPositions: 0,
                netPositions: -1,
                shortPositions: 1,
                symbol: "XAGUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 23.721,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -59.9,
                floatingProfitEOD: 17.4,
                floatingShortProfit: -59.9,
                floatingShortProfitEOD: 17.4,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811420",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "XAGUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 23.076,
            },
        ],
        BTCUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1.66,
                floatingProfitEOD: -1.66,
                floatingShortProfit: -1.66,
                floatingShortProfitEOD: -1.66,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811143",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "BTCUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 37400.19,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: -91.74,
                closedProfitEOD: -54.58,
                closedShortPositions: 0.14,
                closedShortProfit: -91.74,
                closedShortProfitEOD: -54.58,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 12.88,
                floatingProfitEOD: -25.63,
                floatingShortProfit: 12.88,
                floatingShortProfitEOD: -25.63,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814835",
                longPositions: 0,
                netPositions: -0.1,
                shortPositions: 0.1,
                symbol: "BTCUSD",
                user_trade_number: 5,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 37584.43000000001,
            },
            {
                closedLongPositions: 0.1,
                closedLongProfit: 14.42,
                closedLongProfitEOD: 15.92,
                closedProfit: 14.42,
                closedProfitEOD: 15.92,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 12.75,
                floatingLongProfitEOD: 9.14,
                floatingProfit: 12.75,
                floatingProfitEOD: 9.14,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802181",
                longPositions: 0.01999999999999999,
                netPositions: 0.01999999999999999,
                shortPositions: 0,
                symbol: "BTCUSD",
                user_trade_number: 4,
                weightedAvgLongPrice: 36804.25000000003,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 18.22,
                floatingLongProfitEOD: 10.91,
                floatingProfit: 18.22,
                floatingProfitEOD: 10.91,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813989",
                longPositions: 0.03,
                netPositions: 0.03,
                shortPositions: 0,
                symbol: "BTCUSD",
                user_trade_number: 3,
                weightedAvgLongPrice: 36835,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: -51.17,
                closedProfitEOD: -51.17,
                closedShortPositions: 0.25,
                closedShortProfit: -51.17,
                closedShortProfitEOD: -51.17,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 1.16,
                floatingProfitEOD: 1.16,
                floatingShortProfit: 1.16,
                floatingShortProfitEOD: 1.16,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809080",
                longPositions: 0,
                netPositions: -0.10000000000000002,
                shortPositions: 0.10000000000000002,
                symbol: "BTCUSD",
                user_trade_number: 5,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 37467.119999999995,
            },
            {
                closedLongPositions: 0.01,
                closedLongProfit: 2.22,
                closedLongProfitEOD: 1.46,
                closedProfit: 2.22,
                closedProfitEOD: 1.46,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813096",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "BTCUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -0.55,
                floatingProfitEOD: -0.55,
                floatingShortProfit: -0.55,
                floatingShortProfitEOD: -0.55,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810469",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "BTCUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 37400.19,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 47.62,
                floatingLongProfitEOD: 91.44,
                floatingProfit: 47.62,
                floatingProfitEOD: 91.44,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813781",
                longPositions: 0.20000000000000004,
                netPositions: 0.20000000000000004,
                shortPositions: 0,
                symbol: "BTCUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 37203.439999999995,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 12.78,
                floatingProfitEOD: 12.78,
                floatingShortProfit: 12.78,
                floatingShortProfitEOD: 12.78,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812793",
                longPositions: 0,
                netPositions: -1.01,
                shortPositions: 1.01,
                symbol: "BTCUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 37468.22,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 2051.99,
                floatingLongProfitEOD: 741.56,
                floatingProfit: -7363.78,
                floatingProfitEOD: -461.92,
                floatingShortProfit: -9415.77,
                floatingShortProfitEOD: -1203.48,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813740",
                longPositions: 1.62,
                netPositions: -1.1400000000000006,
                shortPositions: 2.7600000000000007,
                symbol: "BTCUSD",
                user_trade_number: 17,
                weightedAvgLongPrice: 36175.51666666666,
                weightedAvgShortPrice: 34044.34257246375,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -163.49,
                floatingProfitEOD: -140.28,
                floatingShortProfit: -163.49,
                floatingShortProfitEOD: -140.28,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812585",
                longPositions: 0,
                netPositions: -0.4,
                shortPositions: 0.4,
                symbol: "BTCUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 37048.68,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: -15.48,
                closedProfitEOD: -5.18,
                closedShortPositions: 0.02,
                closedShortProfit: -15.48,
                closedShortProfitEOD: -5.18,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802840",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "BTCUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -0.16,
                floatingProfitEOD: -0.16,
                floatingShortProfit: -0.16,
                floatingShortProfitEOD: -0.16,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813738",
                longPositions: 0,
                netPositions: -0.05,
                shortPositions: 0.05,
                symbol: "BTCUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 37452.35,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -2.77,
                floatingProfitEOD: -2.77,
                floatingShortProfit: -2.77,
                floatingShortProfitEOD: -2.77,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812727",
                longPositions: 0,
                netPositions: -0.05,
                shortPositions: 0.05,
                symbol: "BTCUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 37400.19,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 99.28,
                floatingLongProfitEOD: 4.57,
                floatingProfit: 99.28,
                floatingProfitEOD: 4.57,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999980",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "BTCUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 27513.31,
                weightedAvgShortPrice: 0,
            },
        ],
        GBPNZD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -0.05,
                floatingProfitEOD: 8.41,
                floatingShortProfit: -0.05,
                floatingShortProfitEOD: 8.41,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809024",
                longPositions: 0,
                netPositions: -0.05,
                shortPositions: 0.05,
                symbol: "GBPNZD",
                user_trade_number: 5,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 2.077222,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 1.92,
                closedProfitEOD: 3.5,
                closedShortPositions: 0.04,
                closedShortProfit: 1.92,
                closedShortProfitEOD: 3.5,
                floatingLongProfit: -6.78,
                floatingLongProfitEOD: -3.24,
                floatingProfit: -6.78,
                floatingProfitEOD: -3.24,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814194",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "GBPNZD",
                user_trade_number: 3,
                weightedAvgLongPrice: 2.08243,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -57.94,
                floatingLongProfitEOD: -3.23,
                floatingProfit: -57.94,
                floatingProfitEOD: -3.23,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809801",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "GBPNZD",
                user_trade_number: 2,
                weightedAvgLongPrice: 2.125075,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 1.92,
                closedProfitEOD: 8.2,
                closedShortPositions: 0.04,
                closedShortProfit: 1.92,
                closedShortProfitEOD: 8.2,
                floatingLongProfit: -6.67,
                floatingLongProfitEOD: -3.24,
                floatingProfit: -6.67,
                floatingProfitEOD: -3.24,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799362",
                longPositions: 0.02,
                netPositions: 0.020000000000000004,
                shortPositions: -1.734723475976807e-18,
                symbol: "GBPNZD",
                user_trade_number: 5,
                weightedAvgLongPrice: 2.08234,
                weightedAvgShortPrice: 2,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -496.31,
                floatingLongProfitEOD: -250.94,
                floatingProfit: -496.31,
                floatingProfitEOD: -250.94,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799838",
                longPositions: 1.55,
                netPositions: 1.55,
                shortPositions: 0,
                symbol: "GBPNZD",
                user_trade_number: 1,
                weightedAvgLongPrice: 2.08218,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 95453.18,
                floatingLongProfitEOD: -1878.04,
                floatingProfit: 95453.18,
                floatingProfitEOD: -1878.04,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799947",
                longPositions: 11.6,
                netPositions: 11.6,
                shortPositions: 0,
                symbol: "GBPNZD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.9396100000000003,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 10711.97,
                floatingLongProfitEOD: -210.47,
                floatingProfit: 10711.97,
                floatingProfitEOD: -210.47,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799946",
                longPositions: 1.3,
                netPositions: 1.3,
                shortPositions: 0,
                symbol: "GBPNZD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.93942,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 13.12,
                closedProfitEOD: 36.81,
                closedShortPositions: 0.22,
                closedShortProfit: 13.12,
                closedShortProfitEOD: 36.81,
                floatingLongProfit: -9.59,
                floatingLongProfitEOD: -7.46,
                floatingProfit: -10.66,
                floatingProfitEOD: -8.53,
                floatingShortProfit: -1.07,
                floatingShortProfitEOD: -1.07,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799318",
                longPositions: 0.08,
                netPositions: 0.059999999999999984,
                shortPositions: 0.02000000000000002,
                symbol: "GBPNZD",
                user_trade_number: 19,
                weightedAvgLongPrice: 2.07877625,
                weightedAvgShortPrice: 2.0763899999999995,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 40.52,
                floatingLongProfitEOD: -38.86,
                floatingProfit: 40.52,
                floatingProfitEOD: -38.86,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799402",
                longPositions: 0.24000000000000002,
                netPositions: 0.24000000000000002,
                shortPositions: 0,
                symbol: "GBPNZD",
                user_trade_number: 2,
                weightedAvgLongPrice: 2.073964166666667,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -110.52,
                floatingLongProfitEOD: -6.48,
                floatingProfit: -110.52,
                floatingProfitEOD: -6.48,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799710",
                longPositions: 0.04,
                netPositions: 0.04,
                shortPositions: 0,
                symbol: "GBPNZD",
                user_trade_number: 2,
                weightedAvgLongPrice: 2.12286,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -954.2,
                floatingProfitEOD: 66.46,
                floatingShortProfit: -954.2,
                floatingShortProfitEOD: 66.46,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799732",
                longPositions: 0,
                netPositions: -0.4,
                shortPositions: 0.4,
                symbol: "GBPNZD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 2.0374999999999996,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 1.92,
                closedProfitEOD: 8.16,
                closedShortPositions: 0.04,
                closedShortProfit: 1.92,
                closedShortProfitEOD: 8.16,
                floatingLongProfit: -6.68,
                floatingLongProfitEOD: -3.24,
                floatingProfit: -6.68,
                floatingProfitEOD: -3.24,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799775",
                longPositions: 0.02,
                netPositions: 0.020000000000000004,
                shortPositions: -3.469446951953614e-18,
                symbol: "GBPNZD",
                user_trade_number: 5,
                weightedAvgLongPrice: 2.08235,
                weightedAvgShortPrice: 2,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 21.03,
                closedProfitEOD: 69.93,
                closedShortPositions: 0.44,
                closedShortProfit: 21.03,
                closedShortProfitEOD: 69.93,
                floatingLongProfit: -66.8,
                floatingLongProfitEOD: -32.38,
                floatingProfit: -66.8,
                floatingProfitEOD: -32.38,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999596",
                longPositions: 0.2,
                netPositions: 0.19999999999999996,
                shortPositions: 5.551115123125783e-17,
                symbol: "GBPNZD",
                user_trade_number: 5,
                weightedAvgLongPrice: 2.08235,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 1.94,
                closedProfitEOD: 7.58,
                closedShortPositions: 0.04,
                closedShortProfit: 1.94,
                closedShortProfitEOD: 7.58,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812047",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "GBPNZD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -3.05,
                floatingLongProfitEOD: -1.62,
                floatingProfit: -3.05,
                floatingProfitEOD: -1.62,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808263",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "GBPNZD",
                user_trade_number: 1,
                weightedAvgLongPrice: 2.08184,
                weightedAvgShortPrice: 0,
            },
        ],
        XAUUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -78.66,
                floatingProfitEOD: 18.79,
                floatingShortProfit: -78.66,
                floatingShortProfitEOD: 18.79,
                isInPercentageMode: false,
                login: "DEMO:MT4:8815017",
                longPositions: 17,
                netPositions: 16.88000000000000001,
                shortPositions: 0.12000000000000001,
                symbol: "XAUUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1971.1558333333335,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -99.46,
                floatingProfitEOD: 14.85,
                floatingShortProfit: -99.46,
                floatingShortProfitEOD: 14.85,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814844",
                longPositions: 21,
                netPositions: 20.955,
                shortPositions: 0.05,
                symbol: "XAUUSD",
                user_trade_number: 5,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1957.768,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1417.54,
                floatingLongProfitEOD: -216.41,
                floatingProfit: -8028.57,
                floatingProfitEOD: 77.17,
                floatingShortProfit: -6611.03,
                floatingShortProfitEOD: 293.58,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812423",
                longPositions: 0.72,
                netPositions: -0.28000000000000025,
                shortPositions: 1.0000000000000002,
                symbol: "XAUUSD",
                user_trade_number: 22,
                weightedAvgLongPrice: 1997.1223611111113,
                weightedAvgShortPrice: 1911.5838999999996,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -189.35,
                floatingProfitEOD: 32.43,
                floatingShortProfit: -189.35,
                floatingShortProfitEOD: 32.43,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814843",
                longPositions: 0,
                netPositions: -0.10999999999999999,
                shortPositions: 0.10999999999999999,
                symbol: "XAUUSD",
                user_trade_number: 9,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1960.468181818182,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -304.7,
                floatingLongProfitEOD: -36.18,
                floatingProfit: -883.72,
                floatingProfitEOD: 10.36,
                floatingShortProfit: -579.02,
                floatingShortProfitEOD: 46.54,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813877",
                longPositions: 0.12000000000000001,
                netPositions: -0.039999999999999994,
                shortPositions: 0.16,
                symbol: "XAUUSD",
                user_trade_number: 12,
                weightedAvgLongPrice: 2002.8166666666662,
                weightedAvgShortPrice: 1941.5324999999998,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 69.55,
                floatingLongProfitEOD: -14.7,
                floatingProfit: -1554.13,
                floatingProfitEOD: 32.66,
                floatingShortProfit: -1623.68,
                floatingShortProfitEOD: 47.36,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812667",
                longPositions: 0.05,
                netPositions: -0.11,
                shortPositions: 0.16,
                symbol: "XAUUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 1963.59,
                weightedAvgShortPrice: 1876.19,
            },
            {
                closedLongPositions: 0.01,
                closedLongProfit: -11.99,
                closedLongProfitEOD: -2.56,
                closedProfit: -11.99,
                closedProfitEOD: -2.56,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802181",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "XAUUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -223.4,
                floatingLongProfitEOD: -57.38,
                floatingProfit: -1048.23,
                floatingProfitEOD: -1.23,
                floatingShortProfit: -824.83,
                floatingShortProfitEOD: 56.15,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810480",
                longPositions: 0.19,
                netPositions: 0,
                shortPositions: 0.19,
                symbol: "XAUUSD",
                user_trade_number: 10,
                weightedAvgLongPrice: 1989.177894736842,
                weightedAvgShortPrice: 1934.262631578947,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 47.63,
                floatingProfitEOD: 14.66,
                floatingShortProfit: 47.63,
                floatingShortProfitEOD: 14.66,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814969",
                longPositions: 0,
                netPositions: -0.05,
                shortPositions: 0.05,
                symbol: "XAUUSD",
                user_trade_number: 5,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1987.224,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.36,
                floatingLongProfitEOD: -6.08,
                floatingProfit: -403.88,
                floatingProfitEOD: 5.74,
                floatingShortProfit: -403.52,
                floatingShortProfitEOD: 11.82,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812660",
                longPositions: 0.02,
                netPositions: -0.02,
                shortPositions: 0.04,
                symbol: "XAUUSD",
                user_trade_number: 4,
                weightedAvgLongPrice: 1977.5799999999997,
                weightedAvgShortPrice: 1876.7949999999998,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 6.6,
                closedProfitEOD: 15.35,
                closedShortPositions: 0.05,
                closedShortProfit: 6.6,
                closedShortProfitEOD: 15.35,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813991",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "XAUUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -120.1,
                floatingLongProfitEOD: -52,
                floatingProfit: -4898.1,
                floatingProfitEOD: 100.4,
                floatingShortProfit: -4778,
                floatingShortProfitEOD: 152.4,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809929",
                longPositions: 0.2,
                netPositions: -0.39999999999999997,
                shortPositions: 0.6,
                symbol: "XAUUSD",
                user_trade_number: 8,
                weightedAvgLongPrice: 1983.775,
                weightedAvgShortPrice: 1898.5166666666667,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -69.12,
                floatingLongProfitEOD: -31.2,
                floatingProfit: -501.8,
                floatingProfitEOD: -3.26,
                floatingShortProfit: -432.68,
                floatingShortProfitEOD: 27.94,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807988",
                longPositions: 0.12,
                netPositions: 0.009999999999999995,
                shortPositions: 0.11,
                symbol: "XAUUSD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1983.53,
                weightedAvgShortPrice: 1938.8154545454545,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -73.06,
                floatingLongProfitEOD: -35.94,
                floatingProfit: -853.08,
                floatingProfitEOD: 17.36,
                floatingShortProfit: -780.02,
                floatingShortProfitEOD: 53.3,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814609",
                longPositions: 0.12000000000000001,
                netPositions: -0.059999999999999984,
                shortPositions: 0.18,
                symbol: "XAUUSD",
                user_trade_number: 15,
                weightedAvgLongPrice: 1983.533333333333,
                weightedAvgShortPrice: 1934.3344444444447,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -3851.81,
                floatingLongProfitEOD: -1751.87,
                floatingProfit: -26336.27,
                floatingProfitEOD: -27.91,
                floatingShortProfit: -22484.46,
                floatingShortProfitEOD: 1723.96,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808295",
                longPositions: 5.8599999999999985,
                netPositions: -0.030000000000002025,
                shortPositions: 5.890000000000001,
                symbol: "XAUUSD",
                user_trade_number: 49,
                weightedAvgLongPrice: 1984.0235153583626,
                weightedAvgShortPrice: 1939.4206451612897,
            },
        ],
        NZDUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -13.1,
                floatingLongProfitEOD: 9.4,
                floatingProfit: -13.1,
                floatingProfitEOD: 9.4,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799396",
                longPositions: 0.2,
                netPositions: 0.2,
                shortPositions: 0,
                symbol: "NZDUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0.6002749999999999,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -15.53,
                floatingProfitEOD: -2.73,
                floatingShortProfit: -15.53,
                floatingShortProfitEOD: -2.73,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799361",
                longPositions: 0,
                netPositions: -0.060000000000000005,
                shortPositions: 0.060000000000000005,
                symbol: "NZDUSD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.5972066666666667,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.32,
                floatingLongProfitEOD: 1.92,
                floatingProfit: 0.32,
                floatingProfitEOD: 1.92,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799394",
                longPositions: 0.04,
                netPositions: 0.04,
                shortPositions: 0,
                symbol: "NZDUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0.59955,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.28,
                floatingLongProfitEOD: 0.48,
                floatingProfit: -30.14,
                floatingProfitEOD: -5.04,
                floatingShortProfit: -30.42,
                floatingShortProfitEOD: -5.52,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799318",
                longPositions: 0.01,
                netPositions: -0.14,
                shortPositions: 0.15000000000000002,
                symbol: "NZDUSD",
                user_trade_number: 10,
                weightedAvgLongPrice: 0.59935,
                weightedAvgShortPrice: 0.5977679999999997,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.46,
                floatingLongProfitEOD: 0.48,
                floatingProfit: -30.21,
                floatingProfitEOD: -5.09,
                floatingShortProfit: -30.67,
                floatingShortProfitEOD: -5.57,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799317",
                longPositions: 0.01,
                netPositions: -0.14,
                shortPositions: 0.15000000000000002,
                symbol: "NZDUSD",
                user_trade_number: 10,
                weightedAvgLongPrice: 0.59917,
                weightedAvgShortPrice: 0.5977546666666664,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -9.26,
                floatingProfitEOD: -1.85,
                floatingShortProfit: -9.26,
                floatingShortProfitEOD: -1.85,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799515",
                longPositions: 0,
                netPositions: -0.04,
                shortPositions: 0.04,
                symbol: "NZDUSD",
                user_trade_number: 4,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.5974875,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -4.41,
                floatingLongProfitEOD: 1.41,
                floatingProfit: -9.59,
                floatingProfitEOD: 0.48,
                floatingShortProfit: -5.18,
                floatingShortProfitEOD: -0.93,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799426",
                longPositions: 0.03,
                netPositions: 0.009999999999999998,
                shortPositions: 0.02,
                symbol: "NZDUSD",
                user_trade_number: 5,
                weightedAvgLongPrice: 0.60109,
                weightedAvgShortPrice: 0.597215,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -96.9,
                floatingLongProfitEOD: 14.1,
                floatingProfit: -96.9,
                floatingProfitEOD: 14.1,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999546",
                longPositions: 0.3,
                netPositions: 0.3,
                shortPositions: 0,
                symbol: "NZDUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.60291,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1516,
                floatingProfitEOD: -94,
                floatingShortProfit: -1516,
                floatingShortProfitEOD: -94,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799589",
                longPositions: 0,
                netPositions: -2,
                shortPositions: 2,
                symbol: "NZDUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.59223,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -744.1,
                floatingProfitEOD: -16.45,
                floatingShortProfit: -744.1,
                floatingShortProfitEOD: -16.45,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799453",
                longPositions: 0,
                netPositions: -0.35000000000000003,
                shortPositions: 0.35000000000000003,
                symbol: "NZDUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.57855,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -9.8,
                floatingLongProfitEOD: 0.94,
                floatingProfit: -9.8,
                floatingProfitEOD: 0.94,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814325",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "NZDUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.6045,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -3,
                floatingLongProfitEOD: 0.47,
                floatingProfit: -3,
                floatingProfitEOD: 0.47,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813995",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "NZDUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.6026,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -17.3,
                floatingLongProfitEOD: 2.35,
                floatingProfit: -17.3,
                floatingProfitEOD: 2.35,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809252",
                longPositions: 0.05,
                netPositions: 0.05,
                shortPositions: 0,
                symbol: "NZDUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.60306,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -3.05,
                floatingLongProfitEOD: 0.47,
                floatingProfit: -3.05,
                floatingProfitEOD: 0.47,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812103",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "NZDUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.60265,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -18.43,
                floatingProfitEOD: -1.41,
                floatingShortProfit: -18.43,
                floatingShortProfitEOD: -1.41,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811132",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "NZDUSD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.5936766666666667,
            },
        ],
        USDJPY: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 82379.87,
                floatingLongProfitEOD: 2942.14,
                floatingProfit: 82379.87,
                floatingProfitEOD: 2942.14,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799947",
                longPositions: 23.2,
                netPositions: 23.2,
                shortPositions: 0,
                symbol: "USDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 144.511,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 9010.1,
                floatingLongProfitEOD: 329.72,
                floatingProfit: 9010.1,
                floatingProfitEOD: 329.72,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799946",
                longPositions: 2.6,
                netPositions: 2.6,
                shortPositions: 0,
                symbol: "USDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 144.639,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0.06,
                closedLongProfit: 4.03,
                closedLongProfitEOD: 6.66,
                closedProfit: 4.03,
                closedProfitEOD: 6.66,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.01,
                floatingLongProfitEOD: 0.01,
                floatingProfit: -30.16,
                floatingProfitEOD: -22.24,
                floatingShortProfit: -30.17,
                floatingShortProfitEOD: -22.25,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799318",
                longPositions: 0.010000000000000004,
                netPositions: -0.16999999999999998,
                shortPositions: 0.18,
                symbol: "USDJPY",
                user_trade_number: 16,
                weightedAvgLongPrice: 149.82399999999987,
                weightedAvgShortPrice: 149.58816666666664,
            },
            {
                closedLongPositions: 0.08,
                closedLongProfit: 5.33,
                closedLongProfitEOD: 9.28,
                closedProfit: 5.33,
                closedProfitEOD: 9.28,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.11,
                floatingLongProfitEOD: -0.11,
                floatingProfit: -24.05,
                floatingProfitEOD: -18.68,
                floatingShortProfit: -23.94,
                floatingShortProfitEOD: -18.57,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799317",
                longPositions: 0.009999999999999986,
                netPositions: -0.14,
                shortPositions: 0.15,
                symbol: "USDJPY",
                user_trade_number: 16,
                weightedAvgLongPrice: 149.84200000000013,
                weightedAvgShortPrice: 149.60033333333334,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 83.7,
                floatingLongProfitEOD: 115.06,
                floatingProfit: -33.61,
                floatingProfitEOD: 14.52,
                floatingShortProfit: -117.31,
                floatingShortProfitEOD: -100.54,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999999",
                longPositions: 1.61,
                netPositions: 0.8000000000000002,
                shortPositions: 0.8099999999999999,
                symbol: "USDJPY",
                user_trade_number: 9,
                weightedAvgLongPrice: 149.7451055900621,
                weightedAvgShortPrice: 149.624,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1814.13,
                floatingLongProfitEOD: 382.45,
                floatingProfit: -2789.16,
                floatingProfitEOD: 10.06,
                floatingShortProfit: -975.03,
                floatingShortProfitEOD: -372.39,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799711",
                longPositions: 3,
                netPositions: 0,
                shortPositions: 3,
                symbol: "USDJPY",
                user_trade_number: 2,
                weightedAvgLongPrice: 150.73,
                weightedAvgShortPrice: 149.354,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -37.71,
                floatingProfitEOD: -62.07,
                floatingShortProfit: -37.71,
                floatingShortProfitEOD: -62.07,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999103",
                longPositions: 0,
                netPositions: -0.5,
                shortPositions: 0.5,
                symbol: "USDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 149.728,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -210.81,
                floatingProfitEOD: -8.69,
                floatingShortProfit: -210.81,
                floatingShortProfitEOD: -8.69,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799685",
                longPositions: 0,
                netPositions: -0.07,
                shortPositions: 0.07,
                symbol: "USDJPY",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 145.32742857142858,
            },
            {
                closedLongPositions: 0.01,
                closedLongProfit: -2.31,
                closedLongProfitEOD: -1.35,
                closedProfit: -2.31,
                closedProfitEOD: -1.35,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814544",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "USDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -60.67,
                floatingLongProfitEOD: 12.68,
                floatingProfit: -60.67,
                floatingProfitEOD: 12.68,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812489",
                longPositions: 0.1,
                netPositions: 0.1,
                shortPositions: 0,
                symbol: "USDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 150.732,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 13.98,
                floatingLongProfitEOD: 6.37,
                floatingProfit: 13.98,
                floatingProfitEOD: 6.37,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812009",
                longPositions: 0.05,
                netPositions: 0.05,
                shortPositions: 0,
                symbol: "USDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 149.405,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -15.6,
                floatingLongProfitEOD: 7.6,
                floatingProfit: -15.6,
                floatingProfitEOD: 7.6,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814627",
                longPositions: 0.06,
                netPositions: 0.06,
                shortPositions: 0,
                symbol: "USDJPY",
                user_trade_number: 2,
                weightedAvgLongPrice: 150.2145,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 23.43,
                floatingLongProfitEOD: 12.68,
                floatingProfit: 23.43,
                floatingProfitEOD: 12.68,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813152",
                longPositions: 0.1,
                netPositions: 0.1,
                shortPositions: 0,
                symbol: "USDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 149.472,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 2.06,
                floatingLongProfitEOD: 14.01,
                floatingProfit: 2.06,
                floatingProfitEOD: 14.01,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812045",
                longPositions: 0.11,
                netPositions: 0.11,
                shortPositions: 0,
                symbol: "USDJPY",
                user_trade_number: 4,
                weightedAvgLongPrice: 149.79572727272728,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -5.73,
                floatingLongProfitEOD: 1.27,
                floatingProfit: -9.79,
                floatingProfitEOD: 0.03,
                floatingShortProfit: -4.06,
                floatingShortProfitEOD: -1.24,
                isInPercentageMode: false,
                login: "DEMO:MT4:8805626",
                longPositions: 0.01,
                netPositions: 0,
                shortPositions: 0.01,
                symbol: "USDJPY",
                user_trade_number: 2,
                weightedAvgLongPrice: 150.683,
                weightedAvgShortPrice: 149.232,
            },
        ],
        GBPAUD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -9.72,
                floatingProfitEOD: 1.8,
                floatingShortProfit: -9.72,
                floatingShortProfitEOD: 1.8,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799362",
                longPositions: 0,
                netPositions: -0.060000000000000005,
                shortPositions: 0.060000000000000005,
                symbol: "GBPAUD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.9102799999999998,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -52.55,
                floatingProfitEOD: 19.2,
                floatingShortProfit: -52.55,
                floatingShortProfitEOD: 19.2,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999604",
                longPositions: 0,
                netPositions: -0.51,
                shortPositions: 0.51,
                symbol: "GBPAUD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.9110701960784313,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -5.44,
                floatingLongProfitEOD: -0.97,
                floatingProfit: -8.06,
                floatingProfitEOD: -0.21,
                floatingShortProfit: -2.62,
                floatingShortProfitEOD: 0.76,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799426",
                longPositions: 0.03,
                netPositions: 0.009999999999999998,
                shortPositions: 0.02,
                symbol: "GBPAUD",
                user_trade_number: 5,
                weightedAvgLongPrice: 1.9151066666666667,
                weightedAvgShortPrice: 1.9106299999999998,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -32.53,
                floatingLongProfitEOD: -1.36,
                floatingProfit: -32.53,
                floatingProfitEOD: -1.36,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799403",
                longPositions: 0.04,
                netPositions: 0.04,
                shortPositions: 0,
                symbol: "GBPAUD",
                user_trade_number: 3,
                weightedAvgLongPrice: 1.9247899999999998,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -6.17,
                floatingLongProfitEOD: -0.61,
                floatingProfit: -7.36,
                floatingProfitEOD: -0.23,
                floatingShortProfit: -1.19,
                floatingShortProfitEOD: 0.38,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799446",
                longPositions: 0.02,
                netPositions: 0.01,
                shortPositions: 0.01,
                symbol: "GBPAUD",
                user_trade_number: 3,
                weightedAvgLongPrice: 1.9170900000000002,
                weightedAvgShortPrice: 1.9108199999999997,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -24.47,
                floatingLongProfitEOD: -4.93,
                floatingProfit: -45.79,
                floatingProfitEOD: 0.64,
                floatingShortProfit: -21.32,
                floatingShortProfitEOD: 5.57,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799488",
                longPositions: 0.18,
                netPositions: 0.01999999999999999,
                shortPositions: 0.16,
                symbol: "GBPAUD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1.914486666666667,
                weightedAvgShortPrice: 1.910650625,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -9.8,
                floatingProfitEOD: 1.76,
                floatingShortProfit: -9.8,
                floatingShortProfitEOD: 1.76,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799806",
                longPositions: 0,
                netPositions: -0.060000000000000005,
                shortPositions: 0.060000000000000005,
                symbol: "GBPAUD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.9102683333333332,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 106463.31,
                floatingLongProfitEOD: -445.67,
                floatingProfit: 106463.31,
                floatingProfitEOD: -445.67,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799947",
                longPositions: 11.6,
                netPositions: 11.6,
                shortPositions: 0,
                symbol: "GBPAUD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.7713500000000002,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 11971.87,
                floatingLongProfitEOD: -52.49,
                floatingProfit: 11971.87,
                floatingProfitEOD: -52.49,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799946",
                longPositions: 1.3,
                netPositions: 1.3,
                shortPositions: 0,
                symbol: "GBPAUD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.77084,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -9.73,
                floatingProfitEOD: 1.73,
                floatingShortProfit: -9.73,
                floatingShortProfitEOD: 1.73,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799801",
                longPositions: 0,
                netPositions: -0.060000000000000005,
                shortPositions: 0.060000000000000005,
                symbol: "GBPAUD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.9102933333333334,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -11.83,
                floatingProfitEOD: 5.52,
                floatingShortProfit: -11.83,
                floatingShortProfitEOD: 5.52,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799823",
                longPositions: 0,
                netPositions: -0.15,
                shortPositions: 0.15,
                symbol: "GBPAUD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.9114540000000002,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -7.03,
                floatingLongProfitEOD: -6.25,
                floatingProfit: -7.03,
                floatingProfitEOD: -6.25,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999545",
                longPositions: 0.3,
                netPositions: 0.3,
                shortPositions: 0,
                symbol: "GBPAUD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.91292,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -14.91,
                floatingProfitEOD: 5.64,
                floatingShortProfit: -14.91,
                floatingShortProfitEOD: 5.64,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799775",
                longPositions: 0,
                netPositions: -0.15,
                shortPositions: 0.15,
                symbol: "GBPAUD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.9111253333333333,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -10.12,
                floatingLongProfitEOD: -1.22,
                floatingProfit: -13.33,
                floatingProfitEOD: -0.62,
                floatingShortProfit: -3.21,
                floatingShortProfitEOD: 0.6,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799312",
                longPositions: 0.04,
                netPositions: 0.02,
                shortPositions: 0.02,
                symbol: "GBPAUD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1.9162350000000001,
                weightedAvgShortPrice: 1.910305,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -7.4,
                floatingProfitEOD: 2.26,
                floatingShortProfit: -7.4,
                floatingShortProfitEOD: 2.26,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799455",
                longPositions: 0,
                netPositions: -0.060000000000000005,
                shortPositions: 0.060000000000000005,
                symbol: "GBPAUD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.9107499999999997,
            },
        ],
        NZDCHF: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -732.58,
                floatingLongProfitEOD: 58.79,
                floatingProfit: -732.58,
                floatingProfitEOD: 58.79,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799462",
                longPositions: 0.8400000000000001,
                netPositions: 0.8400000000000001,
                shortPositions: 0,
                symbol: "NZDCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0.5390057142857142,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.99,
                floatingLongProfitEOD: 2.09,
                floatingProfit: -3.73,
                floatingProfitEOD: 1.47,
                floatingShortProfit: -2.74,
                floatingShortProfitEOD: -0.62,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799361",
                longPositions: 0.03,
                netPositions: 0.019999999999999997,
                shortPositions: 0.01,
                symbol: "NZDCHF",
                user_trade_number: 4,
                weightedAvgLongPrice: 0.53151,
                weightedAvgShortPrice: 0.529,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.5,
                floatingLongProfitEOD: 21.49,
                floatingProfit: -12.48,
                floatingProfitEOD: 16.39,
                floatingShortProfit: -12.98,
                floatingShortProfitEOD: -5.1,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799318",
                longPositions: 0.30999999999999994,
                netPositions: 0.22999999999999995,
                shortPositions: 0.07999999999999999,
                symbol: "NZDCHF",
                user_trade_number: 19,
                weightedAvgLongPrice: 0.5312,
                weightedAvgShortPrice: 0.53000875,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -16.6,
                floatingLongProfitEOD: 21.61,
                floatingProfit: -29.69,
                floatingProfitEOD: 16.51,
                floatingShortProfit: -13.09,
                floatingShortProfitEOD: -5.1,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799317",
                longPositions: 0.31000000000000005,
                netPositions: 0.23000000000000007,
                shortPositions: 0.07999999999999999,
                symbol: "NZDCHF",
                user_trade_number: 19,
                weightedAvgLongPrice: 0.5316919354838711,
                weightedAvgShortPrice: 0.5299962500000001,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -31.21,
                floatingLongProfitEOD: 3.5,
                floatingProfit: -31.21,
                floatingProfitEOD: 3.5,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799685",
                longPositions: 0.05,
                netPositions: 0.05,
                shortPositions: 0,
                symbol: "NZDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.53675,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -449.9,
                floatingProfitEOD: -22.51,
                floatingShortProfit: -449.9,
                floatingShortProfitEOD: -22.51,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799453",
                longPositions: 0,
                netPositions: -0.35000000000000003,
                shortPositions: 0.35000000000000003,
                symbol: "NZDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.52006,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -19.08,
                floatingProfitEOD: -2.55,
                floatingShortProfit: -19.08,
                floatingShortProfitEOD: -2.55,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813752",
                longPositions: 0,
                netPositions: -0.04,
                shortPositions: 0.04,
                symbol: "NZDCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.52723,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2.3,
                floatingLongProfitEOD: 0.7,
                floatingProfit: -2.3,
                floatingProfitEOD: 0.7,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814962",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "NZDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.53324,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -29.15,
                floatingProfitEOD: -3.8,
                floatingShortProfit: -29.15,
                floatingShortProfitEOD: -3.8,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812026",
                longPositions: 0,
                netPositions: -0.06,
                shortPositions: 0.06,
                symbol: "NZDCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.5271450000000001,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -9.74,
                floatingProfitEOD: -1.26,
                floatingShortProfit: -9.74,
                floatingShortProfitEOD: -1.26,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812302",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "NZDCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.527135,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -29.25,
                floatingProfitEOD: -3.8,
                floatingShortProfit: -29.25,
                floatingShortProfitEOD: -3.8,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812304",
                longPositions: 0,
                netPositions: -0.06,
                shortPositions: 0.06,
                symbol: "NZDCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.52713,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -3.14,
                floatingLongProfitEOD: 1.44,
                floatingProfit: -3.14,
                floatingProfitEOD: 1.44,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812305",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "NZDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.53261,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -68.81,
                floatingProfitEOD: -8.92,
                floatingShortProfit: -68.81,
                floatingShortProfitEOD: -8.92,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810008",
                longPositions: 0,
                netPositions: -0.14,
                shortPositions: 0.14,
                symbol: "NZDCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.5270999999999999,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -4.61,
                floatingLongProfitEOD: 1.4,
                floatingProfit: -4.61,
                floatingProfitEOD: 1.4,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814682",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "NZDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.53324,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -9.73,
                floatingProfitEOD: -1.26,
                floatingShortProfit: -9.73,
                floatingShortProfitEOD: -1.26,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810247",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "NZDCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.5271399999999999,
            },
        ],
        CHFJPY: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -366.39,
                floatingProfitEOD: -44.87,
                floatingShortProfit: -366.39,
                floatingShortProfitEOD: -44.87,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799360",
                longPositions: 0,
                netPositions: -0.41000000000000003,
                shortPositions: 0.41000000000000003,
                symbol: "CHFJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 167.79400000000004,
            },
            {
                closedLongPositions: 0.01,
                closedLongProfit: 0.67,
                closedLongProfitEOD: 0.55,
                closedProfit: 0.67,
                closedProfitEOD: 0.55,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.21,
                floatingLongProfitEOD: -0.21,
                floatingProfit: -62.41,
                floatingProfitEOD: -26.09,
                floatingShortProfit: -62.2,
                floatingShortProfitEOD: -25.88,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799318",
                longPositions: 0.01,
                netPositions: -0.25,
                shortPositions: 0.26,
                symbol: "CHFJPY",
                user_trade_number: 14,
                weightedAvgLongPrice: 169.131,
                weightedAvgShortPrice: 168.77465384615388,
            },
            {
                closedLongPositions: 0.02,
                closedLongProfit: 1.33,
                closedLongProfitEOD: 2.3,
                closedProfit: 1.33,
                closedProfitEOD: 2.3,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.47,
                floatingLongProfitEOD: -0.47,
                floatingProfit: -62.04,
                floatingProfitEOD: -25.86,
                floatingShortProfit: -61.57,
                floatingShortProfitEOD: -25.39,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799317",
                longPositions: 0.01,
                netPositions: -0.25,
                shortPositions: 0.26,
                symbol: "CHFJPY",
                user_trade_number: 15,
                weightedAvgLongPrice: 169.16999999999996,
                weightedAvgShortPrice: 168.7781923076923,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -136.08,
                floatingProfitEOD: -2.19,
                floatingShortProfit: -136.08,
                floatingShortProfitEOD: -2.19,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799685",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "CHFJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 158.938,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 2298.98,
                floatingLongProfitEOD: 76.25,
                floatingProfit: 1633.66,
                floatingProfitEOD: 54.49,
                floatingShortProfit: -665.32,
                floatingShortProfitEOD: -21.76,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999994",
                longPositions: 0.7,
                netPositions: 0.49999999999999994,
                shortPositions: 0.2,
                symbol: "CHFJPY",
                user_trade_number: 9,
                weightedAvgLongPrice: 164.1757142857143,
                weightedAvgShortPrice: 164.1485,
            },
            {
                closedLongPositions: 0.01,
                closedLongProfit: -1.13,
                closedLongProfitEOD: -2.44,
                closedProfit: -1.13,
                closedProfitEOD: -2.44,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814712",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "CHFJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1292.86,
                floatingProfitEOD: -76.16,
                floatingShortProfit: -1292.86,
                floatingShortProfitEOD: -76.16,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808668",
                longPositions: 0,
                netPositions: -0.7,
                shortPositions: 0.7,
                symbol: "CHFJPY",
                user_trade_number: 4,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 166.36557142857146,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -7.49,
                floatingProfitEOD: -3.27,
                floatingShortProfit: -7.49,
                floatingShortProfitEOD: -3.27,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812045",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "CHFJPY",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 168.75900000000001,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -8.2,
                floatingProfitEOD: -4.35,
                floatingShortProfit: -8.2,
                floatingShortProfitEOD: -4.35,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812047",
                longPositions: 0,
                netPositions: -0.04,
                shortPositions: 0.04,
                symbol: "CHFJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 168.826,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -2.05,
                floatingProfitEOD: -1.09,
                floatingShortProfit: -2.05,
                floatingShortProfitEOD: -1.09,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808549",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "CHFJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 168.826,
            },
        ],
        AUDUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 2354.94,
                floatingLongProfitEOD: -26.46,
                floatingProfit: 2354.94,
                floatingProfitEOD: -26.46,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808905",
                longPositions: 1.26,
                netPositions: 1.26,
                shortPositions: 0,
                symbol: "AUDUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.63258,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -23174.56,
                floatingProfitEOD: 664.75,
                floatingShortProfit: -23174.56,
                floatingShortProfitEOD: 664.75,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807907",
                longPositions: 0,
                netPositions: -30.479999999999997,
                shortPositions: 30.479999999999997,
                symbol: "AUDUSD",
                user_trade_number: 32,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.6437087040682414,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -18549.14,
                floatingLongProfitEOD: -78.54,
                floatingProfit: -18549.14,
                floatingProfitEOD: -78.54,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8800001",
                longPositions: 3.1399999999999997,
                netPositions: 3.1399999999999997,
                shortPositions: 0,
                symbol: "AUDUSD",
                user_trade_number: 14,
                weightedAvgLongPrice: 0.7103035668789809,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -53851.15,
                floatingLongProfitEOD: -276.4,
                floatingProfit: -62943.4,
                floatingProfitEOD: -43.4,
                floatingShortProfit: -9092.25,
                floatingShortProfitEOD: 233,
                isInPercentageMode: false,
                login: "DEMO:MT4:8800002",
                longPositions: 12.649999999999995,
                netPositions: 2.3499999999999943,
                shortPositions: 10.3,
                symbol: "AUDUSD",
                user_trade_number: 83,
                weightedAvgLongPrice: 0.6938315810276683,
                weightedAvgShortPrice: 0.6424763592233009,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -0.15,
                floatingProfitEOD: 0.22,
                floatingShortProfit: -0.15,
                floatingShortProfitEOD: 0.22,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814544",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "AUDUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.65116,
            },
            {
                closedLongPositions: 0.02,
                closedLongProfit: 1.4,
                closedLongProfitEOD: -0.3,
                closedProfit: 1.4,
                closedProfitEOD: -0.3,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813797",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "AUDUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -4.26,
                floatingProfitEOD: 0.88,
                floatingShortProfit: -4.26,
                floatingShortProfitEOD: 0.88,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814646",
                longPositions: 0,
                netPositions: -0.04,
                shortPositions: 0.04,
                symbol: "AUDUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.650325,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -125.78,
                floatingProfitEOD: 5.32,
                floatingShortProfit: -125.78,
                floatingShortProfitEOD: 5.32,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809295",
                longPositions: 0,
                netPositions: -0.24000000000000005,
                shortPositions: 0.24000000000000005,
                symbol: "AUDUSD",
                user_trade_number: 12,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.6461374999999998,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 1.07,
                floatingLongProfitEOD: -0.42,
                floatingProfit: -47.75,
                floatingProfitEOD: 1.12,
                floatingShortProfit: -48.82,
                floatingShortProfitEOD: 1.54,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814482",
                longPositions: 0.02,
                netPositions: -0.05,
                shortPositions: 0.07,
                symbol: "AUDUSD",
                user_trade_number: 7,
                weightedAvgLongPrice: 0.650675,
                weightedAvgShortPrice: 0.6444057142857144,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -0.66,
                floatingProfitEOD: 0.26,
                floatingShortProfit: -0.66,
                floatingShortProfitEOD: 0.26,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812045",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "AUDUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.65069,
            },
            {
                closedLongPositions: 0.01,
                closedLongProfit: 0.88,
                closedLongProfitEOD: -0.06,
                closedProfit: 0.88,
                closedProfitEOD: -0.06,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -23.77,
                floatingProfitEOD: 0.21,
                floatingShortProfit: -23.77,
                floatingShortProfitEOD: 0.21,
                isInPercentageMode: false,
                login: "DEMO:MT4:8805626",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "AUDUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.62763,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -191.31,
                floatingProfitEOD: 4.94,
                floatingShortProfit: -191.31,
                floatingShortProfitEOD: 4.94,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814193",
                longPositions: 0,
                netPositions: -0.22999999999999995,
                shortPositions: 0.22999999999999995,
                symbol: "AUDUSD",
                user_trade_number: 9,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.643067391304348,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.57,
                floatingLongProfitEOD: -0.21,
                floatingProfit: 0.57,
                floatingProfitEOD: -0.21,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814807",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "AUDUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.65062,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -4.63,
                floatingProfitEOD: 0.22,
                floatingShortProfit: -4.63,
                floatingShortProfitEOD: 0.22,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809801",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "AUDUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.64676,
            },
            {
                closedLongPositions: 0.04,
                closedLongProfit: 2.8,
                closedLongProfitEOD: -0.6,
                closedProfit: 2.8,
                closedProfitEOD: -0.6,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814609",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "AUDUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
        ],
        AUDCHF: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -18.42,
                floatingProfitEOD: 0.23,
                floatingShortProfit: -18.42,
                floatingShortProfitEOD: 0.23,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812047",
                longPositions: 0,
                netPositions: -0.04,
                shortPositions: 0.04,
                symbol: "AUDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.57312,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -87.24,
                floatingLongProfitEOD: 0.16,
                floatingProfit: -87.24,
                floatingProfitEOD: 0.16,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809483",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "AUDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.61554,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2.57,
                floatingLongProfitEOD: 0.08,
                floatingProfit: -2.57,
                floatingProfitEOD: 0.08,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808263",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "AUDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.57918,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -570.41,
                floatingLongProfitEOD: 2.22,
                floatingProfit: -603.33,
                floatingProfitEOD: 2.34,
                floatingShortProfit: -32.92,
                floatingShortProfitEOD: 0.12,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807494",
                longPositions: 0.28,
                netPositions: 0.26,
                shortPositions: 0.02,
                symbol: "AUDCHF",
                user_trade_number: 6,
                weightedAvgLongPrice: 0.5949464285714284,
                weightedAvgShortPrice: 0.562615,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -46.27,
                floatingProfitEOD: 0.56,
                floatingShortProfit: -46.27,
                floatingShortProfitEOD: 0.56,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808198",
                longPositions: 0,
                netPositions: -0.1,
                shortPositions: 0.1,
                symbol: "AUDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.5731,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -17.49,
                floatingLongProfitEOD: 0.4,
                floatingProfit: -17.49,
                floatingProfitEOD: 0.4,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810680",
                longPositions: 0.05,
                netPositions: 0.05,
                shortPositions: 0,
                symbol: "AUDCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0.58,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2.18,
                floatingLongProfitEOD: 0.08,
                floatingProfit: -2.18,
                floatingProfitEOD: 0.08,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809760",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "AUDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.57883,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1046.87,
                floatingLongProfitEOD: 1.9,
                floatingProfit: -1046.87,
                floatingProfitEOD: 1.9,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808280",
                longPositions: 0.24,
                netPositions: 0.24,
                shortPositions: 0,
                symbol: "AUDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.61554,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -4.4,
                floatingLongProfitEOD: 0.79,
                floatingProfit: -4.4,
                floatingProfitEOD: 0.79,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812920",
                longPositions: 0.1,
                netPositions: 0.1,
                shortPositions: 0,
                symbol: "AUDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.57729,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -4.81,
                floatingProfitEOD: 0.17,
                floatingShortProfit: -4.81,
                floatingShortProfitEOD: 0.17,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811348",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "AUDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.57578,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -15.44,
                floatingLongProfitEOD: 0.48,
                floatingProfit: -15.44,
                floatingProfitEOD: 0.48,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811722",
                longPositions: 0.06,
                netPositions: 0.06,
                shortPositions: 0,
                symbol: "AUDCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0.57918,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -25.86,
                floatingLongProfitEOD: 0.64,
                floatingProfit: -25.86,
                floatingProfitEOD: 0.64,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810850",
                longPositions: 0.08,
                netPositions: 0.08,
                shortPositions: 0,
                symbol: "AUDCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0.57976375,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -4.6,
                floatingProfitEOD: 0.06,
                floatingShortProfit: -4.6,
                floatingShortProfitEOD: 0.06,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808549",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "AUDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.57312,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -11.06,
                floatingLongProfitEOD: 0.4,
                floatingProfit: -11.06,
                floatingProfitEOD: 0.4,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807669",
                longPositions: 0.05,
                netPositions: 0.05,
                shortPositions: 0,
                symbol: "AUDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.57886,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -16.19,
                floatingLongProfitEOD: 0.24,
                floatingProfit: -16.19,
                floatingProfitEOD: 0.24,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810417",
                longPositions: 0.03,
                netPositions: 0.03,
                shortPositions: 0,
                symbol: "AUDCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0.58168,
                weightedAvgShortPrice: 0,
            },
        ],
        EURCHF: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2.62,
                floatingLongProfitEOD: -0.29,
                floatingProfit: -2.62,
                floatingProfitEOD: -0.29,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814325",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "EURCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.96743,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -186.37,
                floatingProfitEOD: 11.55,
                floatingShortProfit: -186.37,
                floatingShortProfitEOD: 11.55,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813874",
                longPositions: 0,
                netPositions: -0.42000000000000004,
                shortPositions: 0.42000000000000004,
                symbol: "EURCHF",
                user_trade_number: 4,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.9625742857142856,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1062.58,
                floatingProfitEOD: 24.64,
                floatingShortProfit: -1062.58,
                floatingShortProfitEOD: 24.64,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810483",
                longPositions: 0,
                netPositions: -0.91,
                shortPositions: 0.91,
                symbol: "EURCHF",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.9561635164835165,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1.13,
                floatingLongProfitEOD: -0.17,
                floatingProfit: -1.13,
                floatingProfitEOD: -0.17,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813995",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "EURCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.96725,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -352.55,
                floatingProfitEOD: 8.12,
                floatingShortProfit: -352.55,
                floatingShortProfitEOD: 8.12,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810482",
                longPositions: 0,
                netPositions: -0.3,
                shortPositions: 0.3,
                symbol: "EURCHF",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.9560970000000002,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1.31,
                floatingLongProfitEOD: -0.15,
                floatingProfit: -1.31,
                floatingProfitEOD: -0.15,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812303",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "EURCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.96743,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -48.4,
                floatingProfitEOD: 0.81,
                floatingShortProfit: -48.4,
                floatingShortProfitEOD: 0.81,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811293",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "EURCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.9522133333333335,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1.19,
                floatingLongProfitEOD: -0.17,
                floatingProfit: -1.19,
                floatingProfitEOD: -0.17,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811050",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "EURCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.9673,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -65.18,
                floatingProfitEOD: 1.64,
                floatingShortProfit: -65.18,
                floatingShortProfitEOD: 1.64,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810800",
                longPositions: 0,
                netPositions: -0.06,
                shortPositions: 0.06,
                symbol: "EURCHF",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.9568800000000001,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1.59,
                floatingLongProfitEOD: -0.17,
                floatingProfit: -1.59,
                floatingProfitEOD: -0.17,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813471",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "EURCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.9676600000000001,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1.31,
                floatingLongProfitEOD: -0.15,
                floatingProfit: -1.31,
                floatingProfitEOD: -0.15,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812382",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "EURCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.96743,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -27.15,
                floatingProfitEOD: 1.93,
                floatingShortProfit: -27.15,
                floatingShortProfitEOD: 1.93,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814164",
                longPositions: 0,
                netPositions: -0.07,
                shortPositions: 0.07,
                symbol: "EURCHF",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.9630685714285713,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1.59,
                floatingLongProfitEOD: -0.17,
                floatingProfit: -1.59,
                floatingProfitEOD: -0.17,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813474",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "EURCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.9676600000000001,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -27.15,
                floatingProfitEOD: 1.93,
                floatingShortProfit: -27.15,
                floatingShortProfitEOD: 1.93,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814323",
                longPositions: 0,
                netPositions: -0.07,
                shortPositions: 0.07,
                symbol: "EURCHF",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.9630685714285713,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1.59,
                floatingLongProfitEOD: -0.17,
                floatingProfit: -1.59,
                floatingProfitEOD: -0.17,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812386",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "EURCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.9676600000000001,
                weightedAvgShortPrice: 0,
            },
        ],
        DOGUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 13.4,
                floatingLongProfitEOD: 2.13,
                floatingProfit: 13.4,
                floatingProfitEOD: 2.13,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807629",
                longPositions: 30.750000000000004,
                netPositions: 30.750000000000004,
                shortPositions: 0,
                symbol: "DOGUSD",
                user_trade_number: 15,
                weightedAvgLongPrice: 0.07592469268292683,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812792",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "DOGUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.08476,
            },
        ],
        EURGBP: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -8.47,
                floatingProfitEOD: -0.06,
                floatingShortProfit: -8.47,
                floatingShortProfitEOD: -0.06,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812554",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "EURGBP",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.872405,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -4.46,
                floatingProfitEOD: -0.02,
                floatingShortProfit: -4.46,
                floatingShortProfitEOD: -0.02,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809024",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "EURGBP",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8722199999999999,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -34.93,
                floatingProfitEOD: -0.2,
                floatingShortProfit: -34.93,
                floatingShortProfitEOD: -0.2,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812325",
                longPositions: 0,
                netPositions: -0.08,
                shortPositions: 0.08,
                symbol: "EURGBP",
                user_trade_number: 7,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8722975000000001,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.47,
                floatingLongProfitEOD: 0.09,
                floatingProfit: -11.37,
                floatingProfitEOD: 0.05,
                floatingShortProfit: -10.9,
                floatingShortProfitEOD: -0.04,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809496",
                longPositions: 0.01,
                netPositions: -0.01,
                shortPositions: 0.02,
                symbol: "EURGBP",
                user_trade_number: 3,
                weightedAvgLongPrice: 0.87596,
                weightedAvgShortPrice: 0.871425,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -33.48,
                floatingProfitEOD: -0.12,
                floatingShortProfit: -33.48,
                floatingShortProfitEOD: -0.12,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808353",
                longPositions: 0,
                netPositions: -0.060000000000000005,
                shortPositions: 0.060000000000000005,
                symbol: "EURGBP",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8713200000000001,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.42,
                floatingLongProfitEOD: 0.2,
                floatingProfit: 0.42,
                floatingProfitEOD: 0.2,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813636",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "EURGBP",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.8754,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -21.7,
                floatingProfitEOD: -0.08,
                floatingShortProfit: -21.7,
                floatingShortProfitEOD: -0.08,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808172",
                longPositions: 0,
                netPositions: -0.04,
                shortPositions: 0.04,
                symbol: "EURGBP",
                user_trade_number: 4,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.871445,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -53.45,
                floatingProfitEOD: -0.32,
                floatingShortProfit: -53.45,
                floatingShortProfitEOD: -0.32,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809295",
                longPositions: 0,
                netPositions: -0.13,
                shortPositions: 0.13,
                symbol: "EURGBP",
                user_trade_number: 9,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8725007692307692,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.45,
                floatingLongProfitEOD: 0.2,
                floatingProfit: 0.45,
                floatingProfitEOD: 0.2,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802848",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "EURGBP",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.87539,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 4.6,
                floatingLongProfitEOD: 0.26,
                floatingProfit: 4.6,
                floatingProfitEOD: 0.26,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813241",
                longPositions: 0.03,
                netPositions: 0.03,
                shortPositions: 0,
                symbol: "EURGBP",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.87435,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -2.4,
                floatingProfitEOD: -0.12,
                floatingShortProfit: -2.4,
                floatingShortProfitEOD: -0.12,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812045",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "EURGBP",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8751800000000001,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -34.61,
                floatingProfitEOD: -0.22,
                floatingShortProfit: -34.61,
                floatingShortProfitEOD: -0.22,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807946",
                longPositions: 0,
                netPositions: -0.08,
                shortPositions: 0.08,
                symbol: "EURGBP",
                user_trade_number: 7,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8723299999999999,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -4.67,
                floatingProfitEOD: -0.04,
                floatingShortProfit: -4.67,
                floatingShortProfitEOD: -0.04,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808721",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "EURGBP",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8739250000000001,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.31,
                floatingLongProfitEOD: 0.09,
                floatingProfit: -24.11,
                floatingProfitEOD: -0.09,
                floatingShortProfit: -23.8,
                floatingShortProfitEOD: -0.18,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808127",
                longPositions: 0.01,
                netPositions: -0.049999999999999996,
                shortPositions: 0.06,
                symbol: "EURGBP",
                user_trade_number: 6,
                weightedAvgLongPrice: 0.87583,
                weightedAvgShortPrice: 0.8726216666666666,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: -18.69,
                closedProfitEOD: -7.79,
                closedShortPositions: 0.05,
                closedShortProfit: -18.69,
                closedShortProfitEOD: -7.79,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1295.53,
                floatingProfitEOD: -3.29,
                floatingShortProfit: -1295.53,
                floatingShortProfitEOD: -3.29,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802384",
                longPositions: 0,
                netPositions: -1.23,
                shortPositions: 1.23,
                symbol: "EURGBP",
                user_trade_number: 5,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8673563414634148,
            },
        ],
        EURNZD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1.38,
                floatingProfitEOD: 2.8,
                floatingShortProfit: -1.38,
                floatingShortProfitEOD: 2.8,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812554",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "EURNZD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.8178400000000001,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -5.02,
                floatingProfitEOD: 4.22,
                floatingShortProfit: -5.02,
                floatingShortProfitEOD: 4.22,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814635",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "EURNZD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.8161933333333333,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -30.36,
                floatingLongProfitEOD: -28.96,
                floatingProfit: -122.49,
                floatingProfitEOD: 0.66,
                floatingShortProfit: -92.13,
                floatingShortProfitEOD: 29.62,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812526",
                longPositions: 0.21000000000000002,
                netPositions: 2.7755575615628914e-17,
                shortPositions: 0.21,
                symbol: "EURNZD",
                user_trade_number: 8,
                weightedAvgLongPrice: 1.8210014285714287,
                weightedAvgShortPrice: 1.8116642857142857,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0.96,
                closedProfitEOD: 2.17,
                closedShortPositions: 0.02,
                closedShortProfit: 0.96,
                closedShortProfitEOD: 2.17,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -93.15,
                floatingProfitEOD: 18.12,
                floatingShortProfit: -93.15,
                floatingShortProfitEOD: 18.12,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814193",
                longPositions: 0,
                netPositions: -0.13000000000000003,
                shortPositions: 0.13000000000000003,
                symbol: "EURNZD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.807060769230769,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2.06,
                floatingLongProfitEOD: -1.38,
                floatingProfit: -40.65,
                floatingProfitEOD: 11.15,
                floatingShortProfit: -38.59,
                floatingShortProfitEOD: 12.53,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814482",
                longPositions: 0.01,
                netPositions: -0.08,
                shortPositions: 0.09,
                symbol: "EURNZD",
                user_trade_number: 7,
                weightedAvgLongPrice: 1.8220199999999998,
                weightedAvgShortPrice: 1.8118600000000005,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -117.07,
                floatingProfitEOD: 1.34,
                floatingShortProfit: -117.07,
                floatingShortProfitEOD: 1.34,
                isInPercentageMode: false,
                login: "DEMO:MT4:8803780",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "EURNZD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.6239400000000002,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2.09,
                floatingLongProfitEOD: -1.38,
                floatingProfit: -38.9,
                floatingProfitEOD: 11.14,
                floatingShortProfit: -36.81,
                floatingShortProfitEOD: 12.52,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814055",
                longPositions: 0.01,
                netPositions: -0.08,
                shortPositions: 0.09,
                symbol: "EURNZD",
                user_trade_number: 7,
                weightedAvgLongPrice: 1.8220700000000003,
                weightedAvgShortPrice: 1.8121944444444447,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -674.63,
                floatingProfitEOD: 40.57,
                floatingShortProfit: -674.63,
                floatingShortProfitEOD: 40.57,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812570",
                longPositions: 0,
                netPositions: -0.29000000000000004,
                shortPositions: 0.29000000000000004,
                symbol: "EURNZD",
                user_trade_number: 12,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.7802127586206895,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -0.48,
                floatingProfitEOD: 0.18,
                floatingShortProfit: -0.48,
                floatingShortProfitEOD: 0.18,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814845",
                longPositions: 0,
                netPositions: -0.14,
                shortPositions: 0.14,
                symbol: "EURNZD",
                user_trade_number: 10,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.8131928571428568,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -14.07,
                floatingProfitEOD: 16.87,
                floatingShortProfit: -14.07,
                floatingShortProfitEOD: 16.87,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799362",
                longPositions: 0,
                netPositions: -0.12,
                shortPositions: 0.12,
                symbol: "EURNZD",
                user_trade_number: 9,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.8170308333333334,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -9.45,
                floatingLongProfitEOD: -7.11,
                floatingProfit: -10.54,
                floatingProfitEOD: 36.39,
                floatingShortProfit: -1.09,
                floatingShortProfitEOD: 43.5,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799317",
                longPositions: 0.08,
                netPositions: -0.23000000000000004,
                shortPositions: 0.31000000000000005,
                symbol: "EURNZD",
                user_trade_number: 19,
                weightedAvgLongPrice: 1.8205612500000001,
                weightedAvgShortPrice: 1.8189322580645158,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2.55,
                floatingLongProfitEOD: -1.38,
                floatingProfit: -40.54,
                floatingProfitEOD: 11.15,
                floatingShortProfit: -37.99,
                floatingShortProfitEOD: 12.53,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799426",
                longPositions: 0.01,
                netPositions: -0.08,
                shortPositions: 0.09,
                symbol: "EURNZD",
                user_trade_number: 7,
                weightedAvgLongPrice: 1.82285,
                weightedAvgShortPrice: 1.811971111111111,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 1900.57,
                floatingLongProfitEOD: -110.63,
                floatingProfit: 1900.57,
                floatingProfitEOD: -110.63,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999999",
                longPositions: 0.8200000000000001,
                netPositions: 0.8200000000000001,
                shortPositions: 0,
                symbol: "EURNZD",
                user_trade_number: 3,
                weightedAvgLongPrice: 1.7799660975609755,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2.04,
                floatingLongProfitEOD: -1.38,
                floatingProfit: -32.8,
                floatingProfitEOD: 8.38,
                floatingShortProfit: -30.76,
                floatingShortProfitEOD: 9.76,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799446",
                longPositions: 0.01,
                netPositions: -0.060000000000000005,
                shortPositions: 0.07,
                symbol: "EURNZD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1.822,
                weightedAvgShortPrice: 1.8116814285714284,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -62.52,
                floatingProfitEOD: 23.83,
                floatingShortProfit: -62.52,
                floatingShortProfitEOD: 23.83,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799452",
                longPositions: 0,
                netPositions: -0.17000000000000004,
                shortPositions: 0.17000000000000004,
                symbol: "EURNZD",
                user_trade_number: 13,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.812861764705882,
            },
        ],
        AUDCAD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -18.89,
                floatingProfitEOD: 0.64,
                floatingShortProfit: -18.89,
                floatingShortProfitEOD: 0.64,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813499",
                longPositions: 0,
                netPositions: -0.07999999999999999,
                shortPositions: 0.07999999999999999,
                symbol: "AUDCAD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8903775,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0.07,
                floatingProfitEOD: 0.04,
                floatingShortProfit: 0.07,
                floatingShortProfitEOD: 0.04,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814544",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "AUDCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8937,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -25.85,
                floatingProfitEOD: 0.73,
                floatingShortProfit: -25.85,
                floatingShortProfitEOD: 0.73,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809295",
                longPositions: 0,
                netPositions: -0.12,
                shortPositions: 0.12,
                symbol: "AUDCAD",
                user_trade_number: 9,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8906883333333333,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -73.39,
                floatingProfitEOD: 0.91,
                floatingShortProfit: -73.39,
                floatingShortProfitEOD: 0.91,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814193",
                longPositions: 0,
                netPositions: -0.15,
                shortPositions: 0.15,
                symbol: "AUDCAD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8869346666666666,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -25.75,
                floatingProfitEOD: 0.66,
                floatingShortProfit: -25.75,
                floatingShortProfitEOD: 0.66,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808556",
                longPositions: 0,
                netPositions: -0.12,
                shortPositions: 0.12,
                symbol: "AUDCAD",
                user_trade_number: 9,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8907083333333334,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -34.7,
                floatingProfitEOD: 0.4,
                floatingShortProfit: -34.7,
                floatingShortProfitEOD: 0.4,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812408",
                longPositions: 0,
                netPositions: -0.08,
                shortPositions: 0.08,
                symbol: "AUDCAD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8876400000000001,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -61.21,
                floatingProfitEOD: 1.47,
                floatingShortProfit: -61.21,
                floatingShortProfitEOD: 1.47,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808037",
                longPositions: 0,
                netPositions: -0.27,
                shortPositions: 0.27,
                symbol: "AUDCAD",
                user_trade_number: 9,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8905448148148147,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -25.63,
                floatingProfitEOD: 0.73,
                floatingShortProfit: -25.63,
                floatingShortProfitEOD: 0.73,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808532",
                longPositions: 0,
                netPositions: -0.12,
                shortPositions: 0.12,
                symbol: "AUDCAD",
                user_trade_number: 9,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8907141666666665,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -87.3,
                floatingProfitEOD: 1.36,
                floatingShortProfit: -87.3,
                floatingShortProfitEOD: 1.36,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813983",
                longPositions: 0,
                netPositions: -0.30000000000000004,
                shortPositions: 0.30000000000000004,
                symbol: "AUDCAD",
                user_trade_number: 10,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8896749999999998,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -5.22,
                floatingProfitEOD: 0.08,
                floatingShortProfit: -5.22,
                floatingShortProfitEOD: 0.08,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812554",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "AUDCAD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8900950000000001,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -29.45,
                floatingProfitEOD: 0.62,
                floatingShortProfit: -29.45,
                floatingShortProfitEOD: 0.62,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812533",
                longPositions: 0,
                netPositions: -0.08,
                shortPositions: 0.08,
                symbol: "AUDCAD",
                user_trade_number: 7,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8885700000000002,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -45.33,
                floatingProfitEOD: 0.48,
                floatingShortProfit: -45.33,
                floatingShortProfitEOD: 0.48,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814635",
                longPositions: 0,
                netPositions: -0.09999999999999998,
                shortPositions: 0.09999999999999998,
                symbol: "AUDCAD",
                user_trade_number: 8,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8874440000000002,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -25.65,
                floatingProfitEOD: 0.73,
                floatingShortProfit: -25.65,
                floatingShortProfitEOD: 0.73,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808353",
                longPositions: 0,
                netPositions: -0.12,
                shortPositions: 0.12,
                symbol: "AUDCAD",
                user_trade_number: 9,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8907108333333335,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -13.06,
                floatingProfitEOD: 0.32,
                floatingShortProfit: -13.06,
                floatingShortProfitEOD: 0.32,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812810",
                longPositions: 0,
                netPositions: -0.05,
                shortPositions: 0.05,
                symbol: "AUDCAD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.89006,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -4.46,
                floatingProfitEOD: 0.11,
                floatingShortProfit: -4.46,
                floatingShortProfitEOD: 0.11,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809061",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "AUDCAD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.890525,
            },
        ],
        CADCHF: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -24.33,
                floatingLongProfitEOD: 0.16,
                floatingProfit: -24.33,
                floatingProfitEOD: 0.16,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8806329",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "CADCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0.656415,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -760.26,
                floatingLongProfitEOD: 0.76,
                floatingProfit: -760.26,
                floatingProfitEOD: 0.76,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8803208",
                longPositions: 0.09000000000000001,
                netPositions: 0.09000000000000001,
                shortPositions: 0,
                symbol: "CADCHF",
                user_trade_number: 4,
                weightedAvgLongPrice: 0.7205544444444444,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0.1,
                closedLongProfit: -65.36,
                closedLongProfitEOD: -30.99,
                closedProfit: -65.36,
                closedProfitEOD: -30.99,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808215",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "CADCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -77.18,
                floatingLongProfitEOD: 0.08,
                floatingProfit: -77.18,
                floatingProfitEOD: 0.08,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8803780",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "CADCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.71401,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -684.77,
                floatingLongProfitEOD: 0.77,
                floatingProfit: -684.77,
                floatingProfitEOD: 0.77,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8803004",
                longPositions: 0.08,
                netPositions: 0.08,
                shortPositions: 0,
                symbol: "CADCHF",
                user_trade_number: 4,
                weightedAvgLongPrice: 0.7215600000000001,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -684.87,
                floatingLongProfitEOD: 0.68,
                floatingProfit: -684.87,
                floatingProfitEOD: 0.68,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8803013",
                longPositions: 0.08,
                netPositions: 0.08,
                shortPositions: 0,
                symbol: "CADCHF",
                user_trade_number: 4,
                weightedAvgLongPrice: 0.7215612499999999,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -30.52,
                floatingLongProfitEOD: 0.16,
                floatingProfit: -30.52,
                floatingProfitEOD: 0.16,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799363",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "CADCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0.65918,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -485.97,
                floatingLongProfitEOD: 3.95,
                floatingProfit: -485.97,
                floatingProfitEOD: 3.95,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799351",
                longPositions: 0.5,
                netPositions: 0.5,
                shortPositions: 0,
                symbol: "CADCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.65427,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -115.08,
                floatingLongProfitEOD: 1.58,
                floatingProfit: -115.08,
                floatingProfitEOD: 1.58,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799318",
                longPositions: 0.18,
                netPositions: 0.18,
                shortPositions: 0,
                symbol: "CADCHF",
                user_trade_number: 10,
                weightedAvgLongPrice: 0.6513311111111111,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -126.88,
                floatingLongProfitEOD: 1.9,
                floatingProfit: -126.88,
                floatingProfitEOD: 1.9,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799317",
                longPositions: 0.21999999999999997,
                netPositions: 0.21999999999999997,
                shortPositions: 0,
                symbol: "CADCHF",
                user_trade_number: 11,
                weightedAvgLongPrice: 0.6507754545454545,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -4030.02,
                floatingLongProfitEOD: 47.42,
                floatingProfit: -4030.02,
                floatingProfitEOD: 47.42,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799711",
                longPositions: 6,
                netPositions: 6,
                shortPositions: 0,
                symbol: "CADCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0.65159,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -218.99,
                floatingLongProfitEOD: 3.95,
                floatingProfit: -218.99,
                floatingProfitEOD: 3.95,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799831",
                longPositions: 0.5,
                netPositions: 0.5,
                shortPositions: 0,
                symbol: "CADCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.64952,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.73,
                floatingLongProfitEOD: 0.08,
                floatingProfit: -0.73,
                floatingProfitEOD: 0.08,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808263",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "CADCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.64629,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -0.58,
                floatingProfitEOD: -0.06,
                floatingShortProfit: -0.58,
                floatingShortProfitEOD: -0.06,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810043",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "CADCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.64544,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -338.36,
                floatingLongProfitEOD: 1.26,
                floatingProfit: -338.36,
                floatingProfitEOD: 1.26,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807494",
                longPositions: 0.16,
                netPositions: 0.16,
                shortPositions: 0,
                symbol: "CADCHF",
                user_trade_number: 3,
                weightedAvgLongPrice: 0.6643737499999998,
                weightedAvgShortPrice: 0,
            },
        ],
        USDCAD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -6.42,
                floatingLongProfitEOD: 0.72,
                floatingProfit: -7,
                floatingProfitEOD: 0.57,
                floatingShortProfit: -0.58,
                floatingShortProfitEOD: -0.15,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814732",
                longPositions: 0.03,
                netPositions: 0.019999999999999997,
                shortPositions: 0.01,
                symbol: "USDCAD",
                user_trade_number: 4,
                weightedAvgLongPrice: 1.3747633333333336,
                weightedAvgShortPrice: 1.37122,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 1.84,
                floatingProfitEOD: -0.91,
                floatingShortProfit: 1.84,
                floatingShortProfitEOD: -0.91,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812533",
                longPositions: 0,
                netPositions: -0.06000000000000001,
                shortPositions: 0.06000000000000001,
                symbol: "USDCAD",
                user_trade_number: 5,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.3724416666666666,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -5.03,
                floatingProfitEOD: -0.45,
                floatingShortProfit: -5.03,
                floatingShortProfitEOD: -0.45,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809024",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "USDCAD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.369716666666667,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -10.23,
                floatingLongProfitEOD: 1.44,
                floatingProfit: -10.84,
                floatingProfitEOD: 1.29,
                floatingShortProfit: -0.61,
                floatingShortProfitEOD: -0.15,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812325",
                longPositions: 0.06000000000000001,
                netPositions: 0.05000000000000001,
                shortPositions: 0.01,
                symbol: "USDCAD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1.3741716666666666,
                weightedAvgShortPrice: 1.37118,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -5.9,
                floatingLongProfitEOD: 0.72,
                floatingProfit: -6.5,
                floatingProfitEOD: 0.57,
                floatingShortProfit: -0.6,
                floatingShortProfitEOD: -0.15,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809496",
                longPositions: 0.03,
                netPositions: 0.019999999999999997,
                shortPositions: 0.01,
                symbol: "USDCAD",
                user_trade_number: 4,
                weightedAvgLongPrice: 1.374526666666667,
                weightedAvgShortPrice: 1.3712,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -10.21,
                floatingLongProfitEOD: 1.44,
                floatingProfit: -10.79,
                floatingProfitEOD: 1.29,
                floatingShortProfit: -0.58,
                floatingShortProfitEOD: -0.15,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809295",
                longPositions: 0.06000000000000001,
                netPositions: 0.05000000000000001,
                shortPositions: 0.01,
                symbol: "USDCAD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1.3741649999999999,
                weightedAvgShortPrice: 1.37122,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -10.08,
                floatingLongProfitEOD: 1.44,
                floatingProfit: -10.65,
                floatingProfitEOD: 1.29,
                floatingShortProfit: -0.57,
                floatingShortProfitEOD: -0.15,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808172",
                longPositions: 0.06000000000000001,
                netPositions: 0.05000000000000001,
                shortPositions: 0.01,
                symbol: "USDCAD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1.3741349999999997,
                weightedAvgShortPrice: 1.37124,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -4.43,
                floatingLongProfitEOD: 0.48,
                floatingProfit: -9.37,
                floatingProfitEOD: 0.03,
                floatingShortProfit: -4.94,
                floatingShortProfitEOD: -0.45,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814482",
                longPositions: 0.02,
                netPositions: -0.009999999999999998,
                shortPositions: 0.03,
                symbol: "USDCAD",
                user_trade_number: 5,
                weightedAvgLongPrice: 1.3748699999999998,
                weightedAvgShortPrice: 1.3697633333333334,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -4.72,
                floatingLongProfitEOD: 0.48,
                floatingProfit: -4.57,
                floatingProfitEOD: 0.33,
                floatingShortProfit: 0.15,
                floatingShortProfitEOD: -0.15,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814055",
                longPositions: 0.02,
                netPositions: 0.01,
                shortPositions: 0.01,
                symbol: "USDCAD",
                user_trade_number: 3,
                weightedAvgLongPrice: 1.375065,
                weightedAvgShortPrice: 1.37223,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -462.89,
                floatingProfitEOD: -4.6,
                floatingShortProfit: -462.89,
                floatingShortProfitEOD: -4.6,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807818",
                longPositions: 0,
                netPositions: -0.3,
                shortPositions: 0.3,
                symbol: "USDCAD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.3508499999999999,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -10.18,
                floatingLongProfitEOD: 1.44,
                floatingProfit: -10.78,
                floatingProfitEOD: 1.29,
                floatingShortProfit: -0.6,
                floatingShortProfitEOD: -0.15,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807946",
                longPositions: 0.06000000000000001,
                netPositions: 0.05000000000000001,
                shortPositions: 0.01,
                symbol: "USDCAD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1.3741583333333331,
                weightedAvgShortPrice: 1.37119,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -10.13,
                floatingLongProfitEOD: 1.44,
                floatingProfit: -10.7,
                floatingProfitEOD: 1.29,
                floatingShortProfit: -0.57,
                floatingShortProfitEOD: -0.15,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808127",
                longPositions: 0.06000000000000001,
                netPositions: 0.05000000000000001,
                shortPositions: 0.01,
                symbol: "USDCAD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1.3741449999999997,
                weightedAvgShortPrice: 1.37124,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -10.1,
                floatingLongProfitEOD: 1.44,
                floatingProfit: -10.7,
                floatingProfitEOD: 1.29,
                floatingShortProfit: -0.6,
                floatingShortProfitEOD: -0.15,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808556",
                longPositions: 0.06000000000000001,
                netPositions: 0.05000000000000001,
                shortPositions: 0.01,
                symbol: "USDCAD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1.374138333333333,
                weightedAvgShortPrice: 1.3712,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -10.13,
                floatingLongProfitEOD: 1.44,
                floatingProfit: -10.7,
                floatingProfitEOD: 1.29,
                floatingShortProfit: -0.57,
                floatingShortProfitEOD: -0.15,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812914",
                longPositions: 0.06000000000000001,
                netPositions: 0.05000000000000001,
                shortPositions: 0.01,
                symbol: "USDCAD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1.3741466666666666,
                weightedAvgShortPrice: 1.37124,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -10.13,
                floatingLongProfitEOD: 1.44,
                floatingProfit: -10.7,
                floatingProfitEOD: 1.29,
                floatingShortProfit: -0.57,
                floatingShortProfitEOD: -0.15,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807991",
                longPositions: 0.06000000000000001,
                netPositions: 0.05000000000000001,
                shortPositions: 0.01,
                symbol: "USDCAD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1.374145,
                weightedAvgShortPrice: 1.37124,
            },
        ],
        GBPUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -77.88,
                floatingProfitEOD: 6.4,
                floatingShortProfit: -77.88,
                floatingShortProfitEOD: 6.4,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813499",
                longPositions: 0,
                netPositions: -0.09999999999999999,
                shortPositions: 0.09999999999999999,
                symbol: "GBPUSD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.2379820000000001,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -65.43,
                floatingProfitEOD: 1.92,
                floatingShortProfit: -65.43,
                floatingShortProfitEOD: 1.92,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807393",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "GBPUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.22397,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 278.4,
                floatingLongProfitEOD: -73.2,
                floatingProfit: -2702.72,
                floatingProfitEOD: 62.23,
                floatingShortProfit: -2981.12,
                floatingShortProfitEOD: 135.43,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808384",
                longPositions: 1.2,
                netPositions: -0.9100000000000008,
                shortPositions: 2.1100000000000008,
                symbol: "GBPUSD",
                user_trade_number: 25,
                weightedAvgLongPrice: 1.24323,
                weightedAvgShortPrice: 1.23164962085308,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -0.06,
                floatingProfitEOD: 0.64,
                floatingShortProfit: -0.06,
                floatingShortProfitEOD: 0.64,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814544",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "GBPUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.24564,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -3.08,
                floatingLongProfitEOD: -0.61,
                floatingProfit: -3.08,
                floatingProfitEOD: -0.61,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814027",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "GBPUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.24865,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 1.5,
                floatingLongProfitEOD: -91.5,
                floatingProfit: -1638.02,
                floatingProfitEOD: -18.22,
                floatingShortProfit: -1639.52,
                floatingShortProfitEOD: 73.28,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808385",
                longPositions: 1.5,
                netPositions: 0.3599999999999999,
                shortPositions: 1.1400000000000001,
                symbol: "GBPUSD",
                user_trade_number: 23,
                weightedAvgLongPrice: 1.24554,
                weightedAvgShortPrice: 1.2313954385964914,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -4.29,
                floatingProfitEOD: 0.64,
                floatingShortProfit: -4.29,
                floatingShortProfitEOD: 0.64,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814888",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "GBPUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.24148,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2.08,
                floatingLongProfitEOD: -2.44,
                floatingProfit: -2.08,
                floatingProfitEOD: -2.44,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814569",
                longPositions: 0.04,
                netPositions: 0.04,
                shortPositions: 0,
                symbol: "GBPUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 1.24609,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -4250,
                floatingProfitEOD: 640,
                floatingShortProfit: -4250,
                floatingShortProfitEOD: 640,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814865",
                longPositions: 0,
                netPositions: -10,
                shortPositions: 10,
                symbol: "GBPUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.24145,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -2.8,
                floatingProfitEOD: 0.64,
                floatingShortProfit: -2.8,
                floatingShortProfitEOD: 0.64,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807194",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "GBPUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.2429,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1.5,
                floatingLongProfitEOD: -1.83,
                floatingProfit: -108.76,
                floatingProfitEOD: 5.28,
                floatingShortProfit: -107.26,
                floatingShortProfitEOD: 7.11,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814482",
                longPositions: 0.03,
                netPositions: -0.08,
                shortPositions: 0.11,
                symbol: "GBPUSD",
                user_trade_number: 10,
                weightedAvgLongPrice: 1.24607,
                weightedAvgShortPrice: 1.2360127272727273,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -31.35,
                floatingProfitEOD: 3.84,
                floatingShortProfit: -31.35,
                floatingShortProfitEOD: 3.84,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810200",
                longPositions: 0,
                netPositions: -0.060000000000000005,
                shortPositions: 0.060000000000000005,
                symbol: "GBPUSD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.240545,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -3.96,
                floatingProfitEOD: 1.92,
                floatingShortProfit: -3.96,
                floatingShortProfitEOD: 1.92,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812045",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "GBPUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.2444600000000001,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1391.88,
                floatingLongProfitEOD: -12.69,
                floatingProfit: -2913.68,
                floatingProfitEOD: 15.56,
                floatingShortProfit: -1521.8,
                floatingShortProfitEOD: 28.25,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802655",
                longPositions: 0.21000000000000005,
                netPositions: -0.2500000000000002,
                shortPositions: 0.46000000000000024,
                symbol: "GBPUSD",
                user_trade_number: 67,
                weightedAvgLongPrice: 1.311835714285714,
                weightedAvgShortPrice: 1.2126963043478254,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -7.71,
                floatingProfitEOD: 0.64,
                floatingShortProfit: -7.71,
                floatingShortProfitEOD: 0.64,
                isInPercentageMode: false,
                login: "DEMO:MT4:8805626",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "GBPUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.23807,
            },
        ],
        GBPCAD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1.58,
                floatingProfitEOD: -0.04,
                floatingShortProfit: -1.58,
                floatingShortProfitEOD: -0.04,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813499",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "GBPCAD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.708045,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -3.75,
                floatingProfitEOD: 0.84,
                floatingShortProfit: -3.75,
                floatingShortProfitEOD: 0.84,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814635",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "GBPCAD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.7065549999999998,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.78,
                floatingLongProfitEOD: -1,
                floatingProfit: -1.97,
                floatingProfitEOD: -0.58,
                floatingShortProfit: -1.19,
                floatingShortProfitEOD: 0.42,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813603",
                longPositions: 0.02,
                netPositions: 0.01,
                shortPositions: 0.01,
                symbol: "GBPCAD",
                user_trade_number: 3,
                weightedAvgLongPrice: 1.70917,
                weightedAvgShortPrice: 1.7075,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.82,
                floatingLongProfitEOD: -0.5,
                floatingProfit: -7.3,
                floatingProfitEOD: 0.34,
                floatingShortProfit: -6.48,
                floatingShortProfitEOD: 0.84,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814482",
                longPositions: 0.01,
                netPositions: -0.01,
                shortPositions: 0.02,
                symbol: "GBPCAD",
                user_trade_number: 3,
                weightedAvgLongPrice: 1.70977,
                weightedAvgShortPrice: 1.70468,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1.22,
                floatingProfitEOD: 0.42,
                floatingShortProfit: -1.22,
                floatingShortProfitEOD: 0.42,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812570",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "GBPCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.7074599999999998,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -9.81,
                floatingProfitEOD: 2.55,
                floatingShortProfit: -9.81,
                floatingShortProfitEOD: 2.55,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811401",
                longPositions: 0,
                netPositions: -0.06,
                shortPositions: 0.06,
                symbol: "GBPCAD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.7068866666666669,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -5.66,
                floatingProfitEOD: 1.26,
                floatingShortProfit: -5.66,
                floatingShortProfitEOD: 1.26,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810200",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "GBPCAD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.7065433333333333,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -5.6,
                floatingProfitEOD: 1.26,
                floatingShortProfit: -5.6,
                floatingShortProfitEOD: 1.26,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810893",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "GBPCAD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.70657,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -5.12,
                floatingProfitEOD: 1.26,
                floatingShortProfit: -5.12,
                floatingShortProfitEOD: 1.26,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812497",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "GBPCAD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.7067900000000003,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -64.52,
                floatingProfitEOD: 4.2,
                floatingShortProfit: -64.52,
                floatingShortProfitEOD: 4.2,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810858",
                longPositions: 0,
                netPositions: -0.09999999999999999,
                shortPositions: 0.09999999999999999,
                symbol: "GBPCAD",
                user_trade_number: 10,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.7002780000000002,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -279.97,
                floatingProfitEOD: 9.31,
                floatingShortProfit: -279.97,
                floatingShortProfitEOD: 9.31,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814194",
                longPositions: 0,
                netPositions: -0.21999999999999997,
                shortPositions: 0.21999999999999997,
                symbol: "GBPCAD",
                user_trade_number: 7,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.6916699999999998,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -23.55,
                floatingProfitEOD: 2.52,
                floatingShortProfit: -23.55,
                floatingShortProfitEOD: 2.52,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809854",
                longPositions: 0,
                netPositions: -0.060000000000000005,
                shortPositions: 0.060000000000000005,
                symbol: "GBPCAD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.7037449999999998,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -4.56,
                floatingProfitEOD: 1.26,
                floatingShortProfit: -4.56,
                floatingShortProfitEOD: 1.26,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808037",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "GBPCAD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.7070433333333335,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -5.68,
                floatingProfitEOD: 1.26,
                floatingShortProfit: -5.68,
                floatingShortProfitEOD: 1.26,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809951",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "GBPCAD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.7065333333333337,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -5.59,
                floatingProfitEOD: 1.26,
                floatingShortProfit: -5.59,
                floatingShortProfitEOD: 1.26,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799362",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "GBPCAD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.7065733333333337,
            },
        ],
        WTI02: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -7023,
                floatingProfitEOD: -6333,
                floatingShortProfit: -7023,
                floatingShortProfitEOD: -6333,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808384",
                longPositions: 0,
                netPositions: -43,
                shortPositions: 43,
                symbol: "WTI02",
                user_trade_number: 17,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 76.1719534883721,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -5738,
                floatingLongProfitEOD: 1152,
                floatingProfit: -438.86,
                floatingProfitEOD: -3686.48,
                floatingShortProfit: 5299.14,
                floatingShortProfitEOD: -4838.48,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808385",
                longPositions: 6,
                netPositions: -27.009999999999998,
                shortPositions: 33.01,
                symbol: "WTI02",
                user_trade_number: 23,
                weightedAvgLongPrice: 77.24033333333334,
                weightedAvgShortPrice: 76.49510754316874,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 2328,
                floatingLongProfitEOD: 193,
                floatingProfit: 2328,
                floatingProfitEOD: 193,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999110",
                longPositions: 1,
                netPositions: 1,
                shortPositions: 0,
                symbol: "WTI02",
                user_trade_number: 1,
                weightedAvgLongPrice: 73.957,
                weightedAvgShortPrice: 0,
            },
        ],
        HK50: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 80.59,
                floatingLongProfitEOD: 0,
                floatingProfit: 170.65,
                floatingProfitEOD: 0,
                floatingShortProfit: 90.06,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8804559",
                longPositions: 2,
                netPositions: 1,
                shortPositions: 1,
                symbol: "HK50",
                user_trade_number: 3,
                weightedAvgLongPrice: 17331.324999999997,
                weightedAvgShortPrice: 18360.11,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -185.03,
                floatingLongProfitEOD: 0,
                floatingProfit: -185.03,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808010",
                longPositions: 3,
                netPositions: 3,
                shortPositions: 0,
                symbol: "HK50",
                user_trade_number: 3,
                weightedAvgLongPrice: 18126.246666666666,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 252.37,
                floatingLongProfitEOD: 0,
                floatingProfit: 252.37,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808397",
                longPositions: 4,
                netPositions: 4,
                shortPositions: 0,
                symbol: "HK50",
                user_trade_number: 2,
                weightedAvgLongPrice: 17153.655,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 887.58,
                floatingLongProfitEOD: 0,
                floatingProfit: 887.58,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802346",
                longPositions: 10,
                netPositions: 10,
                shortPositions: 0,
                symbol: "HK50",
                user_trade_number: 1,
                weightedAvgLongPrice: 16953.58,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -7528.31,
                floatingLongProfitEOD: 0,
                floatingProfit: -7446.87,
                floatingProfitEOD: 0,
                floatingShortProfit: 81.44,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807843",
                longPositions: 40,
                netPositions: 32,
                shortPositions: 8,
                symbol: "HK50",
                user_trade_number: 10,
                weightedAvgLongPrice: 19112.6,
                weightedAvgShortPrice: 17737.263750000002,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 62.68,
                floatingLongProfitEOD: 0,
                floatingProfit: 62.68,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807267",
                longPositions: 2,
                netPositions: 2,
                shortPositions: 0,
                symbol: "HK50",
                user_trade_number: 2,
                weightedAvgLongPrice: 17401.190000000002,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -876.88,
                floatingLongProfitEOD: 0,
                floatingProfit: -876.88,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802111",
                longPositions: 3,
                netPositions: 3,
                shortPositions: 0,
                symbol: "HK50",
                user_trade_number: 3,
                weightedAvgLongPrice: 19923.953333333335,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 264.82,
                floatingLongProfitEOD: 0,
                floatingProfit: 264.82,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT5:999999",
                longPositions: 4,
                netPositions: 4,
                shortPositions: 0,
                symbol: "HK50",
                user_trade_number: 1,
                weightedAvgLongPrice: 17129.39,
                weightedAvgShortPrice: 0,
            },
        ],
        TSLA: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2.61,
                floatingLongProfitEOD: 0,
                floatingProfit: -2.61,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813904",
                longPositions: 3,
                netPositions: 3,
                shortPositions: 0,
                symbol: "TSLA",
                user_trade_number: 1,
                weightedAvgLongPrice: 235.18999999999997,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 12.41,
                floatingProfitEOD: 0,
                floatingShortProfit: 12.41,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999825",
                longPositions: 0,
                netPositions: -1,
                shortPositions: 1,
                symbol: "TSLA",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 246.81,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -49.02,
                floatingLongProfitEOD: 0,
                floatingProfit: -49.02,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999769",
                longPositions: 3,
                netPositions: 3,
                shortPositions: 0,
                symbol: "TSLA",
                user_trade_number: 1,
                weightedAvgLongPrice: 250.66,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -171.1,
                floatingProfitEOD: 0,
                floatingShortProfit: -171.1,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812768",
                longPositions: 0,
                netPositions: -5,
                shortPositions: 5,
                symbol: "TSLA",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 200.18,
            },
        ],
        AUDNZD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 27.25,
                floatingLongProfitEOD: -4.51,
                floatingProfit: 27.25,
                floatingProfitEOD: -4.51,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799817",
                longPositions: 0.07,
                netPositions: 0.07,
                shortPositions: 0,
                symbol: "AUDNZD",
                user_trade_number: 2,
                weightedAvgLongPrice: 1.0794214285714285,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -8.25,
                floatingProfitEOD: 38.53,
                floatingShortProfit: -8.25,
                floatingShortProfitEOD: 38.53,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799429",
                longPositions: 0,
                netPositions: -0.5,
                shortPositions: 0.5,
                symbol: "AUDNZD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.08574,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2,
                floatingLongProfitEOD: -1.48,
                floatingProfit: -20.26,
                floatingProfitEOD: 25.32,
                floatingShortProfit: -18.26,
                floatingShortProfitEOD: 26.8,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799318",
                longPositions: 0.03,
                netPositions: -0.32999999999999996,
                shortPositions: 0.36,
                symbol: "AUDNZD",
                user_trade_number: 17,
                weightedAvgLongPrice: 1.0870433333333331,
                weightedAvgShortPrice: 1.085283888888889,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 3.66,
                floatingProfitEOD: 7.8,
                floatingShortProfit: 3.66,
                floatingShortProfitEOD: 7.8,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799439",
                longPositions: 0,
                netPositions: -0.1,
                shortPositions: 0.1,
                symbol: "AUDNZD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.08668,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2,
                floatingLongProfitEOD: -1.48,
                floatingProfit: -18.81,
                floatingProfitEOD: 25.37,
                floatingShortProfit: -16.81,
                floatingShortProfitEOD: 26.85,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799317",
                longPositions: 0.03,
                netPositions: -0.32999999999999996,
                shortPositions: 0.36,
                symbol: "AUDNZD",
                user_trade_number: 17,
                weightedAvgLongPrice: 1.0870433333333331,
                weightedAvgShortPrice: 1.0853483333333336,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -181.44,
                floatingProfitEOD: 36.29,
                floatingShortProfit: -181.44,
                floatingShortProfitEOD: 36.29,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799812",
                longPositions: 0,
                netPositions: -0.5,
                shortPositions: 0.5,
                symbol: "AUDNZD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.08012,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 1.8,
                floatingProfitEOD: 3.9,
                floatingShortProfit: 1.8,
                floatingShortProfitEOD: 3.9,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799438",
                longPositions: 0,
                netPositions: -0.05,
                shortPositions: 0.05,
                symbol: "AUDNZD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.08667,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.86,
                floatingLongProfitEOD: -0.62,
                floatingProfit: -0.86,
                floatingProfitEOD: -0.62,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799876",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "AUDNZD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.08737,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -30.58,
                floatingLongProfitEOD: -19.61,
                floatingProfit: -30.58,
                floatingProfitEOD: -19.61,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999545",
                longPositions: 0.3,
                netPositions: 0.3,
                shortPositions: 0,
                symbol: "AUDNZD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.08766,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 17.6,
                floatingLongProfitEOD: -3.21,
                floatingProfit: 17.6,
                floatingProfitEOD: -3.21,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799685",
                longPositions: 0.05,
                netPositions: 0.05,
                shortPositions: 0,
                symbol: "AUDNZD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.08005,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -31.2,
                floatingProfitEOD: 4.36,
                floatingShortProfit: -31.2,
                floatingShortProfitEOD: 4.36,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809033",
                longPositions: 0,
                netPositions: -0.06,
                shortPositions: 0.06,
                symbol: "AUDNZD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.0775033333333335,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 3.38,
                closedProfitEOD: 2.5,
                closedShortPositions: 0.04,
                closedShortProfit: 3.38,
                closedShortProfitEOD: 2.5,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812047",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "AUDNZD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2.57,
                floatingLongProfitEOD: -4.94,
                floatingProfit: -2.57,
                floatingProfitEOD: -4.94,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812247",
                longPositions: 0.08,
                netPositions: 0.08,
                shortPositions: 0,
                symbol: "AUDNZD",
                user_trade_number: 2,
                weightedAvgLongPrice: 1.0864775,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -24.17,
                floatingProfitEOD: 7.26,
                floatingShortProfit: -24.17,
                floatingShortProfitEOD: 7.26,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808198",
                longPositions: 0,
                netPositions: -0.1,
                shortPositions: 0.1,
                symbol: "AUDNZD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.08214,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -9.95,
                floatingLongProfitEOD: -6.18,
                floatingProfit: -9.95,
                floatingProfitEOD: -6.18,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812920",
                longPositions: 0.1,
                netPositions: 0.1,
                shortPositions: 0,
                symbol: "AUDNZD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.0876,
                weightedAvgShortPrice: 0,
            },
        ],
        EURCAD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -199.02,
                floatingProfitEOD: 2.47,
                floatingShortProfit: -199.02,
                floatingShortProfitEOD: 2.47,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799363",
                longPositions: 0,
                netPositions: -0.08,
                shortPositions: 0.08,
                symbol: "EURCAD",
                user_trade_number: 7,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.4624637500000002,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.01,
                floatingLongProfitEOD: -0.37,
                floatingProfit: -4733.36,
                floatingProfitEOD: 356.49,
                floatingShortProfit: -4733.35,
                floatingShortProfitEOD: 356.86,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799372",
                longPositions: 0.01,
                netPositions: -11.390000000000002,
                shortPositions: 11.400000000000002,
                symbol: "EURCAD",
                user_trade_number: 7,
                weightedAvgLongPrice: 1.49627,
                weightedAvgShortPrice: 1.490893745614035,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.19,
                floatingLongProfitEOD: -0.37,
                floatingProfit: -40.99,
                floatingProfitEOD: 6.48,
                floatingShortProfit: -41.18,
                floatingShortProfitEOD: 6.85,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799318",
                longPositions: 0.01,
                netPositions: -0.21000000000000002,
                shortPositions: 0.22000000000000003,
                symbol: "EURCAD",
                user_trade_number: 12,
                weightedAvgLongPrice: 1.49599,
                weightedAvgShortPrice: 1.4940250000000002,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.19,
                floatingLongProfitEOD: -0.37,
                floatingProfit: -41.11,
                floatingProfitEOD: 6.48,
                floatingShortProfit: -41.3,
                floatingShortProfitEOD: 6.85,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799317",
                longPositions: 0.01,
                netPositions: -0.21000000000000002,
                shortPositions: 0.22000000000000003,
                symbol: "EURCAD",
                user_trade_number: 12,
                weightedAvgLongPrice: 1.49599,
                weightedAvgShortPrice: 1.4940177272727273,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -2846.88,
                floatingProfitEOD: 80.9,
                floatingShortProfit: -2846.88,
                floatingShortProfitEOD: 80.9,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799711",
                longPositions: 0,
                netPositions: -3,
                shortPositions: 3,
                symbol: "EURCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.48364,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -162.9,
                floatingProfitEOD: 13.48,
                floatingShortProfit: -162.9,
                floatingShortProfitEOD: 13.48,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799831",
                longPositions: 0,
                netPositions: -0.5,
                shortPositions: 0.5,
                symbol: "EURCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.49219,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1384.85,
                floatingProfitEOD: 53.25,
                floatingShortProfit: -1384.85,
                floatingShortProfitEOD: 53.25,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799488",
                longPositions: 0,
                netPositions: -1.7100000000000009,
                shortPositions: 1.7100000000000009,
                symbol: "EURCAD",
                user_trade_number: 21,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.485481228070175,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -78.15,
                floatingProfitEOD: 6.51,
                floatingShortProfit: -78.15,
                floatingShortProfitEOD: 6.51,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799312",
                longPositions: 0,
                netPositions: -0.21,
                shortPositions: 0.21,
                symbol: "EURCAD",
                user_trade_number: 12,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.4914919047619044,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 811.77,
                floatingLongProfitEOD: -11.15,
                floatingProfit: 811.77,
                floatingProfitEOD: -11.15,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799453",
                longPositions: 0.3,
                netPositions: 0.3,
                shortPositions: 0,
                symbol: "EURCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.45913,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -189.33,
                floatingProfitEOD: 6.21,
                floatingShortProfit: -189.33,
                floatingShortProfitEOD: 6.21,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812047",
                longPositions: 0,
                netPositions: -0.23,
                shortPositions: 0.23,
                symbol: "EURCAD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.485365652173913,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -216.16,
                floatingProfitEOD: 15.11,
                floatingShortProfit: -216.16,
                floatingShortProfitEOD: 15.11,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813697",
                longPositions: 0,
                netPositions: -0.56,
                shortPositions: 0.56,
                symbol: "EURCAD",
                user_trade_number: 5,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.4913639285714284,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 14.43,
                floatingLongProfitEOD: -4.92,
                floatingProfit: 14.43,
                floatingProfitEOD: -4.92,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810483",
                longPositions: 0.15,
                netPositions: 0.15,
                shortPositions: 0,
                symbol: "EURCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.49497,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -475.21,
                floatingProfitEOD: 13.48,
                floatingShortProfit: -475.21,
                floatingShortProfitEOD: 13.48,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807296",
                longPositions: 0,
                netPositions: -0.5,
                shortPositions: 0.5,
                symbol: "EURCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.48362,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -80.45,
                floatingProfitEOD: 4.59,
                floatingShortProfit: -80.45,
                floatingShortProfitEOD: 4.59,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813471",
                longPositions: 0,
                netPositions: -0.17,
                shortPositions: 0.17,
                symbol: "EURCAD",
                user_trade_number: 4,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.4901664705882351,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -5.89,
                floatingProfitEOD: 0.54,
                floatingShortProfit: -5.89,
                floatingShortProfitEOD: 0.54,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812283",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "EURCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.49262,
            },
        ],
        GBPCHF: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.09,
                floatingLongProfitEOD: -0.33,
                floatingProfit: -14.58,
                floatingProfitEOD: 0.87,
                floatingShortProfit: -14.49,
                floatingShortProfitEOD: 1.2,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799362",
                longPositions: 0.01,
                netPositions: -0.03,
                shortPositions: 0.04,
                symbol: "GBPCHF",
                user_trade_number: 5,
                weightedAvgLongPrice: 1.10348,
                weightedAvgShortPrice: 1.10056,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1.1,
                floatingLongProfitEOD: -0.33,
                floatingProfit: -27.02,
                floatingProfitEOD: 3.31,
                floatingShortProfit: -25.92,
                floatingShortProfitEOD: 3.64,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799318",
                longPositions: 0.01,
                netPositions: -0.11,
                shortPositions: 0.12,
                symbol: "GBPCHF",
                user_trade_number: 9,
                weightedAvgLongPrice: 1.10437,
                weightedAvgShortPrice: 1.1018566666666667,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1.11,
                floatingLongProfitEOD: -0.33,
                floatingProfit: -27.01,
                floatingProfitEOD: 3.31,
                floatingShortProfit: -25.9,
                floatingShortProfitEOD: 3.64,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799317",
                longPositions: 0.01,
                netPositions: -0.11,
                shortPositions: 0.12,
                symbol: "GBPCHF",
                user_trade_number: 9,
                weightedAvgLongPrice: 1.10438,
                weightedAvgShortPrice: 1.1018575000000002,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -304.8,
                floatingLongProfitEOD: -16.37,
                floatingProfit: -304.8,
                floatingProfitEOD: -16.37,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799812",
                longPositions: 0.5,
                netPositions: 0.5,
                shortPositions: 0,
                symbol: "GBPCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.10878,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.15,
                floatingLongProfitEOD: -0.33,
                floatingProfit: -1,
                floatingProfitEOD: -0.03,
                floatingShortProfit: -0.85,
                floatingShortProfitEOD: 0.3,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799515",
                longPositions: 0.01,
                netPositions: 0,
                shortPositions: 0.01,
                symbol: "GBPCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 1.10353,
                weightedAvgShortPrice: 1.10302,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -6.96,
                floatingLongProfitEOD: -0.66,
                floatingProfit: -6.96,
                floatingProfitEOD: -0.66,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799457",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "GBPCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 1.10648,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -73.66,
                floatingLongProfitEOD: -1.64,
                floatingProfit: -73.66,
                floatingProfitEOD: -1.64,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799685",
                longPositions: 0.05,
                netPositions: 0.05,
                shortPositions: 0,
                symbol: "GBPCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.11645,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -4.2,
                floatingLongProfitEOD: -1.31,
                floatingProfit: -4.2,
                floatingProfitEOD: -1.31,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812047",
                longPositions: 0.04,
                netPositions: 0.04,
                shortPositions: 0,
                symbol: "GBPCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.10431,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.08,
                floatingLongProfitEOD: -0.33,
                floatingProfit: 0.08,
                floatingProfitEOD: -0.33,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813995",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "GBPCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.10331,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.05,
                floatingLongProfitEOD: -0.33,
                floatingProfit: -0.05,
                floatingProfitEOD: -0.33,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812103",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "GBPCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.10342,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.14,
                floatingLongProfitEOD: -0.98,
                floatingProfit: -0.14,
                floatingProfitEOD: -0.98,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813137",
                longPositions: 0.03,
                netPositions: 0.03,
                shortPositions: 0,
                symbol: "GBPCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.10342,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -161.44,
                floatingProfitEOD: 10.66,
                floatingShortProfit: -161.44,
                floatingShortProfitEOD: 10.66,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813698",
                longPositions: 0,
                netPositions: -0.35,
                shortPositions: 0.35,
                symbol: "GBPCHF",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.0996928571428575,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.14,
                floatingLongProfitEOD: -0.98,
                floatingProfit: -0.14,
                floatingProfitEOD: -0.98,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812247",
                longPositions: 0.03,
                netPositions: 0.03,
                shortPositions: 0,
                symbol: "GBPCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.10342,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.18,
                floatingLongProfitEOD: -1.31,
                floatingProfit: -0.18,
                floatingProfitEOD: -1.31,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811326",
                longPositions: 0.04,
                netPositions: 0.04,
                shortPositions: 0,
                symbol: "GBPCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.10342,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1.04,
                floatingLongProfitEOD: -0.33,
                floatingProfit: -1.04,
                floatingProfitEOD: -0.33,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808549",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "GBPCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.1043,
                weightedAvgShortPrice: 0,
            },
        ],
        USDCHF: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -5.88,
                floatingLongProfitEOD: 0.6,
                floatingProfit: -6.99,
                floatingProfitEOD: 0.33,
                floatingShortProfit: -1.11,
                floatingShortProfitEOD: -0.27,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799361",
                longPositions: 0.02,
                netPositions: 0.01,
                shortPositions: 0.01,
                symbol: "USDCHF",
                user_trade_number: 3,
                weightedAvgLongPrice: 0.8884700000000001,
                weightedAvgShortPrice: 0.88509,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -832.34,
                floatingLongProfitEOD: 63.8,
                floatingProfit: -832.84,
                floatingProfitEOD: 63.53,
                floatingShortProfit: -0.5,
                floatingShortProfitEOD: -0.27,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799318",
                longPositions: 2.1400000000000006,
                netPositions: 2.130000000000001,
                shortPositions: 0.01,
                symbol: "USDCHF",
                user_trade_number: 27,
                weightedAvgLongPrice: 0.8893098130841118,
                weightedAvgShortPrice: 0.88563,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -833.84,
                floatingLongProfitEOD: 63.8,
                floatingProfit: -834.34,
                floatingProfitEOD: 63.53,
                floatingShortProfit: -0.5,
                floatingShortProfitEOD: -0.27,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799317",
                longPositions: 2.1400000000000006,
                netPositions: 2.130000000000001,
                shortPositions: 0.01,
                symbol: "USDCHF",
                user_trade_number: 27,
                weightedAvgLongPrice: 0.8893160280373829,
                weightedAvgShortPrice: 0.88563,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -167.64,
                floatingLongProfitEOD: 15.24,
                floatingProfit: -167.64,
                floatingProfitEOD: 15.24,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799831",
                longPositions: 0.5,
                netPositions: 0.5,
                shortPositions: 0,
                symbol: "USDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.88882,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 5700.61,
                floatingLongProfitEOD: 65.47,
                floatingProfit: 5700.61,
                floatingProfitEOD: 65.47,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799952",
                longPositions: 2,
                netPositions: 2,
                shortPositions: 0,
                symbol: "USDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.86062,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -316.08,
                floatingLongProfitEOD: 34.14,
                floatingProfit: -316.08,
                floatingProfitEOD: 34.14,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799665",
                longPositions: 1.12,
                netPositions: 1.12,
                shortPositions: 0,
                symbol: "USDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.88835,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1373.09,
                floatingLongProfitEOD: 3.63,
                floatingProfit: -1041.77,
                floatingProfitEOD: 2.82,
                floatingShortProfit: 331.32,
                floatingShortProfitEOD: -0.81,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999698",
                longPositions: 0.11999999999999998,
                netPositions: 0.08999999999999998,
                shortPositions: 0.03,
                symbol: "USDCHF",
                user_trade_number: 15,
                weightedAvgLongPrice: 0.9872125,
                weightedAvgShortPrice: 0.98394,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 10.66,
                floatingProfitEOD: -9.48,
                floatingShortProfit: 10.66,
                floatingShortProfitEOD: -9.48,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999104",
                longPositions: 0,
                netPositions: -0.35000000000000003,
                shortPositions: 0.35000000000000003,
                symbol: "USDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.88635,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -120.28,
                floatingLongProfitEOD: 1.52,
                floatingProfit: -120.28,
                floatingProfitEOD: 1.52,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799685",
                longPositions: 0.05,
                netPositions: 0.05,
                shortPositions: 0,
                symbol: "USDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.90718,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 782.78,
                floatingProfitEOD: -24.39,
                floatingShortProfit: 782.78,
                floatingShortProfitEOD: -24.39,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799453",
                longPositions: 0,
                netPositions: -0.8999999999999999,
                shortPositions: 0.8999999999999999,
                symbol: "USDCHF",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8937766666666666,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -99.2,
                floatingLongProfitEOD: 2.44,
                floatingProfit: -99.2,
                floatingProfitEOD: 2.44,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813752",
                longPositions: 0.08,
                netPositions: 0.08,
                shortPositions: 0,
                symbol: "USDCHF",
                user_trade_number: 3,
                weightedAvgLongPrice: 0.8968349999999999,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -32.34,
                floatingLongProfitEOD: 0.91,
                floatingProfit: -32.34,
                floatingProfitEOD: 0.91,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813995",
                longPositions: 0.03,
                netPositions: 0.03,
                shortPositions: 0,
                symbol: "USDCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0.8954000000000002,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -485.77,
                floatingLongProfitEOD: 12.63,
                floatingProfit: -485.77,
                floatingProfitEOD: 12.63,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810483",
                longPositions: 0.42000000000000004,
                netPositions: 0.42000000000000004,
                shortPositions: 0,
                symbol: "USDCHF",
                user_trade_number: 2,
                weightedAvgLongPrice: 0.8960921428571429,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -10.76,
                floatingLongProfitEOD: 0.3,
                floatingProfit: -10.76,
                floatingProfitEOD: 0.3,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809373",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "USDCHF",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.89538,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -49.8,
                floatingLongProfitEOD: 1.21,
                floatingProfit: -49.8,
                floatingProfitEOD: 1.21,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812302",
                longPositions: 0.04,
                netPositions: 0.04,
                shortPositions: 0,
                symbol: "USDCHF",
                user_trade_number: 3,
                weightedAvgLongPrice: 0.8968799999999999,
                weightedAvgShortPrice: 0,
            },
        ],
        NZDJPY: [
            {
                closedLongPositions: 0.01,
                closedLongProfit: 0.53,
                closedLongProfitEOD: 0.53,
                closedProfit: 0.53,
                closedProfitEOD: 0.53,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -9.39,
                floatingProfitEOD: -5.04,
                floatingShortProfit: -9.39,
                floatingShortProfitEOD: -5.04,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799362",
                longPositions: 0,
                netPositions: -0.04,
                shortPositions: 0.04,
                symbol: "NZDJPY",
                user_trade_number: 5,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 89.51975,
            },
            {
                closedLongPositions: 1.38,
                closedLongProfit: 92.56,
                closedLongProfitEOD: 168.55,
                closedProfit: 92.56,
                closedProfitEOD: 168.55,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.24,
                floatingLongProfitEOD: -0.24,
                floatingProfit: -22.9,
                floatingProfitEOD: -14.23,
                floatingShortProfit: -22.66,
                floatingShortProfitEOD: -13.99,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799318",
                longPositions: 0.009999999999999917,
                netPositions: -0.1400000000000001,
                shortPositions: 0.15000000000000002,
                symbol: "NZDJPY",
                user_trade_number: 33,
                weightedAvgLongPrice: 89.88400000000145,
                weightedAvgShortPrice: 89.64513333333332,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -17.41,
                floatingLongProfitEOD: 3.85,
                floatingProfit: -17.41,
                floatingProfitEOD: 3.85,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799317",
                longPositions: 0.03,
                netPositions: 0.03,
                shortPositions: 0,
                symbol: "NZDJPY",
                user_trade_number: 3,
                weightedAvgLongPrice: 90.71833333333335,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -45.3,
                floatingProfitEOD: -2.46,
                floatingShortProfit: -45.3,
                floatingShortProfitEOD: -2.46,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799685",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "NZDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 86.477,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -641.41,
                floatingProfitEOD: -42.98,
                floatingShortProfit: -641.41,
                floatingShortProfitEOD: -42.98,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799453",
                longPositions: 0,
                netPositions: -0.35000000000000003,
                shortPositions: 0.35000000000000003,
                symbol: "NZDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 87.125,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -808.86,
                floatingProfitEOD: -185.19,
                floatingShortProfit: -808.86,
                floatingShortProfitEOD: -185.19,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799310",
                longPositions: 0,
                netPositions: -1.5,
                shortPositions: 1.5,
                symbol: "NZDJPY",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 89.06400000000001,
            },
            {
                closedLongPositions: 0.15,
                closedLongProfit: -0.2,
                closedLongProfitEOD: -17.52,
                closedProfit: -0.2,
                closedProfitEOD: -17.52,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808280",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "NZDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 46.03,
                floatingLongProfitEOD: 24.09,
                floatingProfit: 46.03,
                floatingProfitEOD: 24.09,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811737",
                longPositions: 0.19,
                netPositions: 0.19,
                shortPositions: 0,
                symbol: "NZDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 89.48100000000001,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0.2,
                closedLongProfit: 24.29,
                closedLongProfitEOD: 1.2,
                closedProfit: 24.29,
                closedProfitEOD: 1.2,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811038",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "NZDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -3.32,
                floatingProfitEOD: -1.23,
                floatingShortProfit: -3.32,
                floatingShortProfitEOD: -1.23,
                isInPercentageMode: false,
                login: "DEMO:MT4:8806537",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "NZDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 89.375,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.74,
                floatingLongProfitEOD: 1.27,
                floatingProfit: 0.74,
                floatingProfitEOD: 1.27,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814715",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "NZDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 89.736,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -14.03,
                floatingProfitEOD: -1.23,
                floatingShortProfit: -14.03,
                floatingShortProfitEOD: -1.23,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809801",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "NZDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 87.77,
            },
        ],
        MANU: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -160.32,
                floatingLongProfitEOD: 0,
                floatingProfit: -160.32,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999703",
                longPositions: 501,
                netPositions: 501,
                shortPositions: 0,
                symbol: "MANU",
                user_trade_number: 2,
                weightedAvgLongPrice: 20.17,
                weightedAvgShortPrice: 0,
            },
        ],
        EURAUD: [
            {
                closedLongPositions: 0.01,
                closedLongProfit: 0.52,
                closedLongProfitEOD: 0.85,
                closedProfit: 0.52,
                closedProfitEOD: 0.85,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.63,
                floatingLongProfitEOD: -0.63,
                floatingProfit: -11.03,
                floatingProfitEOD: 0.39,
                floatingShortProfit: -10.4,
                floatingShortProfitEOD: 1.02,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799363",
                longPositions: 0.01,
                netPositions: -0.03,
                shortPositions: 0.04,
                symbol: "EURAUD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1.6757,
                weightedAvgShortPrice: 1.6708525,
            },
            {
                closedLongPositions: 0.01,
                closedLongProfit: 0.52,
                closedLongProfitEOD: 0.85,
                closedProfit: 0.52,
                closedProfitEOD: 0.85,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.63,
                floatingLongProfitEOD: -0.63,
                floatingProfit: -11.07,
                floatingProfitEOD: 0.39,
                floatingShortProfit: -10.44,
                floatingShortProfitEOD: 1.02,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799361",
                longPositions: 0.01,
                netPositions: -0.03,
                shortPositions: 0.04,
                symbol: "EURAUD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1.6757,
                weightedAvgShortPrice: 1.6708350000000003,
            },
            {
                closedLongPositions: 0.03,
                closedLongProfit: 2.16,
                closedLongProfitEOD: 2.19,
                closedProfit: 2.16,
                closedProfitEOD: 2.19,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2.6,
                floatingLongProfitEOD: -2.6,
                floatingProfit: -6.78,
                floatingProfitEOD: -2.1,
                floatingShortProfit: -4.18,
                floatingShortProfitEOD: 0.5,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799426",
                longPositions: 0.009999999999999997,
                netPositions: -0.010000000000000004,
                shortPositions: 0.02,
                symbol: "EURAUD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1.6785700000000006,
                weightedAvgShortPrice: 1.6716399999999998,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2.22,
                floatingLongProfitEOD: -0.43,
                floatingProfit: -48.18,
                floatingProfitEOD: 1.09,
                floatingShortProfit: -45.96,
                floatingShortProfitEOD: 1.52,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799446",
                longPositions: 0.02,
                netPositions: -0.04999999999999999,
                shortPositions: 0.06999999999999999,
                symbol: "EURAUD",
                user_trade_number: 7,
                weightedAvgLongPrice: 1.676375,
                weightedAvgShortPrice: 1.664827142857143,
            },
            {
                closedLongPositions: 0.02,
                closedLongProfit: 1.04,
                closedLongProfitEOD: 1.72,
                closedProfit: 1.04,
                closedProfitEOD: 1.72,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.73,
                floatingLongProfitEOD: -0.73,
                floatingProfit: -24.39,
                floatingProfitEOD: 1.85,
                floatingShortProfit: -23.66,
                floatingShortProfitEOD: 2.58,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799775",
                longPositions: 0.02,
                netPositions: -0.08,
                shortPositions: 0.1,
                symbol: "EURAUD",
                user_trade_number: 6,
                weightedAvgLongPrice: 1.6751600000000002,
                weightedAvgShortPrice: 1.6712110000000002,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -79.47,
                floatingProfitEOD: 8.49,
                floatingShortProfit: -79.47,
                floatingShortProfitEOD: 8.49,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799488",
                longPositions: 0,
                netPositions: -0.33,
                shortPositions: 0.33,
                symbol: "EURAUD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.6711478787878784,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1.24,
                floatingLongProfitEOD: -0.54,
                floatingProfit: -6.41,
                floatingProfitEOD: -0.03,
                floatingShortProfit: -5.17,
                floatingShortProfitEOD: 0.51,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799312",
                longPositions: 0.02,
                netPositions: 0,
                shortPositions: 0.02,
                symbol: "EURAUD",
                user_trade_number: 4,
                weightedAvgLongPrice: 1.6755400000000003,
                weightedAvgShortPrice: 1.67088,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -110.01,
                floatingProfitEOD: 0.85,
                floatingShortProfit: -110.01,
                floatingShortProfitEOD: 0.85,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799685",
                longPositions: 0,
                netPositions: -0.05,
                shortPositions: 0.05,
                symbol: "EURAUD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.6412,
            },
            {
                closedLongPositions: 0.06,
                closedLongProfit: 3.89,
                closedLongProfitEOD: 2.7,
                closedProfit: 3.89,
                closedProfitEOD: 2.7,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -4.5,
                floatingLongProfitEOD: -4.5,
                floatingProfit: -28.27,
                floatingProfitEOD: 0.56,
                floatingShortProfit: -23.77,
                floatingShortProfitEOD: 5.06,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799318",
                longPositions: 0.04000000000000001,
                netPositions: -0.18,
                shortPositions: 0.22,
                symbol: "EURAUD",
                user_trade_number: 20,
                weightedAvgLongPrice: 1.6762999999999997,
                weightedAvgShortPrice: 1.6732263636363633,
            },
            {
                closedLongPositions: 0.06,
                closedLongProfit: 3.91,
                closedLongProfitEOD: 2.63,
                closedProfit: 3.91,
                closedProfitEOD: 2.63,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -4.5,
                floatingLongProfitEOD: -4.5,
                floatingProfit: -28.21,
                floatingProfitEOD: 0.56,
                floatingShortProfit: -23.71,
                floatingShortProfitEOD: 5.06,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799317",
                longPositions: 0.04000000000000001,
                netPositions: -0.18,
                shortPositions: 0.22,
                symbol: "EURAUD",
                user_trade_number: 20,
                weightedAvgLongPrice: 1.6763000000000003,
                weightedAvgShortPrice: 1.6732313636363638,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -10.49,
                floatingProfitEOD: 0.96,
                floatingShortProfit: -10.49,
                floatingShortProfitEOD: 0.96,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799515",
                longPositions: 0,
                netPositions: -0.04,
                shortPositions: 0.04,
                symbol: "EURAUD",
                user_trade_number: 4,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.670845,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -130.29,
                floatingProfitEOD: 18.3,
                floatingShortProfit: -130.29,
                floatingShortProfitEOD: 18.3,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999596",
                longPositions: 0,
                netPositions: -0.7200000000000001,
                shortPositions: 0.7200000000000001,
                symbol: "EURAUD",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.6720719444444443,
            },
            {
                closedLongPositions: 0.04,
                closedLongProfit: 4.61,
                closedLongProfitEOD: 3.2,
                closedProfit: 4.61,
                closedProfitEOD: 3.2,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812047",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "EURAUD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -906.65,
                floatingProfitEOD: 5.76,
                floatingShortProfit: -906.65,
                floatingShortProfitEOD: 5.76,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807494",
                longPositions: 0,
                netPositions: -0.26,
                shortPositions: 0.26,
                symbol: "EURAUD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 1.6213761538461537,
            },
            {
                closedLongPositions: 0.1,
                closedLongProfit: 11.53,
                closedLongProfitEOD: 8.01,
                closedProfit: 11.53,
                closedProfitEOD: 8.01,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808198",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "EURAUD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
        ],
        NZDCAD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -12.47,
                floatingLongProfitEOD: 6.2,
                floatingProfit: -12.47,
                floatingProfitEOD: 6.2,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808053",
                longPositions: 0.1,
                netPositions: 0.1,
                shortPositions: 0,
                symbol: "NZDCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.8243,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -9.18,
                floatingLongProfitEOD: 9.29,
                floatingProfit: -9.18,
                floatingProfitEOD: 9.29,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810483",
                longPositions: 0.15,
                netPositions: 0.15,
                shortPositions: 0,
                symbol: "NZDCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.82343,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -510.65,
                floatingProfitEOD: -32.89,
                floatingShortProfit: -510.65,
                floatingShortProfitEOD: -32.89,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808198",
                longPositions: 0,
                netPositions: -0.6000000000000001,
                shortPositions: 0.6000000000000001,
                symbol: "NZDCAD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.811245,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -29.09,
                floatingLongProfitEOD: 6.34,
                floatingProfit: -29.09,
                floatingProfitEOD: 6.34,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812920",
                longPositions: 0.1,
                netPositions: 0.1,
                shortPositions: 0,
                symbol: "NZDCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.8266000000000001,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -47.89,
                floatingLongProfitEOD: 9.08,
                floatingProfit: -47.89,
                floatingProfitEOD: 9.08,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811597",
                longPositions: 0.15,
                netPositions: 0.15,
                shortPositions: 0,
                symbol: "NZDCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.82695,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -10.09,
                floatingLongProfitEOD: 4.96,
                floatingProfit: -10.09,
                floatingProfitEOD: 4.96,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812134",
                longPositions: 0.08,
                netPositions: 0.08,
                shortPositions: 0,
                symbol: "NZDCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.82432,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -4.99,
                floatingLongProfitEOD: 2.48,
                floatingProfit: -4.99,
                floatingProfitEOD: 2.48,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807708",
                longPositions: 0.04,
                netPositions: 0.04,
                shortPositions: 0,
                symbol: "NZDCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.8243,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0.01,
                closedLongProfit: 0.7,
                closedLongProfitEOD: 0.93,
                closedProfit: 0.7,
                closedProfitEOD: 0.93,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808505",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "NZDCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -11.73,
                floatingProfitEOD: -1.59,
                floatingShortProfit: -11.73,
                floatingShortProfitEOD: -1.59,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808549",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "NZDCAD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8175366666666667,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -12.47,
                floatingLongProfitEOD: 6.2,
                floatingProfit: -12.47,
                floatingProfitEOD: 6.2,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809205",
                longPositions: 0.1,
                netPositions: 0.1,
                shortPositions: 0,
                symbol: "NZDCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.8243,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -17.66,
                floatingLongProfitEOD: 8.67,
                floatingProfit: -17.66,
                floatingProfitEOD: 8.67,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807457",
                longPositions: 0.14,
                netPositions: 0.14,
                shortPositions: 0,
                symbol: "NZDCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.82432,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -23.96,
                floatingLongProfitEOD: 11.77,
                floatingProfit: -23.96,
                floatingProfitEOD: 11.77,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808766",
                longPositions: 0.19,
                netPositions: 0.19,
                shortPositions: 0,
                symbol: "NZDCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.82432,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -12.47,
                floatingLongProfitEOD: 6.2,
                floatingProfit: -12.47,
                floatingProfitEOD: 6.2,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808676",
                longPositions: 0.1,
                netPositions: 0.1,
                shortPositions: 0,
                symbol: "NZDCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.8243,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -12.68,
                floatingLongProfitEOD: 6.2,
                floatingProfit: -12.68,
                floatingProfitEOD: 6.2,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807499",
                longPositions: 0.1,
                netPositions: 0.1,
                shortPositions: 0,
                symbol: "NZDCAD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.82433,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -7.41,
                floatingProfitEOD: -1.65,
                floatingShortProfit: -7.41,
                floatingShortProfitEOD: -1.65,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799362",
                longPositions: 0,
                netPositions: -0.03,
                shortPositions: 0.03,
                symbol: "NZDCAD",
                user_trade_number: 3,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0.8195266666666666,
            },
        ],
        CADJPY: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -3.39,
                floatingLongProfitEOD: 3.15,
                floatingProfit: -3.39,
                floatingProfitEOD: 3.15,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812047",
                longPositions: 0.04,
                netPositions: 0.04,
                shortPositions: 0,
                symbol: "CADJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 109.327,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -5.42,
                floatingLongProfitEOD: 2.37,
                floatingProfit: -5.42,
                floatingProfitEOD: 2.37,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808549",
                longPositions: 0.03,
                netPositions: 0.03,
                shortPositions: 0,
                symbol: "CADJPY",
                user_trade_number: 2,
                weightedAvgLongPrice: 109.47066666666666,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -10.45,
                floatingLongProfitEOD: 3.94,
                floatingProfit: -10.45,
                floatingProfitEOD: 3.94,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813889",
                longPositions: 0.05,
                netPositions: 0.05,
                shortPositions: 0,
                symbol: "CADJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 109.513,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -41.98,
                floatingLongProfitEOD: 3.94,
                floatingProfit: -41.98,
                floatingProfitEOD: 3.94,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811737",
                longPositions: 0.05,
                netPositions: 0.05,
                shortPositions: 0,
                symbol: "CADJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 110.458,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -19.11,
                floatingProfitEOD: -6.19,
                floatingShortProfit: -19.11,
                floatingShortProfitEOD: -6.19,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810133",
                longPositions: 0,
                netPositions: -0.08,
                shortPositions: 0.08,
                symbol: "CADJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 108.87100000000001,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -19,
                floatingLongProfitEOD: 336.79,
                floatingProfit: -37.6,
                floatingProfitEOD: 328.44,
                floatingShortProfit: -18.6,
                floatingShortProfitEOD: -8.35,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799318",
                longPositions: 4.37,
                netPositions: 4.25,
                shortPositions: 0.12000000000000001,
                symbol: "CADJPY",
                user_trade_number: 39,
                weightedAvgLongPrice: 109.20543707093822,
                weightedAvgShortPrice: 108.99691666666664,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -16.63,
                floatingLongProfitEOD: 336.79,
                floatingProfit: -29.68,
                floatingProfitEOD: 330.04,
                floatingShortProfit: -13.05,
                floatingShortProfitEOD: -6.75,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799317",
                longPositions: 4.37,
                netPositions: 4.2700000000000005,
                shortPositions: 0.1,
                symbol: "CADJPY",
                user_trade_number: 38,
                weightedAvgLongPrice: 109.20462242562928,
                weightedAvgShortPrice: 109.03360000000002,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -98.95,
                floatingProfitEOD: -5.46,
                floatingShortProfit: -98.95,
                floatingShortProfitEOD: -5.46,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799685",
                longPositions: 0,
                netPositions: -0.07,
                shortPositions: 0.07,
                symbol: "CADJPY",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 107.11071428571428,
            },
            {
                closedLongPositions: 0.01,
                closedLongProfit: -2.74,
                closedLongProfitEOD: -1.17,
                closedProfit: -2.74,
                closedProfitEOD: -1.17,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814544",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "CADJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.46,
                floatingLongProfitEOD: 0.79,
                floatingProfit: 0.46,
                floatingProfitEOD: 0.79,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809551",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "CADJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 109.131,
                weightedAvgShortPrice: 0,
            },
        ],
        US30: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1876.65,
                floatingProfitEOD: -44.32,
                floatingShortProfit: -1876.65,
                floatingShortProfitEOD: -44.32,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810472",
                longPositions: 0,
                netPositions: -2.6,
                shortPositions: 2.6,
                symbol: "US30",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 34242.92076923076,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 3.66,
                floatingProfitEOD: -1.7,
                floatingShortProfit: 3.66,
                floatingShortProfitEOD: -1.7,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809227",
                longPositions: 0,
                netPositions: -0.1,
                shortPositions: 0.1,
                symbol: "US30",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 35001.34,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -387.53,
                floatingProfitEOD: -30.52,
                floatingShortProfit: -387.53,
                floatingShortProfitEOD: -30.52,
                isInPercentageMode: false,
                login: "DEMO:MT5:800038",
                longPositions: 0,
                netPositions: -1.8,
                shortPositions: 1.8,
                symbol: "US30",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 34749.415,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -22241.7,
                floatingProfitEOD: -160.5,
                floatingShortProfit: -22241.7,
                floatingShortProfitEOD: -160.5,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999936",
                longPositions: 0,
                netPositions: -10,
                shortPositions: 10,
                symbol: "US30",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 32739.54,
            },
        ],
        BCHUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.18,
                floatingLongProfitEOD: 0.06,
                floatingProfit: -0.18,
                floatingProfitEOD: 0.06,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814337",
                longPositions: 0.03,
                netPositions: 0.03,
                shortPositions: 0,
                symbol: "BCHUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 236.386,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -0.37,
                floatingProfitEOD: -0.17,
                floatingShortProfit: -0.37,
                floatingShortProfitEOD: -0.17,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812260",
                longPositions: 0,
                netPositions: -0.08,
                shortPositions: 0.08,
                symbol: "BCHUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 226.519,
            },
        ],
        AAPL: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -7.04,
                floatingProfitEOD: 0,
                floatingShortProfit: -7.04,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813904",
                longPositions: 0,
                netPositions: -4,
                shortPositions: 4,
                symbol: "AAPL",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 188.01,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 131.11,
                floatingLongProfitEOD: 0,
                floatingProfit: 131.11,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:601",
                longPositions: 8,
                netPositions: 8,
                shortPositions: 0,
                symbol: "AAPL",
                user_trade_number: 2,
                weightedAvgLongPrice: 173.28125,
                weightedAvgShortPrice: 0,
            },
        ],
        JP225: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -2108.47,
                floatingProfitEOD: 8.33,
                floatingShortProfit: -2108.47,
                floatingShortProfitEOD: 8.33,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999955",
                longPositions: 0,
                netPositions: -1,
                shortPositions: 1,
                symbol: "JP225",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 27159.43,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 48.72,
                floatingLongProfitEOD: -1.87,
                floatingProfit: 48.72,
                floatingProfitEOD: -1.87,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799564",
                longPositions: 0.2,
                netPositions: 0.2,
                shortPositions: 0,
                symbol: "JP225",
                user_trade_number: 1,
                weightedAvgLongPrice: 32737.01,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -7.14,
                floatingProfitEOD: 0.83,
                floatingShortProfit: -7.14,
                floatingShortProfitEOD: 0.83,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814969",
                longPositions: 0,
                netPositions: -0.1,
                shortPositions: 0.1,
                symbol: "JP225",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 33263.99,
            },
        ],
        UKOUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -91.78,
                floatingLongProfitEOD: 0.82,
                floatingProfit: -91.78,
                floatingProfitEOD: 0.82,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999982",
                longPositions: 1,
                netPositions: 1,
                shortPositions: 0,
                symbol: "UKOUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 90.274,
                weightedAvgShortPrice: 0,
            },
        ],
        AUDJPY: [
            {
                closedLongPositions: 0.01,
                closedLongProfit: 0.53,
                closedLongProfitEOD: 0.34,
                closedProfit: 0.53,
                closedProfitEOD: 0.34,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -10.98,
                floatingProfitEOD: -2.52,
                floatingShortProfit: -10.98,
                floatingShortProfitEOD: -2.52,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799363",
                longPositions: 0,
                netPositions: -0.04,
                shortPositions: 0.04,
                symbol: "AUDJPY",
                user_trade_number: 5,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 97.188,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -232.58,
                floatingProfitEOD: -25.99,
                floatingShortProfit: -232.58,
                floatingShortProfitEOD: -25.99,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799360",
                longPositions: 0,
                netPositions: -0.41000000000000003,
                shortPositions: 0.41000000000000003,
                symbol: "AUDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 96.75,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -52906.03,
                floatingProfitEOD: -1455.42,
                floatingShortProfit: -52906.03,
                floatingShortProfitEOD: -1455.42,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799947",
                longPositions: 0,
                netPositions: -23.2,
                shortPositions: 23.2,
                symbol: "AUDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 94.17499999999998,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -5831.95,
                floatingProfitEOD: -163.11,
                floatingShortProfit: -5831.95,
                floatingShortProfitEOD: -163.11,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799946",
                longPositions: 0,
                netPositions: -2.6,
                shortPositions: 2.6,
                symbol: "AUDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 94.231,
            },
            {
                closedLongPositions: 0.01,
                closedLongProfit: 0.67,
                closedLongProfitEOD: 0.57,
                closedProfit: 0.67,
                closedProfitEOD: 0.57,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -0.29,
                floatingLongProfitEOD: -0.29,
                floatingProfit: -28.92,
                floatingProfitEOD: -9.64,
                floatingShortProfit: -28.63,
                floatingShortProfitEOD: -9.35,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799318",
                longPositions: 0.01,
                netPositions: -0.21,
                shortPositions: 0.22,
                symbol: "AUDJPY",
                user_trade_number: 13,
                weightedAvgLongPrice: 97.612,
                weightedAvgShortPrice: 97.39840909090908,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -52.4,
                floatingProfitEOD: -3.5,
                floatingShortProfit: -52.4,
                floatingShortProfitEOD: -3.5,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799317",
                longPositions: 0,
                netPositions: -0.06000000000000001,
                shortPositions: 0.06000000000000001,
                symbol: "AUDJPY",
                user_trade_number: 5,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 96.28366666666665,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 22.07,
                floatingLongProfitEOD: 0.61,
                floatingProfit: 22.07,
                floatingProfitEOD: 0.61,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799876",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "AUDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 94.262,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -56.65,
                floatingProfitEOD: -1.12,
                floatingShortProfit: -56.65,
                floatingShortProfitEOD: -1.12,
                isInPercentageMode: false,
                login: "DEMO:MT4:8799685",
                longPositions: 0,
                netPositions: -0.02,
                shortPositions: 0.02,
                symbol: "AUDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 93.345,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1.06,
                floatingProfitEOD: -0.56,
                floatingShortProfit: -1.06,
                floatingShortProfitEOD: -0.56,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812045",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "AUDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 97.431,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -11.48,
                floatingProfitEOD: -2.54,
                floatingShortProfit: -11.48,
                floatingShortProfitEOD: -2.54,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812047",
                longPositions: 0,
                netPositions: -0.04,
                shortPositions: 0.04,
                symbol: "AUDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 97.171,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1.83,
                floatingProfitEOD: -0.56,
                floatingShortProfit: -1.83,
                floatingShortProfitEOD: -0.56,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808549",
                longPositions: 0,
                netPositions: -0.01,
                shortPositions: 0.01,
                symbol: "AUDJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 97.316,
            },
        ],
        AUDCNH: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -2256.43,
                floatingLongProfitEOD: -85.04,
                floatingProfit: -2256.43,
                floatingProfitEOD: -85.04,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8800001",
                longPositions: 5.58,
                netPositions: 5.58,
                shortPositions: 0,
                symbol: "AUDCNH",
                user_trade_number: 7,
                weightedAvgLongPrice: 4.729746254480287,
                weightedAvgShortPrice: 0,
            },
        ],
        US500: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -278.41,
                floatingProfitEOD: -1.68,
                floatingShortProfit: -278.41,
                floatingShortProfitEOD: -1.68,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814302",
                longPositions: 0,
                netPositions: -2,
                shortPositions: 2,
                symbol: "US500",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 4377.965,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -0.94,
                floatingProfitEOD: -0.67,
                floatingShortProfit: -0.94,
                floatingShortProfitEOD: -0.67,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812788",
                longPositions: 0,
                netPositions: -0.8,
                shortPositions: 0.8,
                symbol: "US500",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 4516,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 15.94,
                floatingProfitEOD: -2.77,
                floatingShortProfit: 15.94,
                floatingShortProfitEOD: -2.77,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812735",
                longPositions: 0,
                netPositions: -3.3000000000000003,
                shortPositions: 3.3000000000000003,
                symbol: "US500",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 4522,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -464.18,
                floatingProfitEOD: -1.68,
                floatingShortProfit: -464.18,
                floatingShortProfitEOD: -1.68,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809249",
                longPositions: 0,
                netPositions: -2,
                shortPositions: 2,
                symbol: "US500",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 4285.08,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -36.79,
                floatingProfitEOD: -0.17,
                floatingShortProfit: -36.79,
                floatingShortProfitEOD: -0.17,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813948",
                longPositions: 0,
                netPositions: -0.2,
                shortPositions: 0.2,
                symbol: "US500",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 4333.22,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -0.64,
                floatingProfitEOD: -0.08,
                floatingShortProfit: -0.64,
                floatingShortProfitEOD: -0.08,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809227",
                longPositions: 0,
                netPositions: -0.1,
                shortPositions: 0.1,
                symbol: "US500",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 4510.79,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 5.16,
                floatingProfitEOD: -0.59,
                floatingShortProfit: 5.16,
                floatingShortProfitEOD: -0.59,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810997",
                longPositions: 0,
                netPositions: -0.7000000000000001,
                shortPositions: 0.7000000000000001,
                symbol: "US500",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 4524.54,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: -1044.85,
                closedProfitEOD: -7.41,
                closedShortPositions: 5.4,
                closedShortProfit: -1044.85,
                closedShortProfitEOD: -7.41,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810611",
                longPositions: 0,
                netPositions: 1.1102230246251565e-15,
                shortPositions: -1.1102230246251565e-15,
                symbol: "US500",
                user_trade_number: 6,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: -3686.4,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0.89,
                floatingProfitEOD: -0.08,
                floatingShortProfit: 0.89,
                floatingShortProfitEOD: -0.08,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811511",
                longPositions: 0,
                netPositions: -0.1,
                shortPositions: 0.1,
                symbol: "US500",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 4526.04,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 34.89,
                floatingProfitEOD: -4.12,
                floatingShortProfit: 34.89,
                floatingShortProfitEOD: -4.12,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811554",
                longPositions: 0,
                netPositions: -4.9,
                shortPositions: 4.9,
                symbol: "US500",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 4524.29,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 116.03,
                floatingLongProfitEOD: 0.86,
                floatingProfit: 116.03,
                floatingProfitEOD: 0.86,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999076",
                longPositions: 1,
                netPositions: 1,
                shortPositions: 0,
                symbol: "US500",
                user_trade_number: 1,
                weightedAvgLongPrice: 4400.5,
                weightedAvgShortPrice: 0,
            },
        ],
        ETHUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: -1.29,
                closedProfitEOD: -1.29,
                closedShortPositions: 0.05,
                closedShortProfit: -1.29,
                closedShortProfitEOD: -1.29,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: 0,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809080",
                longPositions: 0,
                netPositions: 0,
                shortPositions: 0,
                symbol: "ETHUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 2.78,
                floatingLongProfitEOD: 1.77,
                floatingProfit: 2.78,
                floatingProfitEOD: 1.77,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807394",
                longPositions: 0.07,
                netPositions: 0.07,
                shortPositions: 0,
                symbol: "ETHUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1968.08,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 26.25,
                floatingLongProfitEOD: 16.68,
                floatingProfit: 26.25,
                floatingProfitEOD: 16.68,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809172",
                longPositions: 0.66,
                netPositions: 0.66,
                shortPositions: 0,
                symbol: "ETHUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1967.97,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 3.58,
                floatingLongProfitEOD: 2.27,
                floatingProfit: 3.58,
                floatingProfitEOD: 2.27,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811217",
                longPositions: 0.09,
                netPositions: 0.09,
                shortPositions: 0,
                symbol: "ETHUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1967.95,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 13.87,
                floatingLongProfitEOD: 8.84,
                floatingProfit: 13.87,
                floatingProfitEOD: 8.84,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8810204",
                longPositions: 0.35000000000000003,
                netPositions: 0.35000000000000003,
                shortPositions: 0,
                symbol: "ETHUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1968.1199999999997,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -162.24,
                floatingLongProfitEOD: 57.08,
                floatingProfit: -162.24,
                floatingProfitEOD: 57.08,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807909",
                longPositions: 4,
                netPositions: 4,
                shortPositions: 0,
                symbol: "ETHUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 2048.31,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 34.58,
                floatingLongProfitEOD: 21.98,
                floatingProfit: 34.58,
                floatingProfitEOD: 21.98,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807869",
                longPositions: 0.87,
                netPositions: 0.87,
                shortPositions: 0,
                symbol: "ETHUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1968,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 52.85,
                floatingLongProfitEOD: 33.61,
                floatingProfit: 52.85,
                floatingProfitEOD: 33.61,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808578",
                longPositions: 1.33,
                netPositions: 1.33,
                shortPositions: 0,
                symbol: "ETHUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1968.01,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 87.85,
                floatingLongProfitEOD: 55.85,
                floatingProfit: 87.85,
                floatingProfitEOD: 55.85,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808315",
                longPositions: 2.21,
                netPositions: 2.21,
                shortPositions: 0,
                symbol: "ETHUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1968,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 31.44,
                floatingLongProfitEOD: 14.65,
                floatingProfit: 31.44,
                floatingProfitEOD: 14.65,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813718",
                longPositions: 0.58,
                netPositions: 0.58,
                shortPositions: 0,
                symbol: "ETHUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 1953.5406896551724,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 34.87,
                floatingLongProfitEOD: 22.24,
                floatingProfit: 34.87,
                floatingProfitEOD: 22.24,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807667",
                longPositions: 0.88,
                netPositions: 0.88,
                shortPositions: 0,
                symbol: "ETHUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1968.1200000000001,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 9.54,
                floatingLongProfitEOD: 6.06,
                floatingProfit: 9.54,
                floatingProfitEOD: 6.06,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808317",
                longPositions: 0.24,
                netPositions: 0.24,
                shortPositions: 0,
                symbol: "ETHUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1968.02,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 26.23,
                floatingLongProfitEOD: 16.68,
                floatingProfit: 26.23,
                floatingProfitEOD: 16.68,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808497",
                longPositions: 0.66,
                netPositions: 0.66,
                shortPositions: 0,
                symbol: "ETHUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1968.01,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 21.84,
                floatingLongProfitEOD: 13.9,
                floatingProfit: 21.84,
                floatingProfitEOD: 13.9,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8807360",
                longPositions: 0.55,
                netPositions: 0.55,
                shortPositions: 0,
                symbol: "ETHUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1968.05,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 1.99,
                floatingLongProfitEOD: 1.26,
                floatingProfit: 1.99,
                floatingProfitEOD: 1.26,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808472",
                longPositions: 0.05,
                netPositions: 0.05,
                shortPositions: 0,
                symbol: "ETHUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1968.05,
                weightedAvgShortPrice: 0,
            },
        ],
        DOTUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.09,
                floatingLongProfitEOD: 0,
                floatingProfit: -0.01,
                floatingProfitEOD: 0,
                floatingShortProfit: -0.1,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813720",
                longPositions: 0.01,
                netPositions: 0,
                shortPositions: 0.01,
                symbol: "DOTUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 5.269,
                weightedAvgShortPrice: 5.267,
            },
        ],
        BABA: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -11.5,
                floatingLongProfitEOD: 0,
                floatingProfit: -11.5,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812667",
                longPositions: 10,
                netPositions: 10,
                shortPositions: 0,
                symbol: "BABA",
                user_trade_number: 3,
                weightedAvgLongPrice: 78.92583333333333,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -13,
                floatingLongProfitEOD: 0,
                floatingProfit: -13,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813822",
                longPositions: 10,
                netPositions: 10,
                shortPositions: 0,
                symbol: "BABA",
                user_trade_number: 2,
                weightedAvgLongPrice: 78.88,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -16.72,
                floatingLongProfitEOD: 0,
                floatingProfit: -16.72,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813904",
                longPositions: 10,
                netPositions: 10,
                shortPositions: 0,
                symbol: "BABA",
                user_trade_number: 2,
                weightedAvgLongPrice: 78.97333333333334,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -1.65,
                floatingLongProfitEOD: 0,
                floatingProfit: -1.65,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8812768",
                longPositions: 5,
                netPositions: 5,
                shortPositions: 0,
                symbol: "BABA",
                user_trade_number: 1,
                weightedAvgLongPrice: 77.91,
                weightedAvgShortPrice: 0,
            },
        ],
        BTCJPY: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 133.25,
                floatingLongProfitEOD: 63.45,
                floatingProfit: -7.5,
                floatingProfitEOD: -1.62,
                floatingShortProfit: -140.75,
                floatingShortProfitEOD: -65.07,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813718",
                longPositions: 0.14,
                netPositions: -0.009999999999999981,
                shortPositions: 0.15,
                symbol: "BTCJPY",
                user_trade_number: 2,
                weightedAvgLongPrice: 5462052.3,
                weightedAvgShortPrice: 5477247.7,
            },
        ],
        DE40: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -1086.21,
                floatingProfitEOD: 44.2,
                floatingShortProfit: -1086.21,
                floatingShortProfitEOD: 44.2,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802300",
                longPositions: 0,
                netPositions: -5,
                shortPositions: 5,
                symbol: "DE40",
                user_trade_number: 10,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 15744.01,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 1293.2,
                floatingLongProfitEOD: -14.07,
                floatingProfit: 1293.2,
                floatingProfitEOD: -14.07,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999459",
                longPositions: 1,
                netPositions: 1,
                shortPositions: 0,
                symbol: "DE40",
                user_trade_number: 1,
                weightedAvgLongPrice: 14747.76,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -86.09,
                floatingProfitEOD: 0,
                floatingShortProfit: -86.09,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT5:800038",
                longPositions: 0,
                netPositions: -1.4000000000000001,
                shortPositions: 1.4000000000000001,
                symbol: "DE40",
                user_trade_number: 2,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 15894.89,
            },
        ],
        UK100: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -12.65,
                floatingProfitEOD: -0.27,
                floatingShortProfit: -12.65,
                floatingShortProfitEOD: -0.27,
                isInPercentageMode: false,
                login: "DEMO:MT4:8802534",
                longPositions: 0,
                netPositions: -0.1,
                shortPositions: 0.1,
                symbol: "UK100",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 7415.9,
            },
        ],
        AMGN: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 57.82,
                floatingLongProfitEOD: 0.07,
                floatingProfit: 57.82,
                floatingProfitEOD: 0.07,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999769",
                longPositions: 7,
                netPositions: 7,
                shortPositions: 0,
                symbol: "AMGN",
                user_trade_number: 1,
                weightedAvgLongPrice: 256.97,
                weightedAvgShortPrice: 0,
            },
        ],
        USDSEK: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -498.54,
                floatingLongProfitEOD: 6.62,
                floatingProfit: -498.54,
                floatingProfitEOD: 6.62,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811420",
                longPositions: 0.08,
                netPositions: 0.08,
                shortPositions: 0,
                symbol: "USDSEK",
                user_trade_number: 1,
                weightedAvgLongPrice: 11.18858,
                weightedAvgShortPrice: 0,
            },
        ],
        NFLX: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 91.55,
                floatingLongProfitEOD: 0,
                floatingProfit: 91.55,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999769",
                longPositions: 5,
                netPositions: 5,
                shortPositions: 0,
                symbol: "NFLX",
                user_trade_number: 1,
                weightedAvgLongPrice: 447.36,
                weightedAvgShortPrice: 0,
            },
        ],
        NVDA: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0,
                floatingLongProfitEOD: 0,
                floatingProfit: -65.35,
                floatingProfitEOD: 0,
                floatingShortProfit: -65.35,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999825",
                longPositions: 0,
                netPositions: -1,
                shortPositions: 1,
                symbol: "NVDA",
                user_trade_number: 1,
                weightedAvgLongPrice: 0,
                weightedAvgShortPrice: 427.68,
            },
        ],
        META: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 73.98,
                floatingLongProfitEOD: 0,
                floatingProfit: 73.98,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999769",
                longPositions: 2,
                netPositions: 2,
                shortPositions: 0,
                symbol: "META",
                user_trade_number: 1,
                weightedAvgLongPrice: 298.02,
                weightedAvgShortPrice: 0,
            },
        ],
        PEP: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -77.13,
                floatingLongProfitEOD: 0,
                floatingProfit: -77.13,
                floatingProfitEOD: 0,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:9999769",
                longPositions: 9,
                netPositions: 9,
                shortPositions: 0,
                symbol: "PEP",
                user_trade_number: 1,
                weightedAvgLongPrice: 175.27,
                weightedAvgShortPrice: 0,
            },
        ],
        WTI01: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 5.83,
                floatingLongProfitEOD: 5.79,
                floatingProfit: 5.83,
                floatingProfitEOD: 5.79,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8814408",
                longPositions: 0.03,
                netPositions: 0.03,
                shortPositions: 0,
                symbol: "WTI01",
                user_trade_number: 2,
                weightedAvgLongPrice: 76.04066666666668,
                weightedAvgShortPrice: 0,
            },
        ],
        EOSUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.09,
                floatingLongProfitEOD: 0.01,
                floatingProfit: 0.09,
                floatingProfitEOD: 0.01,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8809022",
                longPositions: 0.01,
                netPositions: 0.01,
                shortPositions: 0,
                symbol: "EOSUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.6354,
                weightedAvgShortPrice: 0,
            },
        ],
        USDSGD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: -116.73,
                floatingLongProfitEOD: 2.08,
                floatingProfit: -116.73,
                floatingProfitEOD: 2.08,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8811420",
                longPositions: 0.08,
                netPositions: 0.08,
                shortPositions: 0,
                symbol: "USDSGD",
                user_trade_number: 1,
                weightedAvgLongPrice: 1.36284,
                weightedAvgShortPrice: 0,
            },
        ],
        XRPUSD: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 0.07,
                floatingLongProfitEOD: 0.12,
                floatingProfit: 0.07,
                floatingProfitEOD: 0.12,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8808592",
                longPositions: 0.02,
                netPositions: 0.02,
                shortPositions: 0,
                symbol: "XRPUSD",
                user_trade_number: 1,
                weightedAvgLongPrice: 0.62598,
                weightedAvgShortPrice: 0,
            },
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 8.16,
                floatingLongProfitEOD: 2.56,
                floatingProfit: 8.16,
                floatingProfitEOD: 2.56,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8805314",
                longPositions: 0.4,
                netPositions: 0.4,
                shortPositions: 0,
                symbol: "XRPUSD",
                user_trade_number: 2,
                weightedAvgLongPrice: 0.61773,
                weightedAvgShortPrice: 0,
            },
        ],
        ETHJPY: [
            {
                closedLongPositions: 0,
                closedLongProfit: 0,
                closedLongProfitEOD: 0,
                closedProfit: 0,
                closedProfitEOD: 0,
                closedShortPositions: 0,
                closedShortProfit: 0,
                closedShortProfitEOD: 0,
                floatingLongProfit: 2.79,
                floatingLongProfitEOD: 2.11,
                floatingProfit: 2.79,
                floatingProfitEOD: 2.11,
                floatingShortProfit: 0,
                floatingShortProfitEOD: 0,
                isInPercentageMode: false,
                login: "DEMO:MT4:8813718",
                longPositions: 0.08,
                netPositions: 0.08,
                shortPositions: 0,
                symbol: "ETHJPY",
                user_trade_number: 1,
                weightedAvgLongPrice: 295429.6,
                weightedAvgShortPrice: 0,
            },
        ],
    },

    mockedTestData: [
        {
            fullLogin: "Demo:MT4:xxxxx79",
            login: "MT4:xxxxx79",
            longPositions: "0.00",
            shortPositions: "1.35",
            netPositions: "-1.35",
            dailyNet: "-159.98",
            floatingProfitEOD: "-116.66",
            floatingProfit: "-973.26",
            closedProfitEOD: "-43.32",
            closedProfit: "-43.32",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.37873",
            exposure: "-135,003.00",
        },
        {
            fullLogin: "Demo:MT4:xxxxx18",
            login: "MT4:xxxxx18",
            longPositions: "0.00",
            shortPositions: "0.71",
            netPositions: "-0.71",
            dailyNet: "-5.11",
            floatingProfitEOD: "-8.83",
            floatingProfit: "-231.97",
            closedProfitEOD: "3.72",
            closedProfit: "2.91",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.38438",
            exposure: "-71,038.17",
        },
        {
            fullLogin: "Demo:MT4:xxxxx96",
            login: "MT4:xxxxx96",
            longPositions: "0.00",
            shortPositions: "0.74",
            netPositions: "-0.74",
            dailyNet: "-66.57",
            floatingProfitEOD: "-66.57",
            floatingProfit: "-632.17",
            closedProfitEOD: "0.00",
            closedProfit: "0.00",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.37693",
            exposure: "-74,019.25",
        },
        {
            fullLogin: "Demo:MT4:xxxxx95",
            login: "MT4:xxxxx95",
            longPositions: "0.00",
            shortPositions: "0.84",
            netPositions: "-0.84",
            dailyNet: "-76.93",
            floatingProfitEOD: "-76.93",
            floatingProfit: "-788.40",
            closedProfitEOD: "0.00",
            closedProfit: "0.00",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.37578",
            exposure: "-84,020.75",
        },
        {
            fullLogin: "Demo:MT4:xxxxx74",
            login: "MT4:xxxxx74",
            longPositions: "0.00",
            shortPositions: "0.13",
            netPositions: "-0.13",
            dailyNet: "15.76",
            floatingProfitEOD: "-13.01",
            floatingProfit: "-58.36",
            closedProfitEOD: "28.77",
            closedProfit: "28.77",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.38264",
            exposure: "-13,008.43",
        },
        {
            fullLogin: "Demo:MT4:xxxxx52",
            login: "MT4:xxxxx52",
            longPositions: "0.00",
            shortPositions: "1.35",
            netPositions: "-1.35",
            dailyNet: "-151.38",
            floatingProfitEOD: "-107.82",
            floatingProfit: "-964.53",
            closedProfitEOD: "-43.56",
            closedProfit: "-43.56",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.37873",
            exposure: "-135,018.35",
        },
        {
            fullLogin: "Demo:MT4:xxxxx90",
            login: "MT4:xxxxx90",
            longPositions: "0.00",
            shortPositions: "1.50",
            netPositions: "-1.50",
            dailyNet: "-166.37",
            floatingProfitEOD: "-122.70",
            floatingProfit: "-1,114.67",
            closedProfitEOD: "-43.67",
            closedProfit: "-43.67",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.37836",
            exposure: "-149,986.98",
        },
        {
            fullLogin: "Demo:MT4:xxxxx46",
            login: "MT4:xxxxx46",
            longPositions: "0.00",
            shortPositions: "0.06",
            netPositions: "-0.06",
            dailyNet: "-3.58",
            floatingProfitEOD: "-3.58",
            floatingProfit: "-29.47",
            closedProfitEOD: "0.00",
            closedProfit: "0.00",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.38179",
            exposure: "-5,998.92",
        },
        {
            fullLogin: "Demo:MT4:xxxxx56",
            login: "MT4:xxxxx56",
            longPositions: "0.00",
            shortPositions: "1.06",
            netPositions: "-1.06",
            dailyNet: "29.11",
            floatingProfitEOD: "27.93",
            floatingProfit: "-293.40",
            closedProfitEOD: "1.18",
            closedProfit: "1.75",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.38508",
            exposure: "-106,013.99",
        },
        {
            fullLogin: "Demo:MT4:xxxxx40",
            login: "MT4:xxxxx40",
            longPositions: "0.00",
            shortPositions: "0.00",
            netPositions: "0.00",
            dailyNet: "4.77",
            floatingProfitEOD: "0.00",
            floatingProfit: "0.00",
            closedProfitEOD: "4.77",
            closedProfit: "4.77",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "0.00000",
            exposure: "0.00",
        },
        {
            fullLogin: "Demo:MT4:xxxxx44",
            login: "MT4:xxxxx44",
            longPositions: "0.00",
            shortPositions: "0.14",
            netPositions: "-0.14",
            dailyNet: "-8.60",
            floatingProfitEOD: "-8.60",
            floatingProfit: "-151.39",
            closedProfitEOD: "0.00",
            closedProfit: "0.80",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.37337",
            exposure: "-13,997.16",
        },
        {
            fullLogin: "Demo:MT4:xxxxx00",
            login: "MT4:xxxxx00",
            longPositions: "0.00",
            shortPositions: "0.04",
            netPositions: "-0.04",
            dailyNet: "-3.97",
            floatingProfitEOD: "-3.97",
            floatingProfit: "-67.68",
            closedProfitEOD: "0.00",
            closedProfit: "0.00",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.36542",
            exposure: "-3,999.97",
        },
        {
            fullLogin: "Demo:MT4:xxxxx85",
            login: "MT4:xxxxx85",
            longPositions: "0.00",
            shortPositions: "0.00",
            netPositions: "0.00",
            dailyNet: "3.93",
            floatingProfitEOD: "0.00",
            floatingProfit: "0.00",
            closedProfitEOD: "3.93",
            closedProfit: "3.93",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "0.00000",
            exposure: "0.00",
        },
        {
            fullLogin: "Demo:MT4:xxxxx84",
            login: "MT4:xxxxx84",
            longPositions: "0.00",
            shortPositions: "1.35",
            netPositions: "-1.35",
            dailyNet: "-160.28",
            floatingProfitEOD: "-116.68",
            floatingProfit: "-973.97",
            closedProfitEOD: "-43.60",
            closedProfit: "-43.60",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.37872",
            exposure: "-135,030.60",
        },
        {
            fullLogin: "Demo:MT4:xxxxx89",
            login: "MT4:xxxxx89",
            longPositions: "0.00",
            shortPositions: "0.00",
            netPositions: "0.00",
            dailyNet: "0.00",
            floatingProfitEOD: "0.00",
            floatingProfit: "0.00",
            closedProfitEOD: "0.00",
            closedProfit: "-74.97",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "0.00000",
            exposure: "0.00",
        },
        {
            fullLogin: "Demo:MT4:xxxxx84",
            login: "MT4:xxxxx84",
            longPositions: "0.00",
            shortPositions: "0.00",
            netPositions: "0.00",
            dailyNet: "0.00",
            floatingProfitEOD: "0.00",
            floatingProfit: "0.00",
            closedProfitEOD: "0.00",
            closedProfit: "-468.72",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "0.00000",
            exposure: "0.00",
        },
        {
            fullLogin: "Demo:MT4:xxxxx52",
            login: "MT4:xxxxx52",
            longPositions: "0.00",
            shortPositions: "0.17",
            netPositions: "-0.17",
            dailyNet: "-14.87",
            floatingProfitEOD: "-14.87",
            floatingProfit: "-152.95",
            closedProfitEOD: "0.00",
            closedProfit: "1.73",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.37626",
            exposure: "-17,001.28",
        },
        {
            fullLogin: "Demo:MT4:xxxxx70",
            login: "MT4:xxxxx70",
            longPositions: "0.00",
            shortPositions: "0.00",
            netPositions: "0.00",
            dailyNet: "3.99",
            floatingProfitEOD: "0.00",
            floatingProfit: "0.00",
            closedProfitEOD: "3.99",
            closedProfit: "3.99",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "0.00000",
            exposure: "0.00",
        },
        {
            fullLogin: "Demo:MT4:xxxxx00",
            login: "MT4:xxxxx00",
            longPositions: "0.00",
            shortPositions: "1.71",
            netPositions: "-1.71",
            dailyNet: "47.90",
            floatingProfitEOD: "47.90",
            floatingProfit: "-467.20",
            closedProfitEOD: "0.00",
            closedProfit: "0.00",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.38468",
            exposure: "-170,990.70",
        },
        {
            fullLogin: "Demo:MT4:xxxxx64",
            login: "MT4:xxxxx64",
            longPositions: "0.00",
            shortPositions: "0.17",
            netPositions: "-0.17",
            dailyNet: "-15.52",
            floatingProfitEOD: "-15.52",
            floatingProfit: "-171.80",
            closedProfitEOD: "0.00",
            closedProfit: "1.76",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.37478",
            exposure: "-17,005.61",
        },
        {
            fullLogin: "Demo:MT4:xxxxx22",
            login: "MT4:xxxxx22",
            longPositions: "0.01",
            shortPositions: "0.07",
            netPositions: "-0.06",
            dailyNet: "-0.55",
            floatingProfitEOD: "-3.83",
            floatingProfit: "-14.26",
            closedProfitEOD: "3.28",
            closedProfit: "4.95",
            weightedAvgLongPrice: "1.39375",
            weightedAvgShortPrice: "1.38666",
            exposure: "-5,999.53",
        },
        {
            fullLogin: "Demo:MT4:xxxxx80",
            login: "MT4:xxxxx80",
            longPositions: "0.00",
            shortPositions: "0.00",
            netPositions: "0.00",
            dailyNet: "0.00",
            floatingProfitEOD: "0.00",
            floatingProfit: "0.00",
            closedProfitEOD: "0.00",
            closedProfit: "-262.06",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "0.00000",
            exposure: "0.00",
        },
        {
            fullLogin: "Demo:MT4:xxxxx27",
            login: "MT4:xxxxx27",
            longPositions: "0.00",
            shortPositions: "1.06",
            netPositions: "-1.06",
            dailyNet: "21.42",
            floatingProfitEOD: "20.22",
            floatingProfit: "-301.16",
            closedProfitEOD: "1.20",
            closedProfit: "1.77",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.38498",
            exposure: "-106,023.38",
        },
        {
            fullLogin: "Demo:MT4:xxxxx85",
            login: "MT4:xxxxx85",
            longPositions: "0.00",
            shortPositions: "0.14",
            netPositions: "-0.14",
            dailyNet: "-13.27",
            floatingProfitEOD: "-13.27",
            floatingProfit: "-99.30",
            closedProfitEOD: "0.00",
            closedProfit: "0.84",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.37901",
            exposure: "-14,006.16",
        },
        {
            fullLogin: "Demo:MT4:xxxxx13",
            login: "MT4:xxxxx13",
            longPositions: "0.00",
            shortPositions: "0.00",
            netPositions: "0.00",
            dailyNet: "5.87",
            floatingProfitEOD: "0.00",
            floatingProfit: "0.00",
            closedProfitEOD: "5.87",
            closedProfit: "5.61",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "0.00000",
            exposure: "0.00",
        },
        {
            fullLogin: "Demo:MT4:xxxxx91",
            login: "MT4:xxxxx91",
            longPositions: "0.00",
            shortPositions: "0.60",
            netPositions: "-0.60",
            dailyNet: "-16.37",
            floatingProfitEOD: "23.70",
            floatingProfit: "-154.39",
            closedProfitEOD: "-40.07",
            closedProfit: "-579.62",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.38513",
            exposure: "-60,009.45",
        },
        {
            fullLogin: "Demo:MT4:xxxxx99",
            login: "MT4:xxxxx99",
            longPositions: "0.00",
            shortPositions: "0.17",
            netPositions: "-0.17",
            dailyNet: "-12.75",
            floatingProfitEOD: "-13.56",
            floatingProfit: "-160.32",
            closedProfitEOD: "0.81",
            closedProfit: "2.40",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.37555",
            exposure: "-17,001.93",
        },
        {
            fullLogin: "Demo:MT4:xxxxx53",
            login: "MT4:xxxxx53",
            longPositions: "0.01",
            shortPositions: "0.00",
            netPositions: "0.01",
            dailyNet: "-3.63",
            floatingProfitEOD: "-4.36",
            floatingProfit: "-4.36",
            closedProfitEOD: "0.73",
            closedProfit: "0.73",
            weightedAvgLongPrice: "1.39404",
            weightedAvgShortPrice: "0.00000",
            exposure: "999.34",
        },

        {
            fullLogin: "Demo:MT4:xxxxx25",
            login: "MT4:xxxxx25",
            longPositions: "0.00",
            shortPositions: "0.71",
            netPositions: "-0.71",
            dailyNet: "14.52",
            floatingProfitEOD: "3.68",
            floatingProfit: "-220.11",
            closedProfitEOD: "10.84",
            closedProfit: "4.70",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.38435",
            exposure: "-71,009.56",
        },
        {
            fullLogin: "Demo:MT4:xxxxx19",
            login: "MT4:xxxxx19",
            longPositions: "0.00",
            shortPositions: "1.35",
            netPositions: "-1.35",
            dailyNet: "-160.14",
            floatingProfitEOD: "-116.65",
            floatingProfit: "-973.53",
            closedProfitEOD: "-43.49",
            closedProfit: "-43.49",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.37873",
            exposure: "-134,996.01",
        },
        {
            fullLogin: "Demo:MT4:xxxxx95",
            login: "MT4:xxxxx95",
            longPositions: "0.01",
            shortPositions: "0.00",
            netPositions: "0.01",
            dailyNet: "-0.67",
            floatingProfitEOD: "-0.67",
            floatingProfit: "-0.67",
            closedProfitEOD: "0.00",
            closedProfit: "0.00",
            weightedAvgLongPrice: "1.38892",
            weightedAvgShortPrice: "0.00000",
            exposure: "999.34",
        },
        {
            fullLogin: "Demo:MT4:xxxxx92",
            login: "MT4:xxxxx92",
            longPositions: "0.00",
            shortPositions: "0.14",
            netPositions: "-0.14",
            dailyNet: "-10.68",
            floatingProfitEOD: "-10.68",
            floatingProfit: "-173.71",
            closedProfitEOD: "0.00",
            closedProfit: "0.80",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.37137",
            exposure: "-14,002.04",
        },
        {
            fullLogin: "Demo:MT4:xxxxx26",
            login: "MT4:xxxxx26",
            longPositions: "0.00",
            shortPositions: "0.02",
            netPositions: "-0.02",
            dailyNet: "-0.88",
            floatingProfitEOD: "-0.88",
            floatingProfit: "-100.54",
            closedProfitEOD: "0.00",
            closedProfit: "2.44",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.31832",
            exposure: "-1,998.90",
        },
        {
            fullLogin: "Demo:MT4:xxxxx67",
            login: "MT4:xxxxx67",
            longPositions: "0.00",
            shortPositions: "1.15",
            netPositions: "-1.15",
            dailyNet: "-128.87",
            floatingProfitEOD: "-96.84",
            floatingProfit: "-876.61",
            closedProfitEOD: "-32.03",
            closedProfit: "-32.03",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.37813",
            exposure: "-115,023.41",
        },
        {
            fullLogin: "Demo:MT4:xxxxx94",
            login: "MT4:xxxxx94",
            longPositions: "0.00",
            shortPositions: "0.14",
            netPositions: "-0.14",
            dailyNet: "-11.89",
            floatingProfitEOD: "-11.89",
            floatingProfit: "-157.05",
            closedProfitEOD: "0.00",
            closedProfit: "-2.78",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.37314",
            exposure: "-14,005.32",
        },

        {
            fullLogin: "Demo:MT4:xxxxx83",
            login: "MT4:xxxxx83",
            longPositions: "0.00",
            shortPositions: "0.55",
            netPositions: "-0.55",
            dailyNet: "-38.54",
            floatingProfitEOD: "-42.43",
            floatingProfit: "-449.08",
            closedProfitEOD: "3.89",
            closedProfit: "3.89",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.37716",
            exposure: "-54,991.83",
        },
        {
            fullLogin: "Demo:MT4:xxxxx86",
            login: "MT4:xxxxx86",
            longPositions: "0.00",
            shortPositions: "1.35",
            netPositions: "-1.35",
            dailyNet: "-160.25",
            floatingProfitEOD: "-116.65",
            floatingProfit: "-973.63",
            closedProfitEOD: "-43.60",
            closedProfit: "-43.60",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.37873",
            exposure: "-134,996.44",
        },
        {
            fullLogin: "Demo:MT4:xxxxx39",
            login: "MT4:xxxxx39",
            longPositions: "0.00",
            shortPositions: "1.06",
            netPositions: "-1.06",
            dailyNet: "27.51",
            floatingProfitEOD: "21.68",
            floatingProfit: "-299.69",
            closedProfitEOD: "5.83",
            closedProfit: "2.71",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.38498",
            exposure: "-106,007.16",
        },
        {
            fullLogin: "Demo:MT4:xxxxx37",
            login: "MT4:xxxxx37",
            longPositions: "0.00",
            shortPositions: "0.20",
            netPositions: "-0.20",
            dailyNet: "-14.56",
            floatingProfitEOD: "-14.56",
            floatingProfit: "-184.48",
            closedProfitEOD: "0.00",
            closedProfit: "0.81",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.37574",
            exposure: "-19,997.05",
        },
        {
            fullLogin: "Demo:MT4:xxxxx87",
            login: "MT4:xxxxx87",
            longPositions: "0.00",
            shortPositions: "1.15",
            netPositions: "-1.15",
            dailyNet: "-129.18",
            floatingProfitEOD: "-96.83",
            floatingProfit: "-876.47",
            closedProfitEOD: "-32.35",
            closedProfit: "-32.35",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.37813",
            exposure: "-115,014.64",
        },
        {
            fullLogin: "Demo:MT4:xxxxx38",
            login: "MT4:xxxxx38",
            longPositions: "0.00",
            shortPositions: "0.17",
            netPositions: "-0.17",
            dailyNet: "-16.89",
            floatingProfitEOD: "-16.89",
            floatingProfit: "-149.88",
            closedProfitEOD: "0.00",
            closedProfit: "0.00",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.37668",
            exposure: "-17,002.36",
        },
        {
            fullLogin: "Demo:MT4:xxxxx19",
            login: "MT4:xxxxx19",
            longPositions: "0.01",
            shortPositions: "0.00",
            netPositions: "0.01",
            dailyNet: "0.44",
            floatingProfitEOD: "0.44",
            floatingProfit: "14.49",
            closedProfitEOD: "0.00",
            closedProfit: "0.00",
            weightedAvgLongPrice: "1.36787",
            weightedAvgShortPrice: "0.00000",
            exposure: "999.33",
        },
        {
            fullLogin: "Demo:MT4:xxxxx35",
            login: "MT4:xxxxx35",
            longPositions: "0.00",
            shortPositions: "0.11",
            netPositions: "-0.11",
            dailyNet: "-8.91",
            floatingProfitEOD: "-8.91",
            floatingProfit: "-139.70",
            closedProfitEOD: "0.00",
            closedProfit: "-3.30",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.37103",
            exposure: "-11,000.44",
        },
        {
            fullLogin: "Demo:MT4:xxxxx32",
            login: "MT4:xxxxx32",
            longPositions: "0.00",
            shortPositions: "1.41",
            netPositions: "-1.41",
            dailyNet: "-10.48",
            floatingProfitEOD: "-10.48",
            floatingProfit: "-430.04",
            closedProfitEOD: "0.00",
            closedProfit: "0.00",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.38468",
            exposure: "-141,039.26",
        },
        {
            fullLogin: "Demo:MT4:xxxxx33",
            login: "MT4:xxxxx33",
            longPositions: "0.00",
            shortPositions: "1.35",
            netPositions: "-1.35",
            dailyNet: "-160.09",
            floatingProfitEOD: "-116.67",
            floatingProfit: "-973.37",
            closedProfitEOD: "-43.42",
            closedProfit: "-43.42",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.37873",
            exposure: "-135,014.06",
        },
        {
            fullLogin: "Demo:MT4:xxxxx98",
            login: "MT4:xxxxx98",
            longPositions: "0.00",
            shortPositions: "1.35",
            netPositions: "-1.35",
            dailyNet: "-160.09",
            floatingProfitEOD: "-116.67",
            floatingProfit: "-973.31",
            closedProfitEOD: "-43.42",
            closedProfit: "-43.42",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.37873",
            exposure: "-135,019.43",
        },
        {
            fullLogin: "Demo:MT4:xxxxx63",
            login: "MT4:xxxxx63",
            longPositions: "0.00",
            shortPositions: "0.00",
            netPositions: "0.00",
            dailyNet: "1.96",
            floatingProfitEOD: "0.00",
            floatingProfit: "0.00",
            closedProfitEOD: "1.96",
            closedProfit: "1.96",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "0.00000",
            exposure: "0.00",
        },
        {
            fullLogin: "Demo:MT4:xxxxx71",
            login: "MT4:xxxxx71",
            longPositions: "0.00",
            shortPositions: "1.35",
            netPositions: "-1.35",
            dailyNet: "-151.01",
            floatingProfitEOD: "-107.80",
            floatingProfit: "-964.30",
            closedProfitEOD: "-43.21",
            closedProfit: "-43.21",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.37873",
            exposure: "-134,996.62",
        },
        {
            fullLogin: "Demo:MT4:xxxxx02",
            login: "MT4:xxxxx02",
            longPositions: "0.00",
            shortPositions: "0.00",
            netPositions: "0.00",
            dailyNet: "13.87",
            floatingProfitEOD: "0.00",
            floatingProfit: "0.00",
            closedProfitEOD: "13.87",
            closedProfit: "13.87",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "0.00000",
            exposure: "0.00",
        },
        {
            fullLogin: "Demo:MT4:xxxxx46",
            login: "MT4:xxxxx46",
            longPositions: "0.00",
            shortPositions: "0.17",
            netPositions: "-0.17",
            dailyNet: "-14.87",
            floatingProfitEOD: "-14.87",
            floatingProfit: "-162.10",
            closedProfitEOD: "0.00",
            closedProfit: "0.83",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.37551",
            exposure: "-17,003.29",
        },
        {
            fullLogin: "Demo:MT4:xxxxx80",
            login: "MT4:xxxxx80",
            longPositions: "0.00",
            shortPositions: "0.14",
            netPositions: "-0.14",
            dailyNet: "-10.75",
            floatingProfitEOD: "-10.75",
            floatingProfit: "-154.47",
            closedProfitEOD: "0.00",
            closedProfit: "1.59",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.37328",
            exposure: "-13,998.44",
        },
        {
            fullLogin: "Demo:MT4:xxxxx39",
            login: "MT4:xxxxx39",
            longPositions: "0.00",
            shortPositions: "0.21",
            netPositions: "-0.21",
            dailyNet: "-18.84",
            floatingProfitEOD: "-18.84",
            floatingProfit: "-175.13",
            closedProfitEOD: "0.00",
            closedProfit: "0.80",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.37720",
            exposure: "-21,003.50",
        },
        {
            fullLogin: "Demo:MT4:xxxxx81",
            login: "MT4:xxxxx81",
            longPositions: "0.00",
            shortPositions: "0.00",
            netPositions: "0.00",
            dailyNet: "2.00",
            floatingProfitEOD: "0.00",
            floatingProfit: "0.00",
            closedProfitEOD: "2.00",
            closedProfit: "2.00",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "0.00000",
            exposure: "0.00",
        },
        {
            fullLogin: "Demo:MT4:xxxxx29",
            login: "MT4:xxxxx29",
            longPositions: "0.00",
            shortPositions: "1.35",
            netPositions: "-1.35",
            dailyNet: "-151.33",
            floatingProfitEOD: "-107.80",
            floatingProfit: "-964.24",
            closedProfitEOD: "-43.53",
            closedProfit: "-43.53",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.37873",
            exposure: "-134,986.51",
        },
        {
            fullLogin: "Demo:MT4:xxxxx27",
            login: "MT4:xxxxx27",
            longPositions: "0.00",
            shortPositions: "1.35",
            netPositions: "-1.35",
            dailyNet: "-150.60",
            floatingProfitEOD: "-107.83",
            floatingProfit: "-964.70",
            closedProfitEOD: "-42.77",
            closedProfit: "-42.77",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.37873",
            exposure: "-135,025.73",
        },
        {
            fullLogin: "Demo:MT4:xxxxx51",
            login: "MT4:xxxxx51",
            longPositions: "0.00",
            shortPositions: "0.95",
            netPositions: "-0.95",
            dailyNet: "-5.56",
            floatingProfitEOD: "-7.90",
            floatingProfit: "-288.63",
            closedProfitEOD: "2.34",
            closedProfit: "1.14",
            weightedAvgLongPrice: "0.00000",
            weightedAvgShortPrice: "1.38468",
            exposure: "-95,036.82",
        },
    ],
};
