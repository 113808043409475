var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"fullscreen":_vm.fullscreen,"height":"1000","width":_vm.dialogWidth,"hide-overlay":true,"persistent":true},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',{staticClass:"pt-0"},[_c('v-app-bar',{staticStyle:{"background":"rgb(26, 57, 88)"},attrs:{"flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-toolbar-title',{staticClass:"text-h6 white--text pl-0"},[_vm._v("Symbol: "+_vm._s(_vm.symbol)+" Detail")]),_c('v-spacer'),(!_vm.VUETIFY_BREAKPOINT.mobile && _vm.allowDownloadAll)?[_c('v-btn',{staticClass:"mr-1",attrs:{"small":"","dark":"","color":"primary","dense":"","loading":_vm.allSymbolDownloadLoading},on:{"click":_vm.downloadAllSymbolSnapshot}},[_vm._v("Download All")])]:_vm._e(),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.VUETIFY_BREAKPOINT.mobile)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"display":"block","float":"right"},attrs:{"dark":"","color":"primary"},on:{"click":_vm.startTutorial}},'v-icon',attrs,false),on),[_vm._v("mdi-school")]):_vm._e()]}}])},[_c('span',[_vm._v("Click to start tutorial")])]),(!_vm.VUETIFY_BREAKPOINT.mobile)?_c('v-btn',{attrs:{"color":"white","icon":""},on:{"click":_vm.toggleFullscreen}},[_c('v-icon',[_vm._v("mdi-arrow-expand")])],1):_vm._e(),_c('v-btn',{attrs:{"color":"white","icon":""},on:{"click":_vm.handleClose}},[_c('v-icon',[_vm._v("mdi-close")])],1)],2),_c('v-tabs',{attrs:{"id":"symbolTabChanging"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{on:{"click":function($event){return _vm.changeView('trades')}}},[_vm._v("Trades")]),(_vm.showSymbolSnapshot && _vm.isNotIBPage)?_c('v-tab',{on:{"click":function($event){return _vm.changeView('snapshot')}}},[_vm._v(" Symbol Snapshot")]):_vm._e()],1),(_vm.loadTrades)?[_c('v-text-field',{staticClass:"mr-4 mt-n2 ml-4 1mb-5",attrs:{"append-icon":"mdi-search","label":"Search Login","single-line":"","hide-details":"","id":"symbolTabSearch"},model:{value:(_vm.searchLogin),callback:function ($$v) {_vm.searchLogin=$$v},expression:"searchLogin"}}),_c('v-data-table',{directives:[{name:"sortable-table",rawName:"v-sortable-table",value:({
                        onEnd: _vm.sortSubHeadersAndUpdateTheKey,
                    }),expression:"{\n                        onEnd: sortSubHeadersAndUpdateTheKey,\n                    }"}],key:_vm.anIncreasingNumberForSub,class:{
                        'fix-first-col': _vm.VUETIFY_BREAKPOINT.mobile,
                    },attrs:{"id":"symbolDetailTable","dense":"","search":_vm.searchLogin,"headers":_vm.subHeader,"items":_vm.computedSymbolDetailObject,"item-key":"login","items-per-page":15,"fixed-header":"","customSort":_vm.customSort,"header-props":{ sortIcon: null },"mobile-breakpoint":0,"footer-props":{
                        itemsPerPageOptions: [10, 15],
                        'items-per-page-text': '',
                    }},scopedSlots:_vm._u([{key:"header.longPositions",fn:function(ref){
                    var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),(_vm.showSumUp)?_c('v-icon',{staticStyle:{"position":"absolute"},attrs:{"size":"18","color":"orange"},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.calculateTooltipNumber(
                                        header.text,
                                        header.value
                                    ); })($event)}}},[_vm._v("mdi-calculator")]):_vm._e()]}},{key:"header.shortPositions",fn:function(ref){
                                    var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),(_vm.showSumUp)?_c('v-icon',{staticStyle:{"position":"absolute"},attrs:{"size":"18","color":"orange"},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.calculateTooltipNumber(
                                        header.text,
                                        header.value
                                    ); })($event)}}},[_vm._v("mdi-calculator")]):_vm._e()]}},{key:"header.dailyNet",fn:function(ref){
                                    var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),(_vm.showSumUp)?_c('v-icon',{staticStyle:{"position":"absolute"},attrs:{"size":"18","color":"orange"},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.calculateTooltipNumber(
                                        header.text,
                                        header.value
                                    ); })($event)}}},[_vm._v("mdi-calculator")]):_vm._e()]}},{key:"header.floatingProfitEOD",fn:function(ref){
                                    var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),(_vm.showSumUp)?_c('v-icon',{staticStyle:{"position":"absolute"},attrs:{"size":"18","color":"orange"},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.calculateTooltipNumber(
                                        header.text,
                                        header.value
                                    ); })($event)}}},[_vm._v("mdi-calculator")]):_vm._e()]}},{key:"header.closedProfitEOD",fn:function(ref){
                                    var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),(_vm.showSumUp)?_c('v-icon',{staticStyle:{"position":"absolute"},attrs:{"size":"18","color":"orange"},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.calculateTooltipNumber(
                                        header.text,
                                        header.value
                                    ); })($event)}}},[_vm._v("mdi-calculator")]):_vm._e()]}},{key:"item.login",fn:function(ref){
                                    var item = ref.item;
return [_c('div',{class:{
                                'link-text': _vm.showHomeLoginDetailSummary,
                            },on:{"click":function($event){return _vm.handleLoginClick(item)}}},[_vm._v(" "+_vm._s(item.login)+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.isInPercentageMode),expression:"item.isInPercentageMode"}],attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-brightness-percent")])]}}],null,true)},[_c('span',[_vm._v("Percent Hedge")])])]}}],null,false,1565285878)})]:_vm._e(),(_vm.loadSnapshot)?_c('SnapshotTab',{attrs:{"target":"symbol","book":_vm.selectedBook,"symbol":_vm.symbol}}):_vm._e(),_c('v-card-actions')],2)],1),(_vm.showSumUp)?_c('v-dialog',{attrs:{"height":"300","width":"500","hide-overlay":false,"persistent":true},model:{value:(_vm.dialogSumUp),callback:function ($$v) {_vm.dialogSumUp=$$v},expression:"dialogSumUp"}},[_c('v-card',{staticClass:"pt-0"},[_c('v-app-bar',{staticStyle:{"background":"rgb(26, 57, 88)"},attrs:{"flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-toolbar-title',{staticClass:"text-h6 white--text pl-0"},[_vm._v("Sum up result:")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"white","icon":""},on:{"click":function () {
                            _vm.dialogSumUp = false;
                        }}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"pa-4",domProps:{"innerHTML":_vm._s(_vm.htmlResult)}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }